import create from "zustand";
import firebase from "firebase/app";
import "firebase/auth";
import isFuture from "date-fns/isFuture";
import isToday from "date-fns/isToday";
import parseISO from "date-fns/parseISO";

import { getCustomer } from "@api/customer-api";
import { VAT_STATUS } from "@constants/vat-status";

export const USER_STATUS = {
  ANONYMOUS: "ANONYMOUS",
  LOADING: "LOADING",
  PENDING_REGISTRATION: "PENDING_REGISTRATION",
  PENDING_LICENSE: "PENDING_LICENSE",
  REGISTERED: "REGISTERED",
};

export const LICENSE_STATUS = {
  ACTIVE: "active",
  FREE_TRIAL: "free_trial",
  CANCELLED: "cancelled",
  INVALID: "invalid",
};

const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (err) {
    // Do nothing, localStorage is not supported
  }
};

export const signIn = (email, password) => {
  clearLocalStorage();

  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const signOut = () => {
  clearLocalStorage();

  return firebase.auth().signOut();
};

const isLicenseValid = (license) => {
  const validUntil = parseISO(license?.valid_until);

  return isToday(validUntil) || isFuture(validUntil);
};

// Helper functions to easily check user status
export const isAnonymous = (status) => status === USER_STATUS.ANONYMOUS;
export const isLoading = (status) => status === USER_STATUS.LOADING;
export const isRegistered = (status) => status === USER_STATUS.REGISTERED;
export const isPendingRegistration = (status) => status === USER_STATUS.PENDING_REGISTRATION;
export const isPendingLicense = (status) => status === USER_STATUS.PENDING_LICENSE;
export const isSignedIn = (status) => isRegistered(status) || isPendingRegistration(status) || isPendingLicense(status);

// State selectors to avoid creating a new function on each render
export const statusSelector = (state) => state.status;
export const cocoUserSelector = (state) => state.cocoUser;
export const fetchCocoUserSelector = (state) => state.fetchCocoUser;
export const firebaseUserSelector = (state) => state.firebaseUser;

const useAuthStore = create((set, get) => ({
  status: USER_STATUS.LOADING,
  firebaseUser: null,
  cocoUser: null,

  getToken() {
    const firebaseUser = get().firebaseUser;

    if (firebaseUser) {
      try {
        return firebaseUser.getIdToken();
      } catch {
        // No-op
      }
    }

    return null;
  },

  async fetchCocoUser() {
    const token = await get().getToken();

    if (token) {
      try {
        const cocoUser = await getCustomer().run();
        const status = isLicenseValid(cocoUser.license) ? USER_STATUS.REGISTERED : USER_STATUS.PENDING_LICENSE;

        set({ cocoUser, status });

        get().pollVATStatus();
      } catch (error) {
        if (error.status === 404) {
          set({ status: USER_STATUS.PENDING_REGISTRATION });
        } else {
          set({ status: USER_STATUS.ANONYMOUS });
        }
      }
    } else {
      set({ status: USER_STATUS.ANONYMOUS });
    }
  },

  /**
   * Recursively fetch the coco customer to check their VAT status.
   * Stripe verifies the user's VAT status in the background.
   * When the status is no longer "pending_vat_check", stop the recursion and update the user state.
   *
   * @param {number} [wait=5000] Time to wait before checking the user status again
   */
  async pollVATStatus(wait = 1000) {
    const state = get();

    if (isRegistered(state.status) && state.cocoUser?.status === VAT_STATUS.PENDING) {
      try {
        const cocoUser = await getCustomer().run();

        if (cocoUser?.status === VAT_STATUS.PENDING) {
          setTimeout(() => state.pollVATStatus(wait * 2), wait);
        } else {
          set({ cocoUser });
        }
      } catch (error) {
        setTimeout(state.pollVATStatus(wait * 2), wait);
      }
    }
  },

  clearUser() {
    set({
      cocoUser: null,
      firebaseUser: null,
      status: USER_STATUS.ANONYMOUS,
    });
  },

  watchForAuthChanges() {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        set({ firebaseUser });
        get().fetchCocoUser();
      } else {
        get().clearUser();
      }
    });
  },
}));

export default useAuthStore;
