import React from "react";
import styled from "styled-components";
import { getStaticAsset } from "../utils";

type Icon =
  | "box"
  | "carrot"
  | "cart"
  | "cloud"
  | "court"
  | "fish"
  | "fork"
  | "globe"
  | "growth"
  | "house"
  | "laptop"
  | "leaf"
  | "magnify"
  | "mail"
  | "package"
  | "phone"
  | "plane"
  | "sausage";

export interface CompensateIconProps {
  alt?: string;
  icon: Icon;
  size?: "sm" | "md" | "lg";
}

const getMaxWidth = (size: CompensateIconProps["size"]) => {
  if (size === "sm") {
    return "64px";
  }
  if (size === "md") {
    return "128px";
  }

  return "160px";
};

const StyledImg = styled.img<Pick<CompensateIconProps, "size">>`
  width: 100%;
  max-width: ${({ size }) => getMaxWidth(size)};
`;

const CompensateIcon = ({ alt, icon, size, ...props }: CompensateIconProps) => (
  <StyledImg alt={alt || ""} size={size} src={getStaticAsset(`/icons/icon-${icon}.png`)} />
);

export default CompensateIcon;
