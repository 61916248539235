import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import FIELD_PROP_TYPES from "../constants/field-prop-types";

const truncateDigits = (amount, digits) => {
  const parts = amount.split(/([,.])/);
  if (digits === 0) return parts[0];
  if (parts.length < 3) return amount;

  return parts[0] + "." + parts[2].substr(0, digits);
};

const formatNumber = (amount, digits = 0) => {
  if (amount === null) {
    amount = "";
  }

  if (typeof amount === "number") {
    amount = amount.toString();
  }

  amount = amount.replace(/\s/g, "");
  if (amount === "") return "";
  if (!amount.match(/^[0-9]+([,.][0-9]*)?$/)) {
    amount = amount.substr(0, amount.length - 1);
  }

  amount = truncateDigits(amount, digits);

  if (amount.match(/\.\d*0$/)) {
    // Converting e.g. "0.0" "5.20" to a Number will wipe the trailing zero and prevent the user from
    // entering numbers such as 0.05 and 5.201. So catch that case and reattach the fractionalPart.
    const [wholePart, fractionalPart] = amount.split(".");

    return `${Number(wholePart).toLocaleString("fi-FI")},${fractionalPart}`;
  }

  return Number(amount).toLocaleString("fi-FI") + (amount.endsWith(".") ? "," : "");
};

const NumberField = ({
  register,
  fieldProps,
  error,
  required,
  name,
  label,
  defaultValue = "",
  digits = 0,
  ...rest
}) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    render={({ onChange, onBlur, value = "", name, ref }) => (
      <TextField
        id={`number-field-${name}`}
        inputRef={ref}
        error={!!error}
        required={required}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        value={formatNumber(value, digits)}
        {...rest}
        {...fieldProps}
        // Keep error message last to make sure it overrides any other helper texts
        helperText={error?.message || fieldProps?.helperText}
      />
    )}
  />
);

export default NumberField;

NumberField.propTypes = {
  ...FIELD_PROP_TYPES,
  digits: PropTypes.number,
};
