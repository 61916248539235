import { differenceInDays } from "date-fns";

import {
  COUNTRY_AVERAGE,
  RENEWABLE,
  FOR_WHOLE_BUILDING,
  SEPARATE_POWER_PLANT,
  USE_GENERAL_ESTIMATE,
  WITH_KNOWN_EMISSION_FACTOR,
  DEFAULT_FACTOR,
} from "./constants";
import { productNameToPrettyName } from "@constants/fuel-types";
import { formatFactor, getCountryFromFactor } from "../../../components/form/fields/CountryElectricityFactorsDropdown";

const typeNames = {
  ...productNameToPrettyName,
  [SEPARATE_POWER_PLANT]: "User-specific",
  [USE_GENERAL_ESTIMATE]: "General average",
  [DEFAULT_FACTOR]: "General average",
  [WITH_KNOWN_EMISSION_FACTOR]: "User-specific",
};

function rowTypeName(type) {
  if (type in typeNames) {
    return typeNames[type];
  }

  return type;
}

export function parseNumber(value) {
  if (!value) {
    return NaN;
  }

  if (typeof value === "number") {
    return value;
  }

  return Number(value.replace(/[^0-9,.]/g, "").replace(/,/g, "."));
}

function usage(values) {
  if (values.amount_known === FOR_WHOLE_BUILDING)
    return (parseNumber(values.amount_used) * parseNumber(values.space_size)) / parseNumber(values.building_size);
  else return parseNumber(values.amount_used);
}

export function calculationParams(basicInfo, type, values) {
  const row = {
    name: basicInfo.space_name,
    type: rowTypeName(values.used_type),
  };

  const params = { description: basicInfo.space_name };

  if (values.amount_known === USE_GENERAL_ESTIMATE) {
    const spaceSize = parseNumber(values.space_size);
    const periodLength = differenceInDays(basicInfo.to_date, basicInfo.from_date) + 1;

    // Since estimates are based on yearly consumption, scale according to reporting period length
    params.usage = (spaceSize * periodLength) / 365;
    params.estimate = `energy/${type}/${values.building_type}`;
    row.usage = spaceSize;
    row.unit = "m²";
  } else {
    params.usage = usage(values);
    row.usage = params.usage;
    row.unit = values.unit || "kWh";
  }

  if (type === "electricity") {
    return [
      { ...params, ...getParamsForElectricity(values) },
      { ...row, ...getRowForElectricity(values) },
    ];
  }

  return [{ ...params, ...getParamsByUsedType(values, type) }, row];
}

/**
 * "Used type" represents the type of energy used for heating and cooling
 */
function getParamsByUsedType(values, type) {
  switch (values.used_type) {
    case WITH_KNOWN_EMISSION_FACTOR:
      return {
        type: `energy/${type}/${DEFAULT_FACTOR}`,
        factor: values.known_emission_factor,
        energy_type: "average",
      };

    case COUNTRY_AVERAGE:
      return { type: values.country_average, energy_type: "average" };

    case RENEWABLE:
      return { type: `energy/${type}/${RENEWABLE}`, energy_type: "renewable" };

    default:
      if (values.used_type.startsWith("energy/")) {
        return { type: values.used_type, energy_type: "average" };
      } else {
        return { type: `energy/${type}/${values.used_type}`, energy_type: "average" };
      }
  }
}

/**
 * Gets the product type by selected country and the
 * type of electricity (renewable or average)
 */
function getParamsForElectricity(values) {
  return {
    type: `energy/electricity/${getCountryFromFactor(values.country)}`,
    energy_type: values.is_renewable === "yes" ? "renewable" : "average",
    factor:
      values.is_known_factor === "yes" && values.is_renewable !== "yes" ? values.known_emission_factor : undefined,
  };
}

/**
 * Row data is used in the tables, should be human readable
 */
function getRowForElectricity(values) {
  const countryCode = getCountryFromFactor(values.country);
  const countryName = formatFactor(values.country);

  if (values.is_renewable === "yes") {
    return { type: `Renewable, ${countryName}` };
  }

  if (values.is_known_factor === "yes") {
    return { type: `Custom factor (${values.known_emission_factor} gCO2e/kWh)` };
  }

  if (countryCode === "Europe" || countryCode === "World") {
    return { type: countryName };
  }

  return {
    type: `Country average, ${countryName}`,
  };
}
