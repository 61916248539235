import { useState } from "react";

const getFromLocalStorage = (key, initialValue) => () => {
  try {
    const item = window.localStorage.getItem(key);

    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(getFromLocalStorage(key, initialValue));

  const setValue = (value) => {
    try {
      setStoredValue(value);

      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
  };

  const clear = () => {
    try {
      localStorage.removeItem(key);
    } catch (error) {}
  };

  return [storedValue, setValue, clear];
};

export default useLocalStorage;
