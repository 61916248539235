import { GENERAL_FORM_ERROR_KEY } from "..";

type ApiErrorType = "bad-request" | "unauthorized" | undefined;

export type InvalidArgumentType =
  | "required"
  | "xor"
  | "=="
  | "!="
  | ">"
  | ">="
  | "min"
  | "<"
  | "<="
  | "max"
  | "format"
  | "URL"
  | "ISO 3166-1 alpha-2"
  | "UUID"
  | "email"
  | "address"
  | "oneof"
  | "unknown";

export interface InvalidParam {
  /** The name of the invalid param */
  name: string;
  type: InvalidArgumentType;
  value: string;
  detail: string;
}

interface BadRequestError {
  type: "bad-request";
  code: 400;
  title: string;
  detail: string;
  /** Where the error occurred, e.g. /authentication */
  instance: string;
  params?: InvalidParam[];
}

interface UnauthorizedError {
  type: "unauthorized";
  code: 401;
  title: string;
}

/**
 * Legacy error implementation that tries to catch any
 * error details thrown by the backend, will be phased out
 * as the backend adopts more structured error responses.
 */
interface UnknownError {
  type?: undefined;
  title?: string;
  detail?: string;
  message?: string;
  details?: string;
  error?: string;
  statusText?: string;
}

/** Possible types of error returned by the server */
export type ServerApiError = UnauthorizedError | BadRequestError | UnknownError;

/**
 * Formats bad request API errors to allow react-hook-form to add error highlighting to invalid fields.
 *
 * The paramNameMap parameter allows us to map to the correct form field name.
 * For example, a form field named vatNum on the frontend may be named vat_id in the API.
 *
 * @param error The original API error
 * @param paramNameMap A map of strings where the keys are API validation error parameter names and values are form field names
 */
export function formatFormError<FormModel>(error: ApiError, paramNameMap: { [key: string]: keyof FormModel }) {
  if (error.type === "bad-request" && error.params?.length) {
    return [
      {
        field: GENERAL_FORM_ERROR_KEY,
        message: error.message,
      },
      ...error.params.map((error) => ({
        field: paramNameMap[error.name],
        message: error.detail,
      })),
    ];
  }

  return error;
}

/**
 * Provides a consistent format for API errors
 *
 * Example usage:
 *
 *    const error = await response.json()
 *
 *    throw new ApiError(error, response.status)
 */
export class ApiError extends Error {
  public type: ApiErrorType;
  public title: string;
  public detail: string;
  public status: number;
  public params?: InvalidParam[];
  public details?: object;

  /**
   * In some legacy errors a JSON string is returned (e.g. when passing Stripe errors blindly)
   * In case of an unknown error without the new `type` field, use this helper to attempt parsing a message from JSON
   */
  static parseJsonMessage(message: string): { details: any; message: string } {
    try {
      if (typeof message === "string") {
        const details = JSON.parse(message);

        return { message: details?.message ?? message, details };
      }
    } catch {
      // Do nothing, message is not JSON
    }

    return { message, details: {} };
  }

  constructor(error: UnauthorizedError | BadRequestError | UnknownError, status: number) {
    if (error.type === "bad-request") {
      super(error.detail);

      this.status = status;
      this.params = error.params;
      this.title = error.title;
      this.detail = error.detail;
    } else if (error.type === "unauthorized") {
      super(error.title);

      this.status = status;
      this.title = error.title;
      this.detail = error.title;
    } else {
      // Fallback to "catch all" errors, this should be phased out as our backend migrates to consistent error types
      const { message, details } = ApiError.parseJsonMessage(
        error.detail || error.title || error.message || error.error || "Something went wrong",
      );
      super(message);

      this.title = error.title || "unknown error";
      this.detail = message;
      this.status = status;
      this.details = details;
    }

    this.type = error.type;
    this.name = "ApiError";
  }
}
