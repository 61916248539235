import useAuthStore, { signOut } from "@store/auth";
import { history } from "@utils/router";
import { executeApiQuery } from "@carbon/shared";

import useSnackbarStore from "../store/snackbar";

export function apiQuery(apiRequest) {
  const { createSnackbar } = useSnackbarStore.getState();

  const handlers = {
    async handleUnauthorized() {
      await signOut();

      createSnackbar("Oops, it looks like your session has expired. Please sign in again.", "error");

      history.push("/login");
    },

    handlePaymentRequired() {
      createSnackbar("A license is required to perform this action, please choose a license.", "error");

      history.push("/licensing");
    },
  };

  return {
    apiRequest: apiRequest,
    run: async () => {
      const token = await useAuthStore.getState().getToken();

      return executeApiQuery(apiRequest, token, handlers);
    },
  };
}
