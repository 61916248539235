import differenceInBusinessDays from "date-fns/differenceInBusinessDays";

import { apiQuery } from "./api";
import { formatCalculationResultV2 } from "./format-calculation-result";

/**
 * calculateDigitalServicesFootprint requests an emission calculation given the parameters
 * and returns the emissions and associated compensation in monetary units
 *
 * @param {Object} form Form data necessary to perform the calculation
 * @param {String} token
 * @returns {Object}
 */
export async function calculateDigitalServicesFootprint(form, token) {
  const apiRequest = {
    type: "POST",
    path: "/v2/emissions/digital",
    data: {
      people: form.numberOfPeople,
      days: differenceInBusinessDays(form.reportingPeriodTo, form.reportingPeriodFrom) + 1,
    },
  };

  const response = await apiQuery(apiRequest, token).run();

  return formatCalculationResultV2(form, response);
}
