import React from "react";
import * as yup from "yup";

import { M2, KWH, GCO2e } from "@components/common/Units";
import { NumberFieldWithAdornment, BuildingTypeDropdown } from "@components/form";
import { requiredNumber } from "../../../components/form/utils/schema";

import {
  FOR_REPORTED_SPACE,
  FOR_WHOLE_BUILDING,
  USE_GENERAL_ESTIMATE,
  WITH_KNOWN_EMISSION_FACTOR,
  SEPARATE_POWER_PLANT,
} from "./constants";

const showSpaceSize = (amountKnown) => amountKnown === FOR_WHOLE_BUILDING || amountKnown === USE_GENERAL_ESTIMATE;
const showBuildingSize = (amountKnown) => amountKnown === FOR_WHOLE_BUILDING;
const showAmountUsed = (amountKnown) => amountKnown === FOR_REPORTED_SPACE || amountKnown === FOR_WHOLE_BUILDING;
const showBuildingType = (amountKnown) => amountKnown === USE_GENERAL_ESTIMATE;
const showEmissionFactor = (usedType) => usedType === WITH_KNOWN_EMISSION_FACTOR || usedType === SEPARATE_POWER_PLANT;

export const COMMON_SCHEMA_CONFIG = {
  amount_known: yup.string().required("Please choose an option"),
  space_size: yup.number().when("amount_known", {
    is: showSpaceSize,
    then: requiredNumber("Space size"),
  }),
  building_size: yup
    .number()
    .min(yup.ref("space_size"), "The building should be bigger than the size of this space")
    .when("amount_known", {
      is: showBuildingSize,
      then: requiredNumber("Building size"),
    }),
  amount_used: yup.number().when("amount_known", { is: showAmountUsed, then: requiredNumber("Amount used") }),
  building_type: yup.string().when("amount_known", { is: showBuildingType, then: yup.string().required() }),
  used_type: yup.string().required("Please choose an option"),
  known_emission_factor: yup
    .number()
    .when("used_type", { is: showEmissionFactor, then: requiredNumber("Emission factor") }),
};

export const SPACE_SIZE_FIELD = {
  label: "What's the size of this space?",
  name: "space_size",
  render(props) {
    return <NumberFieldWithAdornment adornment={<M2 />} {...props} />;
  },
  required: true,
  dependsOn: {
    name: "amount_known",
    condition: showSpaceSize,
  },
};

export const BUILDING_SIZE_FIELD = {
  label: "What's the size of the whole building?",
  name: "building_size",
  render(props) {
    return <NumberFieldWithAdornment adornment={<M2 />} {...props} />;
  },
  required: true,
  dependsOn: {
    name: "amount_known",
    condition: showBuildingSize,
  },
};

export const AMOUNT_USED_FIELD = {
  label: "Amount used",
  name: "amount_used",
  render(props) {
    return <NumberFieldWithAdornment adornment={<KWH />} {...props} />;
  },
  required: true,
  dependsOn: {
    name: "amount_known",
    condition: showAmountUsed,
  },
};

export const BUILDING_TYPE_FIELD = {
  label: "Use the average usage estimate for",
  name: "building_type",
  render: BuildingTypeDropdown,
  required: true,
  dependsOn: {
    name: "amount_known",
    condition: showBuildingType,
  },
};

export const EMISSION_FACTOR_FIELD = {
  label: "Add emission factor",
  name: "known_emission_factor",
  render(props) {
    return <NumberFieldWithAdornment adornment={<GCO2e />} {...props} />;
  },
  required: true,
  dependsOn: {
    name: "used_type",
    condition: showEmissionFactor,
  },
};
