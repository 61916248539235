import React from "react";
import styled, { css } from "styled-components";

import Section from "./Section";
import { palette } from "../theme";

interface Props {
  type?: "section" | "card";
}

export const skeletonShimmerStyles = css`
  position: relative;
  overflow: hidden;
  background: ${palette("gray.light")};

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -40%;
    top: 0;
    height: 100%;
    width: 40%;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.7) 50%, transparent 100%);
    animation: shimmer 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes shimmer {
    from {
      left: -40%;
    }
    to {
      left: 100%;
    }
  }
`;

const LoadingCard = styled.div`
  min-height: 300px;

  ${skeletonShimmerStyles};
`;

const LoadingSection = styled(Section)`
  min-height: 60vh;

  ${skeletonShimmerStyles};
`;

const SkeletonLoader = ({ type = "section" }: Props) => (type === "card" ? <LoadingCard /> : <LoadingSection />);

export default SkeletonLoader;
