import { Grid, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  title: string;
  body: string;
  preview: React.ReactElement;
  reverse: boolean;
}

const DemoSection = ({ title, body, preview, reverse }: Props) => (
  <Grid container spacing={8} justifyContent="center" alignItems="center" direction={reverse ? "row" : "row-reverse"}>
    <Grid item xs={12} sm={6} xl={4}>
      {preview}
    </Grid>
    <Grid item xs={12} sm={6} xl={4}>
      <Typography variant="h4" paragraph>
        {title}
      </Typography>
      <Typography>{body}</Typography>
    </Grid>
  </Grid>
);

export default DemoSection;
