import React from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";

import { NumberFieldWithAdornment } from "@components/form";

const NumberFieldWithDynamicUnit = ({ unitFieldName, ...props }) => {
  const distanceUnit = useWatch({ name: unitFieldName });

  return <NumberFieldWithAdornment adornment={distanceUnit} {...props} />;
};

NumberFieldWithDynamicUnit.propTypes = {
  /** The name of the field containing the unit to render */
  unitFieldName: PropTypes.string.isRequired,
};

export default NumberFieldWithDynamicUnit;
