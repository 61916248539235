import { apiQuery } from "../../../api/api";
import { formatCalculationResultV2 } from "../../../api/format-calculation-result";
import { PurchaseByAmountForm, PurchaseByQuantityForm } from "./index.types";

export async function calculateIndexFootprint(form: PurchaseByAmountForm) {
  const data = {
    type: form.productCategory,
    value: Math.round(form.amount! * 100), // convert euros to cents
    description: form.description || undefined,
  };

  const apiRequest = {
    type: "POST",
    path: `/v2/emissions/index`,
    data,
  };

  const response = await apiQuery(apiRequest).run();

  return formatCalculationResultV2(form, response);
}

export async function calculateProductFootprint(form: PurchaseByQuantityForm) {
  const data = {
    type: form.productCategory,
    quantity: form.quantity,
    description: form.description || undefined,
  };

  const apiRequest = {
    type: "POST",
    path: `/v2/emissions/product`,
    data,
  };

  const response = await apiQuery(apiRequest).run();

  return formatCalculationResultV2(form, response);
}
