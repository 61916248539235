import { createTheme, ThemeOptions } from "@material-ui/core/styles";

import createOverrides from "./overrides";
import palette from "./palette";
import props from "./props";
import typography from "./typography";

const theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  shadows: Array(25).fill("none") as ThemeOptions["shadows"], // Remove all shadows
  palette,
  props,
  typography,
});

// Overrides requires theme.spacing to generate theme aware styles
theme.overrides = createOverrides(theme) as ThemeOptions["overrides"];

export default theme;
