import React from "react";
import { Typography, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PasswordField from "../fields/PasswordField";
import TextField from "../fields/TextField";

export interface LoginFields {
  email: string;
  password: string;
}

const schema: yup.SchemaOf<LoginFields> = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),
  submitLabel: "Sign in",
  defaultValues: {
    termsAndConditions: false,
  },
  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },
  fields: [
    {
      name: "email",
      label: "Email",
      required: true,
      render: TextField,
    },
    {
      name: "password",
      label: "Password",
      required: true,
      render: PasswordField,
    },
  ],
};

export default FORM_CONFIG;
