import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import useAuthStore, {
  isAnonymous,
  isPendingLicense,
  isPendingRegistration,
  isRegistered,
  statusSelector,
} from "./store/auth";
import useSnackbarStore from "./store/snackbar";

export function ProtectedRoute({ children, ...rest }) {
  const status = useAuthStore(statusSelector);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAnonymous(status)) {
          return <>{children}</>;
        } else {
          return <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />;
        }
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  location: PropTypes.object,
  component: PropTypes.node,
};

export function ProtectedRegisteredRoute({ children, ...rest }) {
  const status = useAuthStore(statusSelector);
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isRegistered(status)) {
          return <>{children}</>;
        } else if (isPendingRegistration(status)) {
          return <Redirect to={{ pathname: "/licensing" }} />;
        } else if (isPendingLicense(status)) {
          createSnackbar("To get started please confirm your billing information and proceed to payment", "info");

          return <Redirect to={{ pathname: "/licensing" }} />;
        } else {
          return <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />;
        }
      }}
    />
  );
}

ProtectedRegisteredRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  location: PropTypes.object,
  component: PropTypes.node,
};
