import React from "react";
import instructionsImg from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { CO2, CO2E } from "@components/common/Units";
import BillingInstruction from "@components/common/BillingInstruction";

const RealEstateInstructions = () => (
  <Instructions
    title="How this works"
    imageOne={instructionsImg}
    listItems={[
      <>
        Use Compensate’s Energy & Fuel Use calculator to figure out the carbon footprint of your office use and how much
        offsetting it costs!
      </>,
      <>
        The calculator determines the amount of greenhouse gas emissions created by the premise’s energy use – both from
        use of fuels and bought energy. All the emissions are accounted for, and expressed in <CO2E />.
      </>,
      <>
        The calculator takes into account electricity, district heating, district cooling and use of fuels. Note that
        the amounts of energy used need to be filled in kilowatt hours [kWh].
      </>,
      <>
        If fuels (e.g. for heat or electricity production) have been used in the premises, you can fill this in under
        the “Fuels” section. The unit for used fuels depends on the chosen fuel type.
      </>,
      <> The upstream emissions of purchased fuels and energy are also counted into the calculations automatically.</>,
      <>
        You can add as many locations as you’d like. Simply fill in the basic information and add new values to the
        necessary fields.
      </>,
      <>
        “The Compensation impact” refers to the number of trees required to sequester the amount of <CO2 /> emissions in
        a year. This gives you an idea of how many trees are needed to compensate for the emissions created by your real
        estate use.
      </>,
      <>
        Compensate’s unique overcompensation is already built into the calculator – making sure we can double your
        climate impact!
      </>,
      <BillingInstruction key="billing-instruction" />,
    ]}
  />
);

export default RealEstateInstructions;
