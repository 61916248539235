import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { LoginForm, IntroSection } from "@carbon/shared";
import useAuthStore, { signIn, statusSelector, isSignedIn } from "@store/auth";

const LoginPage = ({ headerHeight }) => {
  const location = useLocation();
  const history = useHistory();
  const signedIn = isSignedIn(useAuthStore(statusSelector));

  const referer = location?.state?.referer?.pathname ?? "/emission-calculators";

  const onSubmit = async (form) => {
    await signIn(form.email, form.password);

    history.replace(referer);
  };

  if (signedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <IntroSection headerHeight={headerHeight} bgcolor="primary.extraLight">
      <Box maxWidth={600} mx="auto" textAlign="center">
        <Typography component="h1" variant="h2" gutterBottom>
          Log in to Compensate Plus
        </Typography>

        <LoginForm onSubmit={onSubmit} />
      </Box>
    </IntroSection>
  );
};

LoginPage.propTypes = {
  headerHeight: PropTypes.number,
};

export default LoginPage;
