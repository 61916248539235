import React from "react";
import instructionsImg from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { CO2, CO2E } from "@components/common/Units";
import BillingInstruction from "@components/common/BillingInstruction";

const IndexInstructions = () => (
  <Instructions
    title="How this works"
    imageOne={instructionsImg}
    listItems={[
      "Use Compensate’s Purchased Products & Services calculator to figure out the estimated carbon footprint for any product or service you have paid for and how much offsetting it costs!",
      "You can calculate the emissions for your digital devices by using the calculator based on quantity. Just choose the devices and how many you want to compensate for. The emissions for digital devices are calculated by using conservative reference emission values for each product group.",
      "You can calculate the emissions for other products and services using the calculator for products and services based on the amount spent. This calculator determines the amount of greenhouse gas emissions of products and services based on the amount spent on them by using Compensate’s price index. ",
      <>
        Compensate’s price index provides an estimate for the carbon intensity (kg
        <CO2E />
        /EUR) for a given product category. The carbon intensity of each product or service category is calculated using
        greenhouse gas emissions and euro expenditure in that category. Using this, the index estimates the carbon
        footprint of products and services based on their purchase price. This price correlates with the resources used
        to produce the product, and therefore its carbon footprint. All the emissions are accounted for, and expressed
        in <CO2E />.
      </>,
      "You can add as many product or service categories as you like – simply add another row.",
      "You can add a description to your purchased products or services (e.g. “summer party”) for your own reference. These will be shown in the Summary.",

      <>
        “The Compensation impact” refers to the number of trees required to sequester the amount of <CO2 /> emissions in
        a year. This gives you an idea of how many trees are needed to compensate for the emissions created by your
        individual carbon footprint.
      </>,
      "Compensate's unique overcompensation is already built into the calculator. It ensures actual climate impact and tackles the risks involved in all carbon capture projects.",
      <BillingInstruction key="billing-instruction" />,
    ]}
  />
);

export default IndexInstructions;
