import React from "react";
import styled from "styled-components";
import { Grid, GridProps } from "@material-ui/core";

import { SingleFormGeneratorProps, MultiFormGeneratorProps, FormGeneratorBaseProps } from "./form.types";
import useHandleSubmit from "./hooks/useHandleSubmit";
import useFormReset from "./hooks/useFormReset";
import { spacing } from "../theme";

interface FormWrapperProps<TForm extends object> {
  onSubmit: SingleFormGeneratorProps<TForm>["onSubmit"] | MultiFormGeneratorProps<TForm>["onSubmit"];
  resetOnSubmit?: FormGeneratorBaseProps<TForm>["resetOnSubmit"];
  asyncFormValues?:
    | SingleFormGeneratorProps<TForm>["asyncFormValues"]
    | MultiFormGeneratorProps<TForm>["asyncFormValues"];
  children: React.ReactNode;
}

const StyledFormGrid = styled((props: GridProps<"form">) => <Grid component="form" noValidate container {...props} />)`
  margin-bottom: ${spacing(7)}px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: ${spacing(4)}px;
  }
`;

function FormWrapper<TForm extends object>({
  onSubmit,
  resetOnSubmit = true,
  asyncFormValues,
  children,
  ...props
}: FormWrapperProps<TForm>) {
  const handleSubmit = useHandleSubmit(onSubmit);

  useFormReset<TForm>(resetOnSubmit, asyncFormValues);

  return (
    <StyledFormGrid onSubmit={handleSubmit} spacing={4} {...props}>
      {children}
    </StyledFormGrid>
  );
}

export default FormWrapper;
