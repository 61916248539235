import React from "react";
import { Link } from "@material-ui/core";

import { STRIPE_PRICING_LINK } from "@constants/links";

const BillingInstruction = () => (
  <>
    By proceeding, you will be directed to finalize your order. Compensate uses Stripe as a payment service provider.
    For more on Stripe{" "}
    <Link href={STRIPE_PRICING_LINK} target="_blank" rel="noopener">
      click here
    </Link>
    .
  </>
);

export default BillingInstruction;
