import React, { ReactElement, ReactNode, useContext } from "react";

interface FeatureProviderProps {
  /** An array of key value pairs where the key represents a feature, and the value represents whether it's enabled in this environment */
  features: { [key: string]: boolean };
  children: ReactNode;
}

interface FeatureGateProps {
  feature: string;
  children: ReactElement;
}

const FeatureContext = React.createContext<FeatureProviderProps["features"]>({});

/**
 * Provides FeatureGate with the list of feature flags for this environment
 */
export const FeatureProvider = ({ features, children }: FeatureProviderProps) => (
  <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>
);

export function useFeatureContext() {
  const features = useContext(FeatureContext);

  return features;
}

/**
 * Conditionally render children based on the specified feature flag
 *
 * FeatureGate must be rendered as a child of FeatureProvider somewhere in the React tree
 */
const FeatureGate = ({ children, feature }: FeatureGateProps) => {
  const features = useContext(FeatureContext);

  return features[feature] ? children : null;
};

export default FeatureGate;
