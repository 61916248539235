import React from "react";
import { Footer, ChangeCookieHubConsent } from "@carbon/shared";

import { ACCESS_LINKS, GENERAL_NAV_ITEMS, SUBSCRIBED_GENERAL_NAV_ITEMS } from "@constants/navigation";
import useAuthStore, { isAnonymous, statusSelector, isRegistered } from "@store/auth";

const FooterContainer = () => {
  const userStatus = useAuthStore(statusSelector);
  const showSignIn = isAnonymous(userStatus);
  const productLinks = isRegistered(userStatus) ? SUBSCRIBED_GENERAL_NAV_ITEMS : GENERAL_NAV_ITEMS;

  return (
    <Footer
      accessLinks={showSignIn ? ACCESS_LINKS : []}
      productLinks={productLinks}
      tagline="Compensate Plus – Calculate your business’ carbon footprint"
      legalInfo={<ChangeCookieHubConsent color="inherit" />}
    />
  );
};

export default FooterContainer;
