import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Link as MuiLink } from "@material-ui/core";
import PropTypes from "prop-types";
import { signOut } from "@store/auth";

const SignInOrOut = ({ className, isSignedIn, referer }) => {
  const history = useHistory();

  const handleSignout = (e) => {
    e.preventDefault();

    return signOut().then(() => history.push(referer));
  };

  if (isSignedIn) {
    return (
      <MuiLink color="inherit" component={Link} className={className} to="#" onClick={handleSignout}>
        Sign out
      </MuiLink>
    );
  }

  return (
    <MuiLink color="inherit" component={NavLink} className={className} to="/login">
      Sign in
    </MuiLink>
  );
};

SignInOrOut.propTypes = {
  className: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
  referer: PropTypes.string,
};

export default SignInOrOut;
