import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import NavigationItem from "./NavigationItem";
import styled from "styled-components";

const StyledGrid = styled(Grid)`
  list-style: none;
`;

const NavigationList = ({ items }) => (
  <StyledGrid component="ul" item xs={12} sm={10} md={6} xl={4}>
    {items.map((item) => (
      <NavigationItem key={item.path} {...item} />
    ))}
  </StyledGrid>
);

NavigationList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
};

export default NavigationList;
