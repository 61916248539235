import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { CountryRegionData } from "react-country-region-selector";
import { BillingInfoForm, IntroSection, Button, ApiError, formatFormError } from "@carbon/shared";

import { createStripeCheckout, registerCustomer, updateCustomer } from "@api/customer-api";
import config from "@config";
import useAuthStore, {
  cocoUserSelector,
  fetchCocoUserSelector,
  isRegistered,
  isPendingRegistration,
  statusSelector,
} from "@store/auth";
import useLocalStorage from "@hooks/useLocalStorage";
import { LICENSE_LOCAL_STORAGE_KEY } from "@constants/license";
import { getStripeLookupById } from "@utils/license";
import { isPendingLicense } from "@store/auth";
import useSnackbarStore from "../../store/snackbar";
import useStripePortal from "../../hooks/useStripePortal";

const stripePromise = loadStripe(config.get("stripeLicensingKey"));

const getCountryOptionFromValue = (value) => {
  if (!value) {
    return null;
  }

  const country = CountryRegionData.find(([, code]) => code === value);

  return {
    value,
    label: country ? country[0] : value,
  };
};

const getOptionFromValue = (value) => {
  if (!value) {
    return null;
  }

  return { value, label: value };
};

const getSubmitLabel = (userRegistered, userPendingLicense) => {
  if (!userRegistered || userPendingLicense) {
    return "Proceed to payment";
  }

  if (!userRegistered) {
    return "Submit";
  }

  return "Save billing information";
};

const mapCustomerToForm = (customer) => ({
  contactName: customer.contact_name,
  contactEmailAddress: customer.email,
  businessCountryDomicile: getCountryOptionFromValue(customer.business_domicile),
  companyName: customer.name,
  businessId: customer.business_details?.business_id,
  vatNum: customer.business_details?.vat_id,
  country: getCountryOptionFromValue(customer.address?.country),
  address: customer.address?.street,
  state: getOptionFromValue(customer.address?.state),
  city: customer.address?.city,
  postCode: customer.address?.postcode,
});

const BillingInfoPage = ({ headerHeight }) => {
  const userStatus = useAuthStore(statusSelector);
  const userPendingLicense = isPendingLicense(userStatus);
  const userRegistered = isRegistered(userStatus);
  const userPendingRegistration = isPendingRegistration(userStatus);

  const fetchCocoUser = useAuthStore(fetchCocoUserSelector);
  const customer = useAuthStore(cocoUserSelector);
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);

  const location = useLocation();
  const history = useHistory();
  // Default to selectedLicense in location state, necessary for users who select a license before signing up
  const [selectedLicense] = useLocalStorage(LICENSE_LOCAL_STORAGE_KEY, location.state?.selectedLicense);

  const { isLoading, redirectToStripePortal } = useStripePortal();

  const [asyncFormValues, setAsyncFormValues] = useState({});

  const isEditMode = userRegistered || userPendingLicense;

  useEffect(() => {
    if (isEditMode) {
      setAsyncFormValues(mapCustomerToForm(customer));
    }
  }, [customer, isEditMode]);

  useEffect(() => {
    // If a license hasn't been selected redirect to the licensing page
    if (!selectedLicense && (userPendingLicense || userPendingRegistration)) {
      history.push("/licensing");
    }
  }, [history, selectedLicense, userPendingLicense, userPendingRegistration]);

  const stripeCheckout = async (license) => {
    try {
      const stripe = await stripePromise;
      const session = await createStripeCheckout(license);
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        throw result.error;
      }
    } catch (error) {
      // Fetch new user information if stripe fails since the user has been created
      fetchCocoUser();

      // The error is re-thrown to allow react-hook-form to add error highlighting to form fields
      if (error instanceof ApiError) {
        const formattedError = formatFormError(error, { vat_id: "vatNum" });

        createSnackbar(`Failed to create your invoice. ${error.message}`, "error");

        throw formattedError;
      }

      throw error;
    }
  };

  const handleSubmit = async (values) => {
    if (isEditMode) {
      await updateCustomer(values).run();

      if (userPendingLicense) {
        await stripeCheckout(getStripeLookupById(selectedLicense));
      } else {
        setAsyncFormValues(values);
        fetchCocoUser();
        createSnackbar("Billing information updated");
      }
    } else {
      await registerCustomer(values).run();

      await stripeCheckout(getStripeLookupById(selectedLicense));
    }
  };

  return (
    <>
      <IntroSection headerHeight={headerHeight} bgcolor="primary.extraLight">
        <Box maxWidth={800} mx="auto">
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            Billing Information
          </Typography>

          <Typography component="h2" variant="h5" align="center" gutterBottom>
            * = required fields
          </Typography>

          <BillingInfoForm
            editMode={userRegistered}
            onSubmit={handleSubmit}
            resetOnSubmit={false}
            asyncFormValues={asyncFormValues}
            submitLabel={getSubmitLabel(userRegistered, userPendingLicense)}
            submitButtonProps={{
              withArrow: !userRegistered || userPendingLicense,
              "data-testid": "billing-submit",
            }}
          />
          {userRegistered && (
            <Button variant="text" withArrow onClick={redirectToStripePortal} loading={isLoading}>
              Update your payment method
            </Button>
          )}
        </Box>
      </IntroSection>
    </>
  );
};

BillingInfoPage.propTypes = {
  headerHeight: PropTypes.number,
};

export default BillingInfoPage;
