export const QUERY_KEY = {
  FLOW: "flow",
  AMOUNT: "amount",
  TYPE: "type",
};

export const QUERY_FLOW = {
  REGISTER: "register",
  END_TRIAL_AND_COMPENSATE: "first-compensation",
  COMPENSATE: "compensate",
};
