import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { Button } from "@carbon/shared";
import { MEDIUM_DISTANCE_FLIGHT, SHORT_DISTANCE_FLIGHT } from "@constants/distance";

const ToggleHaulDistance = ({ distanceFieldName }) => {
  const { setValue } = useFormContext();

  const setDistance = (distance) => {
    setValue(distanceFieldName, distance);
  };

  return (
    <span>
      Or add an estimate:{" "}
      <Button onClick={() => setDistance(SHORT_DISTANCE_FLIGHT)} variant="text" size="small">
        Short to medium haul
      </Button>{" "}
      <Button onClick={() => setDistance(MEDIUM_DISTANCE_FLIGHT)} variant="text" size="small">
        Medium to long haul
      </Button>
    </span>
  );
};

ToggleHaulDistance.propTypes = {
  distanceFieldName: PropTypes.string.isRequired,
};

export default ToggleHaulDistance;
