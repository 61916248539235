import React, { createElement } from "react";
import { Grid, GridProps } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import get from "lodash/get";

const RowBreak = styled.div`
  flex-basis: 100%;
  height: 0;
  padding: 0;
  margin: 0;
  visibility: hidden;
`;

function FieldGenerator({ render, props, wrapperProps, dependsOn, newRow, name, ...rest }: FieldGeneratorProps) {
  const { register, errors, watch } = useFormContext();

  if (typeof render !== "function") {
    return null;
  }

  if (typeof dependsOn?.name === "string") {
    const parentValue = watch(dependsOn.name);

    if (typeof dependsOn.condition === "function" && !dependsOn.condition(parentValue)) {
      return null;
    }
  } else if (dependsOn?.fields instanceof Object) {
    const { fields } = dependsOn;
    const parentNames = Object.keys(fields);
    const parentValues = watch(Object.keys(fields));

    if (!parentNames.every((name) => typeof fields[name] === "function" && fields[name](parentValues[name]))) {
      return null;
    }
  }

  return (
    <>
      {newRow && <RowBreak />}
      <Grid item xs={12} data-testid={name} {...wrapperProps}>
        {createElement(render, {
          register,
          name,
          error: name ? get(errors, name) : undefined,
          ...props,
          ...rest, // @deprecated - Instead use the `props` object to supply the render method with props
        })}
      </Grid>
    </>
  );
}

export interface FieldGeneratorProps {
  defaultValue?: any;
  /** Used if showing this field depends on the value of another field */
  dependsOn?: {
    /** A function that returns a boolean to determine whether this field should be shown. */
    condition: (value: any) => boolean;
    /** The name of the field this depends on */
    name: string;
    /** If this depends on multiple fields provide a map of names to conditions e.g. { fieldOne: () => boolean, fieldTwo: () => bool } */
    fields: { [key: string]: (value: any) => boolean };
  };
  newRow?: boolean;
  render: (props: any) => JSX.Element;
  /** Props spread onto the wrapper of this field */
  wrapperProps?: GridProps;
  /** Props spread onto the field component or element of this component */
  fieldProps?: any;
  /** Props passed into the render prop of this field */
  props?: any;
  /** Human readable label */
  label?: React.ReactChild | React.ReactFragment;
  /** The name given to the input element and used to reference this field in state, must be unique per form */
  name?: string;
  /** Show this field as required in the UI. Required validation is handled by the schema */
  required?: boolean;
}

export default FieldGenerator;
