import React from "react";
import { Typography, Grid } from "@material-ui/core";
import styled from "styled-components";
import { Section, getStaticAsset } from "@carbon/shared";

const PARTNERS = [
  { name: "LSE", logoPath: "/images/lse.png", url: "https://www.lse.ac.uk/" },
  { name: "Supercell", logoPath: "/images/supercell.png", url: "https://supercell.com/" },
  { name: "Reima", logoPath: "/images/reima.png", url: "https://reima.com/" },
  { name: "Noli", logoPath: "/images/noli.png", url: "https://nolistudios.com/" },
  { name: "Hifk", logoPath: "/images/hifk.png", url: "https://hifk.fi/" },
  { name: "Smartly", logoPath: "/images/smartly.png", url: "https://www.smartly.io/" },
];

const CenteredTypography = styled(Typography)`
  text-align: center;
`;

const PartnerLink = styled.a`
  display: block;
  text-align: center;
  transition: filter 0.2s;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0%);
  }
`;

const PartnerImg = styled.img`
  width: 100%;
  max-width: 100px;
`;

const PartnersSection = () => (
  <Section py={[4, null, 6]}>
    <CenteredTypography variant="h4" gutterBottom>
      Compensate is trusted by
    </CenteredTypography>

    <Grid container spacing={8} justifyContent="center" alignItems="center">
      {PARTNERS.map((partner) => (
        <Grid key={partner.name} item xs={4} md={2}>
          <PartnerLink href={partner.url} target="_blank" rel="noopener">
            <PartnerImg src={getStaticAsset(partner.logoPath)} alt={partner.name} />
          </PartnerLink>
        </Grid>
      ))}
    </Grid>
  </Section>
);

export default PartnersSection;
