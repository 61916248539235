import create, { State } from "zustand";

import { DatePresetOption, DateRange, DATE_PRESET_RANGES, PresetRange } from "../constants/dates";

interface DateRangeState extends State {
  /** The possible selectable date options, for example "This month" */
  presets: PresetRange[];
  selectedPreset: DatePresetOption;
  /** The start and end date of the currently selected date preset */
  range: DateRange;
  /** Used to set up the possible selectable date options, if different options than the default are needed */
  configurePresets: (presets: PresetRange[]) => void;
  handleSelectPreset: (preset: DatePresetOption) => void;
}

// State selectors to avoid creating a new function on each render
export const configurePresetsSelector = (state: DateRangeState) => state.configurePresets;
export const dateRangeSelector = (state: DateRangeState) => state.range;
export const selectedDatePresetSelector = (state: DateRangeState) => state.selectedPreset;
export const handleSelectDatePresetSelector = (state: DateRangeState) => state.handleSelectPreset;

/**
 * Handle global date range state, used to determine
 * the start_date and end_date of API requests.
 */
const useDateRangePicker = create<DateRangeState>((set, get) => ({
  configurePresets(presets: PresetRange[]) {
    const { selectedPreset } = get();

    // If the presets don't include the currently selected preset,
    // set a new selectedPreset that's included in the new list of presets.
    if (!presets.some((preset) => preset.value === selectedPreset)) {
      const nextSelected = presets[0];

      set({
        range: nextSelected.getRange(),
        selectedPreset: nextSelected.value,
      });
    }

    set({ presets });
  },

  presets: DATE_PRESET_RANGES,

  selectedPreset: "all-time",

  range: new DateRange(),

  handleSelectPreset(preset: DatePresetOption) {
    const { presets } = get();
    const selectedPreset = presets.find(({ value }) => value === preset);

    if (selectedPreset) {
      set({
        range: selectedPreset.getRange(),
        selectedPreset: preset,
      });
    }
  },
}));

export default useDateRangePicker;
