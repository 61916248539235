import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Dropdown } from "@components/form";

import {
  CALCULATE_BY,
  LORRY_TYPE_OPTIONS,
  GENERIC_FUEL_TYPE_OPTIONS,
  VEHICLE_TYPE,
  AVERAGE_OPTIONS,
  CAR_FUEL_TYPE_OPTIONS,
} from "../constants";
import { useEffect } from "react";

const getOptionsBySelection = (landCalculationBy, vehicleType) => {
  if (landCalculationBy === CALCULATE_BY.WEIGHT) {
    if (vehicleType === VEHICLE_TYPE.HEAVY) {
      return LORRY_TYPE_OPTIONS;
    }

    if (vehicleType === VEHICLE_TYPE.VAN || vehicleType === VEHICLE_TYPE.SMALL) {
      return GENERIC_FUEL_TYPE_OPTIONS;
    }
  }

  if (landCalculationBy === CALCULATE_BY.DISTANCE) {
    if (vehicleType === VEHICLE_TYPE.HEAVY) {
      return LORRY_TYPE_OPTIONS;
    }

    if (vehicleType === VEHICLE_TYPE.VAN) {
      return GENERIC_FUEL_TYPE_OPTIONS;
    }

    if (vehicleType === VEHICLE_TYPE.SMALL) {
      return CAR_FUEL_TYPE_OPTIONS;
    }
  }

  return AVERAGE_OPTIONS;
};

/**
 * Renders different vehicle detail options based on the form state
 *
 * Unlike other form components, VehicleDetailsDropdown's all seeing eyes are aware of the "landCalculationBy" and "vehicleType" fields.
 * This is a special case and most fields shouldn't know about the keys used elsewhere
 */
const VehicleDetailsDropdown = ({ name, ...props }) => {
  const [options, setOptions] = useState([]);
  const { setValue } = useFormContext();
  const { landCalculationBy, vehicleType } = useWatch({
    name: ["landCalculationBy", "vehicleType"],
  });

  useEffect(() => {
    setOptions(getOptionsBySelection(landCalculationBy, vehicleType));
    setValue(name, "");
  }, [name, setValue, landCalculationBy, vehicleType]);

  return <Dropdown name={name} {...props} options={options} />;
};

VehicleDetailsDropdown.propTypes = Dropdown.propTypes;

export default VehicleDetailsDropdown;
