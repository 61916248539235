import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { FRAME_MARGIN } from "../constants/spacing";

export interface SectionProps extends BoxProps {
  noPadding?: boolean;
}

/**
 * A simple component that composes Box to create sections within a Frame
 * To be used at the top level inside a Frame component, setting a consistent padding and margin
 */
const Section = ({ children, noPadding, ...props }: SectionProps) => (
  <Box
    component="section"
    my={FRAME_MARGIN}
    py={noPadding ? undefined : [8, 12, 16]}
    px={noPadding ? undefined : [4, 8, 12, 20]}
    {...props}
  >
    {children}
  </Box>
);

export default Section;
