import React from "react";
import MuiSnackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import useSnackbarStore from "../../store/snackbar";

const Snackbar = () => {
  const snackbar = useSnackbarStore((state) => state.snackbar);
  const isOpen = useSnackbarStore((state) => state.isOpen);
  const closeSnackbar = useSnackbarStore((state) => state.closeSnackbar);
  const clearSnackbar = useSnackbarStore((state) => state.clearSnackbar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    closeSnackbar();
  };

  if (snackbar === null) {
    return null;
  }

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={isOpen}
      autoHideDuration={10000}
      onClose={handleClose}
      TransitionProps={{
        onExited: clearSnackbar,
      }}
    >
      <Alert onClose={handleClose} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
