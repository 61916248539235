import config from "react-global-configuration";
import { local, dev, prod } from "./environments";

switch (window.location.hostname) {
  case "localhost":
    config.set(local);
    break;
  case "partners-dev.compensate.com":
    config.set(dev);
    break;
  case "partners.compensate.com":
  case "compensateplus.com":
    config.set(prod);
    break;
  default:
    config.set(prod); // Conservative default
    break;
}

export default config;
