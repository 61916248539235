import React from "react";

import { CalculatorInfo, Package } from "./packages.types";

type SupportedCases =
  | "FREELANCER_REAL_ESTATE"
  | "FREELANCER_DIGITAL"
  | "FLIGHTS"
  | "CONSULTANCY_REAL_ESTATE"
  | "CONSULTANCY_DIGITAL"
  | "PERSONAL"
  | "BOUTIQUE_REAL_ESTATE"
  | "LOGISTICS";

export const CASES: { [key in SupportedCases]: CalculatorInfo } = {
  FREELANCER_REAL_ESTATE: {
    icon: "house",
    description: (
      <>
        Compensate the emissions from your co-working space or even the office you use in your own home with our{" "}
        <b>Energy & Fuel Use Calculator</b>
      </>
    ),
    data: "Electricity bills or the size of your office space",
  },
  FREELANCER_DIGITAL: {
    icon: "laptop",
    description: (
      <>
        Compensate the emissions from your use of digital services with our <b>Digital Calculator</b>. This includes the
        use of a laptop and mobile phone, and streaming.
      </>
    ),
    data: "The number of employees at your company",
  },
  FLIGHTS: {
    icon: "plane",
    description: (
      <>
        Compensate the emissions from your business travel with our <b>Flights Calculator</b>.
      </>
    ),
    data: "Your flight history or upcoming flights",
  },
  CONSULTANCY_REAL_ESTATE: {
    icon: "house",
    description: (
      <>
        Compensate the emissions from your office or your employees’ home offices with our{" "}
        <b>Energy & Fuel Use Calculator</b>. All you need is your electricity bills or the size of the space to get
        started.
      </>
    ),
    data: "Electricity bills or the size of your workspaces",
  },
  CONSULTANCY_DIGITAL: {
    icon: "laptop",
    description: (
      <>
        Compensate the emissions from your employees’ use of digital services with our <b>Digital Calculator</b>. This
        includes the use of a laptop and mobile phone, and streaming.
      </>
    ),
  },
  PERSONAL: {
    icon: "box",
    description: (
      <>Make carbon negativity an employment perk, and compensate for your employees’ personal carbon footprint.</>
    ),
    data: "The number of employees and their location",
  },
  BOUTIQUE_REAL_ESTATE: {
    icon: "house",
    description: (
      <>
        Compensate the emissions from your shop or warehouse with our <b>Energy & Fuel Use Calculator</b>. All you need
        is your electricity bills or the size of the space to get started.
      </>
    ),
    data: "Electricity bills or the size of your shop or warehouse",
  },
  LOGISTICS: {
    icon: "package",
    description: (
      <>
        Compensate the emissions from shipping your products, either from the manufacturer or to your customers, with
        our <b>Logistics Calculator</b>.
      </>
    ),
    data: "Details of your shipping activities by distance, weight or the amount of fuel used",
  },
};

export const PACKAGES: Package[] = [
  {
    label: "I'm a Freelancer",
    useCases: [CASES.FREELANCER_REAL_ESTATE, CASES.FREELANCER_DIGITAL, CASES.FLIGHTS],
  },
  {
    label: "I run a Consultancy",
    useCases: [CASES.CONSULTANCY_REAL_ESTATE, CASES.CONSULTANCY_DIGITAL, CASES.FLIGHTS, CASES.PERSONAL],
    testimonial: {
      title: "What other consultancies say",
      quote:
        "An ethical, sustainable solution for offsetting emissions, based on strong expertise. The service is easy to use and fairly priced, so it is well-suited to the needs of a small company. The company contacts are knowledgable and friendly.",
      author: "Niina Reina, Certification Services, Peili Consulting Oy",
    },
  },
  {
    label: "I run a Boutique",
    useCases: [CASES.BOUTIQUE_REAL_ESTATE, CASES.LOGISTICS, CASES.PERSONAL],
    testimonial: {
      title: "What other boutiques say",
      quote:
        "I was really happy to see how the Compensate Plus service helped us to identify the main carbon footprint elements and calculate compensation cost. Becoming carbon neutral gives us peace of mind that we are doing the right thing, which is not only good from an environmental angle but it also helps us to differentiate in the market!",
      author: "Founder and CEO, Ape Gelato",
    },
  },
];

export default PACKAGES;
