import React from "react";

import FormGenerator, { FormGeneratorProps } from "../FormGenerator";
import { FORM_CONFIG_EDIT_MODE, FORM_CONFIG_CREATE_MODE } from "../config/billing-info";
import { CustomerForm } from "../../types";

interface Props
  extends Pick<
    FormGeneratorProps<CustomerForm>,
    "onSubmit" | "resetOnSubmit" | "asyncFormValues" | "submitLabel" | "submitButtonProps"
  > {
  editMode: boolean;
}

function BillingInfoForm({ editMode, onSubmit, ...props }: Props) {
  const formConfig = editMode ? FORM_CONFIG_EDIT_MODE : FORM_CONFIG_CREATE_MODE;

  return <FormGenerator {...formConfig} onSubmit={onSubmit} justifyContent="space-between" {...props} />;
}

export default BillingInfoForm;
