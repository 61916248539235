import React from "react";
import { GridProps } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";

import { SingleFormGeneratorProps, MultiFormGeneratorProps, FormGeneratorBaseProps } from "./form.types";
import FieldGenerator from "./FieldGenerator";
import FieldArrayRows from "./FieldArrayRows";
import GeneralFormError from "./GeneralFormError";
import FormWrapper from "./FormWrapper";
import SubmitFormButton from "./SubmitFormButton";

export type FormGeneratorProps<TForm extends object> = (
  | SingleFormGeneratorProps<TForm>
  | MultiFormGeneratorProps<TForm>
) &
  FormGeneratorBaseProps<TForm> &
  Omit<GridProps<"form">, "onSubmit">;

function getDefaultValues<TForm>(defaultValues: TForm, fieldArray: boolean, formName: string) {
  if (fieldArray) {
    return { [formName]: [defaultValues] };
  }

  return defaultValues;
}

function FormGenerator<TForm extends object>({
  asyncFormValues,
  defaultValues,
  duplicateFieldsOnAppend,
  fieldArray = false,
  fields,
  formName = "form",
  onSubmit,
  resetOnSubmit = true,
  resolver,
  submitButtonProps,
  submitLabel,
  submitWrapperProps,
  ...props
}: FormGeneratorProps<TForm>) {
  const formMethods = useForm({
    resolver,
    defaultValues: getDefaultValues<TForm>(defaultValues, fieldArray, formName),
  });

  return (
    <FormProvider {...formMethods}>
      <FormWrapper onSubmit={onSubmit} asyncFormValues={asyncFormValues} resetOnSubmit={resetOnSubmit} {...props}>
        <GeneralFormError />
        {fieldArray ? (
          <FieldArrayRows<TForm>
            formName={formName}
            defaultValues={defaultValues}
            duplicateFieldsOnAppend={duplicateFieldsOnAppend}
            fields={fields}
          />
        ) : (
          fields.map((field, i) => <FieldGenerator key={`${i}-${field.name}`} {...field} />)
        )}
        <SubmitFormButton label={submitLabel} wrapperProps={submitWrapperProps} {...submitButtonProps} />
      </FormWrapper>
    </FormProvider>
  );
}

FormGenerator.defaultProps = {
  submitButtonProps: {},
  submitWrapperProps: {},
};

export default FormGenerator;
