import { mileToKm } from "@utils/unit-conversion";
import { NAUTICAL_MILES } from "@constants/distance";
import { apiQuery } from "./api";
import { formatCalculationResultV2 } from "./format-calculation-result";
import {
  AIRPORTS_KNOWN,
  CALCULATE_BY,
  GENERIC_FUEL_TYPE,
  VEHICLE_TYPE,
} from "../pages/calculators/logistics/constants";

// KINDS holds values of the API params to be send to backend
const KINDS = {
  rigid: "freight_over_land_heavy_goods_vehicle(rigid)",
  articulated: "freight_over_land_heavy_goods_vehicle(articulated)",
  unknown: "freight_over_land_heavy_goods_vehicle(type_unknown)",
  refrigeratedUnknown: "freight_over_land_refrigerated_hgv(type_unknown)",
  refrigeratedRigid: "freight_over_land_refrigerated_hgv(rigid)",
  refrigeratedArticulated: "freight_over_land_refrigerated_hgv(articulated)",
  average: "freight_over_land_average",
  vanDiesel: "freight_over_land_van(average,diesel)",
  vanPetrol: "freight_over_land_van(average,petrol)",
  vanElectric: "freight_over_land_van(electric)",
  vanAverage: "freight_over_land_van(average,fuel_unknown)",
  carDiesel: "freight_over_land_car(diesel)",
  carPetrol: "freight_over_land_car(petrol)",
  carElectric: "freight_over_land_car(electric)",
  carAverage: "freight_over_land_car(fuel_unknown)",
  smallDiesel: "small_delivery_vehicles(diesel)",
  smallElectric: "small_delivery_vehicles(electric_vehicle)",
  smallFlexi: "small_delivery_vehicles(flexifuel)",
  smallGasoline: "small_delivery_vehicles(gasoline)",
  smallHybrid: "small_delivery_vehicles(plug_in_hybrid)",
  biodieselTruck: "heavy_delivery_vehicles_by_biodiesel",
  biogasTruck: "heavy_delivery_vehicles_by_biogas",
  ethanolTruck: "heavy_delivery_vehicles_by_ethanol_blendedfuel(flexifuel)",
  dieselTruck: "heavy_delivery_vehicles_by_fuel_diesel(average_biofuel_blend)",
  gasolineTruck: "heavy_delivery_vehicles_by_fuel_gasoline(average_biofuel_blend)",
  gasTruck: "heavy_delivery_vehicles_by_natural_gas",
  biodieselCar: "small_delivery_vehicles_by_biodiesel",
  biogasCar: "small_delivery_vehicles_by_biogas",
  ethanolCar: "small_delivery_vehicles_by_ethanol_blendedfuel(flexifuel)",
  dieselCar: "small_delivery_vehicles_by_fuel_diesel(average_biofuel_blend)",
  gasolineCar: "small_delivery_vehicles_by_fuel_gasoline(average_biofuel_blend)",
  gasCar: "small_delivery_vehicles_by_natural_gas",
  train: "freight_by_sea_or_rail_freight_train",
  shipContainer: "freight_by_sea_or_rail_cargo_ship(container_ship)",
  shipRefrigerated: "freight_by_sea_or_rail_cargo_ship(refrigerated_cargo)",
  shipFerry: "freight_by_sea_or_rail_cargo_ship(cruiseferry)",
};

const makeByWeightRequest = (kind) => {
  return (values, description) => {
    return {
      distance: values.distance,
      weight: values.weight,
      type: `by/weight/${kind}`,
      description,
    };
  };
};

const makeByWeightRequestForShip = (kind) => {
  return (values, description = "Sea") => {
    const distance = values.distanceUnit === NAUTICAL_MILES ? mileToKm(values.distance) : values.distance;
    return {
      distance,
      weight: values.weight,
      type: `by/weight/${kind}`,
      description,
    };
  };
};

const makeByDistanceRequest = (kind) => {
  return (values, description) => {
    return {
      distance: values.distance,
      type: `by/distance/${kind}`,
      description,
    };
  };
};

const makeByFuelRequest = (kind) => {
  return (values, description = "Land") => {
    return {
      usage: values.fuelAmount,
      type: `by/fuel/${kind}`,
      description,
    };
  };
};

const logisticsDecisionTree = {
  air: {
    by_airports: (values) => {
      return {
        origin: values.airportFrom.iata_code,
        destination: values.airportTo.iata_code,
        weight: values.weight,
        type: "by/air",
        description: "Air",
      };
    },
    by_air: (values) => {
      return {
        distance: values.distance,
        weight: values.weight,
        quantity: values.quantity,
        type: "by/air",
        description: "Air",
      };
    },
  },
  land: {
    [CALCULATE_BY.WEIGHT]: {
      average: makeByWeightRequest(KINDS.average),
      rigid: makeByWeightRequest(KINDS.rigid),
      articulated: makeByWeightRequest(KINDS.articulated),
      unknown: makeByWeightRequest(KINDS.unknown),
      refrigerated_unknown: makeByWeightRequest(KINDS.refrigeratedUnknown),
      refrigerated_rigid: makeByWeightRequest(KINDS.refrigeratedRigid),
      refrigerated_articulated: makeByWeightRequest(KINDS.refrigeratedArticulated),
      car_estimated_average: makeByWeightRequest(KINDS.smallGasoline),
      car_gasoline: makeByWeightRequest(KINDS.smallGasoline),
      car_diesel: makeByWeightRequest(KINDS.smallDiesel),
      car_ethanol: makeByWeightRequest(KINDS.smallFlexi),
      car_electric: makeByWeightRequest(KINDS.smallElectric),
      car_hybrid: makeByWeightRequest(KINDS.smallHybrid),
      default: makeByWeightRequest(KINDS.average),

      // GENERIC_FUEL_TYPE options are used by both van and car, so we must check vehicleType to get the endpoint
      [GENERIC_FUEL_TYPE.DIESEL]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanDiesel : KINDS.carDiesel;

        return makeByWeightRequest(kind)(values, "Land");
      },
      [GENERIC_FUEL_TYPE.PETROL]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanPetrol : KINDS.carPetrol;

        return makeByWeightRequest(kind)(values, "Land");
      },
      [GENERIC_FUEL_TYPE.ELECTRIC]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanElectric : KINDS.carElectric;

        return makeByWeightRequest(kind)(values, "Land");
      },
      [GENERIC_FUEL_TYPE.FUEL_UNKNOWN]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanAverage : KINDS.carAverage;

        return makeByWeightRequest(kind)(values, "Land");
      },
    },
    [CALCULATE_BY.DISTANCE]: {
      average: makeByDistanceRequest(KINDS.average),
      rigid: makeByDistanceRequest(KINDS.rigid),
      articulated: makeByDistanceRequest(KINDS.articulated),
      unknown: makeByDistanceRequest(KINDS.unknown),
      refrigerated_unknown: makeByDistanceRequest(KINDS.refrigeratedUnknown),
      refrigerated_rigid: makeByDistanceRequest(KINDS.refrigeratedRigid),
      refrigerated_articulated: makeByDistanceRequest(KINDS.refrigeratedArticulated),
      car_estimated_average: makeByDistanceRequest(KINDS.smallGasoline),
      car_gasoline: makeByDistanceRequest(KINDS.smallGasoline),
      car_diesel: makeByDistanceRequest(KINDS.smallDiesel),
      car_ethanol: makeByDistanceRequest(KINDS.smallFlexi),
      car_electric: makeByDistanceRequest(KINDS.smallElectric),
      car_hybrid: makeByDistanceRequest(KINDS.smallHybrid),
      default: makeByDistanceRequest(KINDS.average),

      // GENERIC_FUEL_TYPE options are used by both van and car, so we must check vehicleType to get the endpoint
      [GENERIC_FUEL_TYPE.DIESEL]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanDiesel : KINDS.carDiesel;

        return makeByDistanceRequest(kind)(values, "Land");
      },
      [GENERIC_FUEL_TYPE.PETROL]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanPetrol : KINDS.carPetrol;

        return makeByDistanceRequest(kind)(values, "Land");
      },
      [GENERIC_FUEL_TYPE.ELECTRIC]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanElectric : KINDS.carElectric;

        return makeByDistanceRequest(kind)(values, "Land");
      },
      [GENERIC_FUEL_TYPE.FUEL_UNKNOWN]: (values) => {
        const kind = values.vehicleType === VEHICLE_TYPE.VAN ? KINDS.vanAverage : KINDS.carAverage;

        return makeByDistanceRequest(kind)(values, "Land");
      },
    },
    [CALCULATE_BY.FUEL]: {
      heavy: {
        gasoline: makeByFuelRequest(KINDS.gasolineTruck),
        diesel: makeByFuelRequest(KINDS.dieselTruck),
        ethanol: makeByFuelRequest(KINDS.ethanolTruck),
        biodiesel: makeByFuelRequest(KINDS.biodieselTruck),
        biogas: makeByFuelRequest(KINDS.biogasTruck),
        gas: makeByFuelRequest(KINDS.gasTruck),
      },
      small: {
        gasoline: makeByFuelRequest(KINDS.gasolineCar),
        diesel: makeByFuelRequest(KINDS.dieselCar),
        ethanol: makeByFuelRequest(KINDS.ethanolCar),
        biodiesel: makeByFuelRequest(KINDS.biodieselCar),
        biogas: makeByFuelRequest(KINDS.biogasCar),
        gas: makeByFuelRequest(KINDS.gasCar),
      },
    },
  },
  rail: makeByWeightRequest(KINDS.train),
  sea: {
    container_ship: makeByWeightRequestForShip(KINDS.shipContainer),
    refrigerated_cargo: makeByWeightRequestForShip(KINDS.shipRefrigerated),
    ferry: makeByWeightRequestForShip(KINDS.shipFerry),
    default: makeByWeightRequestForShip(KINDS.shipContainer),
  },
};

/**
 * TODO refactor calculateLogisticsFootprint
 *
 * @values values Hash with necessary data to perform calculation
 * @return Hash with a calculation from backend service
 */
export async function calculateLogisticsFootprint(values) {
  let request = null;

  // logistics by land
  if (values.transportationType === "air") {
    if (values.airportsKnown === AIRPORTS_KNOWN.NO) {
      request = logisticsDecisionTree.air.by_air(values, "Air");
    } else {
      request = logisticsDecisionTree.air.by_airports(values, "Air");
    }
  }

  // logistics by rail
  if (values.transportationType === "rail") {
    request = logisticsDecisionTree.rail(values, "Rail");
  }

  // logistics by sea
  if (values.transportationType === "sea") {
    const makeRequestFunc = logisticsDecisionTree.sea[values.cargoType] || logisticsDecisionTree.sea.default;
    request = makeRequestFunc(values, "Sea");
  }

  if (values.transportationType === "land") {
    if (values.landCalculationBy === "fuel") {
      const vehicleType =
        values.vehicleType === "heavy" || values.vehicleType === "van" || values.vehicleType === "average"
          ? "heavy"
          : "small";
      request = logisticsDecisionTree.land.fuel[vehicleType][values.fuelType](values, "Land");
    } else {
      request = logisticsDecisionTree.land[values.landCalculationBy][values.vehicleDetails](values, "Land");
    }
  }

  const response = await apiQuery({
    path: "/v2/emissions/logistics",
    type: "POST",
    data: request,
  }).run();

  return formatCalculationResultV2(values, response);
}
