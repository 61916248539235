import { differenceInDays } from "date-fns";

import { apiQuery } from "../../../api/api";
import { formatCalculationResultV2 } from "../../../api/format-calculation-result";
import { EmployeesForm } from "./employees.types";

export async function calculateEmployeesFootprint(form: EmployeesForm) {
  const apiRequest = {
    type: "POST",
    path: `/v2/emissions/personal`,
    data: {
      country: form.country!.value,
      people: form.numberOfEmployees!,
      days: differenceInDays(form.end!, form.start!),
    },
  };

  const response = await apiQuery(apiRequest).run();

  return formatCalculationResultV2(form, response);
}
