import { useEffect } from "react";

export const injectScript = (scriptContent: string) => {
  const script = document.createElement("script");

  script.innerHTML = scriptContent;

  document.body.appendChild(script);

  return script;
};

const useInjectScript = (scriptContent: string, enabled: boolean) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const script = injectScript(scriptContent);

    return () => {
      document.body.removeChild(script);
    };
  }, [scriptContent, enabled]);
};

export default useInjectScript;
