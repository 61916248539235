import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormGenerator, getStaticAsset } from "@carbon/shared";
import { Link } from "@material-ui/core";

import useCalculatorSummary from "../../../hooks/useCalculatorSummary";
import useSnackbarStore from "../../../store/snackbar";
import CalculatorPage from "../../../components/layout/CalculatorPage";
import SummaryTable from "../../../components/common/SummaryTable";
import { getCompensationParams } from "../../../utils/compensation";
import { redirectToCheckout } from "../../../api/payment-api";
import { getBulkSnackbarConfig, submitBulkCalculations } from "../../../utils/bulk-calculations";

import SUMMARY_TABLE_COLUMNS from "./employees-summary-table";
import Instructions from "./components/Instructions";
import FORM_CONFIG from "./employees-form-config";
import { EmployeesForm, EmployeesFormArray } from "./employees.types";
import { calculateEmployeesFootprint } from "./employees-api";

interface Props {
  headerHeight?: number;
}

const Employees = ({ headerHeight }: Props) => {
  const [asyncFormValues, setAsyncFormValues] = useState<EmployeesFormArray>();
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);
  const { calculations, addCalculationRow, removeCalculationRow, summary } = useCalculatorSummary(
    SUMMARY_TABLE_COLUMNS,
    "employees",
  );

  const handleSubmitTransaction = async () => {
    try {
      await redirectToCheckout(getCompensationParams(summary, "employees"));
    } catch (error: any) {
      createSnackbar(`Failed to submit calculations: ${error.message}`, "error");
    }
  };

  const handleAddCalculation = async (form: EmployeesFormArray) => {
    const results = await submitBulkCalculations(calculateEmployeesFootprint, form.employees);

    if (results.calculations.length) {
      addCalculationRow(results.calculations);
    }

    createSnackbar(
      ...getBulkSnackbarConfig<EmployeesForm>(
        results,
        <>
          Personal carbon footprint calculated, <Link href="#summary">view the summary</Link>
        </>,
      ),
    );

    if (results.errors.length) {
      const filterSuccessfulCalculations = (_: EmployeesForm, i: number) => !results.successIndices.includes(i);

      // Remove successful calculations from the form rows
      setAsyncFormValues({
        employees: form.employees.filter(filterSuccessfulCalculations),
      });

      throw results.errors[0];
    }
  };

  return (
    <CalculatorPage
      backgroundImage={getStaticAsset("/images/digital-bg.jpg")}
      headerHeight={headerHeight}
      title="Personal carbon footprint"
      subtitle="Offset your employees' personal carbon footprint based on where they live"
      instructions={<Instructions />}
      summary={summary}
      summaryTable={
        <SummaryTable
          columns={SUMMARY_TABLE_COLUMNS}
          summary={summary}
          calculations={calculations}
          onRemoveRow={removeCalculationRow}
          onSubmitCalculations={handleSubmitTransaction}
        />
      }
    >
      <FormGenerator<EmployeesForm>
        asyncFormValues={asyncFormValues}
        formName="employees"
        fieldArray
        duplicateFieldsOnAppend={["start", "end"]}
        onSubmit={handleAddCalculation}
        {...FORM_CONFIG}
      />
    </CalculatorPage>
  );
};

Employees.propTypes = {
  headerHeight: PropTypes.number,
};

export default Employees;
