import { useEffect, useState } from "react";

import { CalculationResult, CalculationsSummary, SummaryTableColumn } from "../types";
import useLocalStorage from "./useLocalStorage";

const INITIAL_SUMMARY_STATE: CalculationsSummary = {
  upstreamEmissions: 0,
  usageEmissions: 0,
  emissions: 0,
  price: 0,
  currencySymbol: "€",
  ids: [],
};

function summarizeCalculations<TForm>(calculations: CalculationResult<TForm>[]): CalculationsSummary {
  return calculations.reduce(
    (acc, calculation) => ({
      ...acc,
      upstreamEmissions: acc.upstreamEmissions + calculation.result.emissions.upstream,
      usageEmissions: acc.usageEmissions + calculation.result.emissions.usage,
      emissions: acc.emissions + calculation.result.emissions.total,
      price: acc.price + calculation.result.value.amount,
      ids: [...acc.ids, calculation.id],
      currencySymbol: calculation.result.value.currencySymbol, // Assumes the same currency for all calculations
    }),
    INITIAL_SUMMARY_STATE,
  );
}

interface Summary<TForm> {
  tableColumns: SummaryTableColumn<TForm>[];
  calculations: CalculationResult<TForm>[];
  /** Add one or multiple calculations to the summary */
  addCalculationRow: (calculation: CalculationResult<TForm> | CalculationResult<TForm>[]) => void;
  removeCalculationRow: (rowIndex: number) => void;
  clear: () => void;
  summary: CalculationsSummary;
}

function useCalculatorSummary<TForm>(
  tableColumns: SummaryTableColumn<TForm>[],
  calculationType: string,
): Summary<TForm> {
  const key = `${calculationType}-calculations`;
  const [calculations, setCalculations, clear] = useLocalStorage(key, []);
  const [summary, setSummary] = useState(INITIAL_SUMMARY_STATE);

  useEffect(() => {
    if (calculations.length) {
      setSummary(summarizeCalculations(calculations));
    } else {
      setSummary(INITIAL_SUMMARY_STATE);
    }
  }, [calculations, setSummary]);

  const addCalculationRow: Summary<TForm>["addCalculationRow"] = (row) => {
    if (row instanceof Array) {
      setCalculations([...calculations, ...row]);
    } else {
      setCalculations([...calculations, row]);
    }
  };

  const removeCalculationRow: Summary<TForm>["removeCalculationRow"] = (rowIndex) => {
    setCalculations(calculations.filter((_: any, index: number) => index !== rowIndex));
  };

  return { tableColumns, calculations, addCalculationRow, removeCalculationRow, clear, summary };
}

export default useCalculatorSummary;
