import React from "react";
import { palette } from "@carbon/shared";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  color: ${palette("text.light")};
  border: none;
  transition: background 0.1s;

  &.Mui-expanded {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.2);
  }

  .MuiAccordionSummary-expandIcon {
    color: ${palette("text.light")};
  }
`;

const OtherPackageAccordion = () => (
  <StyledAccordion>
    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" component="span">
        Not seeing your business listed above?
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="body2">
        With our wide selection of calculators, you have full control: only calculate those emissions that are relevant
        to your business operations. Or get started with one area, and add new calculations as you go!
      </Typography>
    </AccordionDetails>
  </StyledAccordion>
);

export default OtherPackageAccordion;
