import React from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import useToggle from "../../hooks/useToggle";
import { FieldProps } from "./types";

const PasswordField = ({ fieldProps, register, error, required, name, label }: FieldProps) => {
  const [isPasswordShown, togglePasswordShown] = useToggle(false);

  return (
    <TextField
      type={isPasswordShown ? "text" : "password"}
      inputRef={register}
      error={!!error}
      required={required}
      name={name}
      label={label}
      helperText={error?.message}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={togglePasswordShown}
              onMouseDown={(event) => event.preventDefault()}
            >
              {isPasswordShown ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...fieldProps}
    />
  );
};

export default PasswordField;
