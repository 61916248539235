import React from "react";
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";

import FIELD_PROP_TYPES from "./field-prop-types";

const Checkbox = ({ fieldProps, register, error, name, label, required }) => (
  <FormControl required={required} error={!!error}>
    <FormControlLabel control={<MuiCheckbox {...fieldProps} />} name={name} label={label} inputRef={register} />
    {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
  </FormControl>
);

Checkbox.propTypes = FIELD_PROP_TYPES;

export default Checkbox;
