/**
 * Emission equivalents in grams, where the value equals one of the property key,
 * e.g. `streamingloveactually: 150` indicates the equivalent on emissions that generate streaming 150 times Love Actually
 * (which is N-1 times you should watch the movie)
 */
export const EMISSION_EQUIVALENTS = {
  kmDriven: 220,
  flightsAroundWorld: 8800000,
  streamingLoveActually: 150,
  squareMetersRainforestProtected: 55000,
};
