import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { RadioButtons, CountryElectricityFactorsDropdown } from "@components/form";

import {
  COPY_TEXT_FOR_THIS_SPACE,
  COPY_TEXT_USE_ESTIMATE,
  COPY_TEXT_WHOLE_BUILDING,
  FOR_REPORTED_SPACE,
  FOR_WHOLE_BUILDING,
  USE_GENERAL_ESTIMATE,
} from "./constants";

import {
  AMOUNT_USED_FIELD,
  BUILDING_SIZE_FIELD,
  BUILDING_TYPE_FIELD,
  COMMON_SCHEMA_CONFIG,
  EMISSION_FACTOR_FIELD,
  SPACE_SIZE_FIELD,
} from "./common-form-config";

const schema = yup.object().shape({
  ...COMMON_SCHEMA_CONFIG,
  used_type: undefined, // Override and ignore used_type
  country: yup.string().required("Please select a country"),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },

  defaultValues: {
    country: "",
    is_renewable: "",
    is_known_factor: "",
    amount_known: "",
  },

  fields: [
    {
      label: "Where is the electricity consumed?",
      name: "country",
      render: CountryElectricityFactorsDropdown,
      fieldProps: {
        helperText: "You can also use select the European or Global average value",
        "data-testid": "electricity-country",
      },
      required: true,
    },

    {
      label: "Do you know the amount of electricity used?",
      name: "amount_known",
      render: RadioButtons,
      required: true,
      options: [
        {
          value: FOR_REPORTED_SPACE,
          label: COPY_TEXT_FOR_THIS_SPACE,
        },
        {
          value: FOR_WHOLE_BUILDING,
          label: COPY_TEXT_WHOLE_BUILDING,
          "data-testid": "electricity-known-for-building",
        },
        {
          value: USE_GENERAL_ESTIMATE,
          label: COPY_TEXT_USE_ESTIMATE,
        },
      ],
    },

    {
      ...SPACE_SIZE_FIELD,
      fieldProps: {
        "data-testid": "electricity-space-size-input",
      },
    },

    BUILDING_SIZE_FIELD,

    AMOUNT_USED_FIELD,

    BUILDING_TYPE_FIELD,

    {
      render: RadioButtons,
      name: "is_renewable",
      label: "Is the electricity 100% renewable?",
      helperText: "If you're not sure, skip this question",
      required: false,
      options: [
        {
          value: "yes",
          label: "Yes",
          "data-testid": "electricity-type-renewable",
        },
        {
          value: "no",
          label: "No",
        },
      ],
    },

    {
      render: RadioButtons,
      name: "is_known_factor",
      label: "Do you know the exact emission factor?",
      helperText: "If not, we'll use the average for your country or region",
      required: false,
      options: [
        {
          value: "yes",
          label: "Yes",
          "data-testid": "electricity-known-factor",
        },
        {
          value: "no",
          label: "No",
        },
      ],
    },

    {
      ...EMISSION_FACTOR_FIELD,
      dependsOn: {
        name: "is_known_factor",
        condition: (isKnownFactor) => isKnownFactor === "yes",
      },
    },
  ],
};

export default FORM_CONFIG;
