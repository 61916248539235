import React from "react";
import PropTypes from "prop-types";
import { Radio, FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup } from "@material-ui/core";
import { Controller } from "react-hook-form";

import { palette, spacing } from "@carbon/shared";
import FIELD_PROP_TYPES from "../constants/field-prop-types";
import styled from "styled-components";

const StyledFormLabel = styled(FormLabel)`
  color: ${palette("text.primary")};
  display: block;
  font-weight: 700;
  margin-bottom: ${({ $paragraph }) => ($paragraph ? spacing(2) : 0)}px;
`;

// Remove alignStart before rendering FormControlLabel to ensure it's not passed to the DOM element
const StyledRadioLabel = styled(({ alignStart, ...props }) => <FormControlLabel {...props} />)`
  ${({ alignStart }) =>
    alignStart &&
    `
    align-items: flex-start;

    // Cancel out the radio button's top padding, use top margin rather than padding to keep the highlight animation circular
    > .MuiIconButton-root {
      margin-top: -9px;
    }
  `}
`;

const RadioButtons = ({ options, fieldProps, error, name, label, required, helperText }) => (
  <FormControl component="fieldset" required={required} error={!!error}>
    <StyledFormLabel component="legend" $paragraph={!helperText}>
      {label}
    </StyledFormLabel>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    <Controller
      name={name}
      as={
        <RadioGroup aria-label={label} name={name}>
          {options.map(({ value, label, helperText, ...rest }) => (
            <div key={value}>
              <StyledRadioLabel value={value} label={label} control={<Radio {...fieldProps} />} {...rest} />
              {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
            </div>
          ))}
        </RadioGroup>
      }
    />
    {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
  </FormControl>
);

RadioButtons.propTypes = {
  ...FIELD_PROP_TYPES,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    }),
  ),
};

export default RadioButtons;
