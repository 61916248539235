import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { pleaseSelect } from "@components/form/utils/form-messages";
import { Dropdown } from "@components/form";
import { requiredNumber } from "../../../components/form/utils/schema";

import { TRANSPORTATION_OPTIONS, CALCULATE_BY, AIRPORTS_KNOWN } from "./constants";
import { AIR_FORM_DEFAULTS, AIR_FORM_FIELDS, AIR_FORM_SCHEMA, isAirTransportation } from "./air-form-config";
import { isLandTransportation, LAND_FORM_DEFAULTS, LAND_FORM_FIELDS, LAND_FORM_SCHEMA } from "./land-form-config";
import { isRailTransportation, RAIL_FORM_FIELDS } from "./rail-form-config";
import { isSeaTransportation, SEA_FORM_DEFAULTS, SEA_FORM_FIELDS, SEA_FORM_SCHEMA } from "./sea-form-config";

const COMMON_SCHEMA = {
  transportationType: yup.string().required(pleaseSelect("a transportation type")),

  distance: yup.number().when(["transportationType", "landCalculationBy", "airportsKnown"], {
    is: (type, calculateBy, airportsKnown) =>
      isRailTransportation(type) ||
      isSeaTransportation(type) ||
      (isLandTransportation(type) && (calculateBy === CALCULATE_BY.WEIGHT || calculateBy === CALCULATE_BY.DISTANCE)) ||
      (isAirTransportation(type) && airportsKnown === AIRPORTS_KNOWN.NO),
    then: requiredNumber("Distance"),
  }),

  weight: yup.number().when(["transportationType", "landCalculationBy"], {
    is: (type, calculateBy) =>
      isRailTransportation(type) ||
      isSeaTransportation(type) ||
      isAirTransportation(type) ||
      (isLandTransportation(type) && calculateBy === CALCULATE_BY.WEIGHT),
    then: requiredNumber("Weight"),
  }),
};

const schema = yup.object().shape({
  ...COMMON_SCHEMA,
  ...AIR_FORM_SCHEMA,
  ...LAND_FORM_SCHEMA,
  ...SEA_FORM_SCHEMA,
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  defaultValues: {
    transportationType: "",
    distance: "",
    weight: "",

    ...AIR_FORM_DEFAULTS,
    ...LAND_FORM_DEFAULTS,
    ...SEA_FORM_DEFAULTS,
  },

  fields: [
    {
      render: Dropdown,
      name: "transportationType",
      label: "Transportation by",
      options: TRANSPORTATION_OPTIONS,
      required: true,
      fieldProps: {
        helperText: "First, choose the way your goods/cargo are transported",
      },
    },

    ...AIR_FORM_FIELDS,
    ...LAND_FORM_FIELDS,
    ...RAIL_FORM_FIELDS,
    ...SEA_FORM_FIELDS,
  ],
};

export default FORM_CONFIG;
