import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Link, Typography } from "@material-ui/core";

import { spacing } from "@carbon/shared";
import { SUSTAINABILITY_LINK } from "@constants/links";
import { QUERY_FLOW, QUERY_KEY } from "@constants/query-string";
import useCalculatorSummary from "../../hooks/useCalculatorSummary";

const StyledAlert = styled(Alert)`
  padding: ${spacing(4)}px;
  margin-bottom: ${spacing(10)}px;
`;

const TYPE_TO_NAME = {
  digital: "Digital",
  flights: "Flights",
  "real-estate": "Energy & Fuel Use",
  logistics: "Logistics",
  personal: "Personal Carbon Footprint",
  index: "Purchased Products and Services",
};

const CompensationConfirmation = ({ urlParams, endedFreeTrial = false }) => {
  const type = urlParams.get(QUERY_KEY.TYPE);

  const { clear: clearLocalCalculations } = useCalculatorSummary([], type);

  const compensationMessage = endedFreeTrial
    ? `Your subscription is now active and you have successfully compensated your ${TYPE_TO_NAME[type]} emissions.`
    : `You have successfully compensated your ${TYPE_TO_NAME[type]} emissions.`;

  useEffect(() => {
    clearLocalCalculations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledAlert severity="success">
      <AlertTitle variant="h5" gutterBottom={false} paragraph>
        Thank you for compensating!
      </AlertTitle>
      <Typography paragraph>{compensationMessage}</Typography>
      <Typography>
        Compensate uses 100% of your payment to buy carbon credits from certified, high quality carbon capture projects.{" "}
        <Link target="_blank" rel="noopener" href={SUSTAINABILITY_LINK}>
          Learn more.
        </Link>
      </Typography>
    </StyledAlert>
  );
};

CompensationConfirmation.propTypes = {
  endedFreeTrial: PropTypes.bool,
  urlParams: PropTypes.shape().isRequired,
};

const LocationContextMessage = ({ fallback = null }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const flow = urlParams.get(QUERY_KEY.FLOW);

  if (flow === QUERY_FLOW.COMPENSATE) {
    return <CompensationConfirmation urlParams={urlParams} />;
  }

  if (flow === QUERY_FLOW.END_TRIAL_AND_COMPENSATE) {
    return <CompensationConfirmation endedFreeTrial urlParams={urlParams} />;
  }

  if (flow === QUERY_FLOW.REGISTER) {
    return (
      <StyledAlert severity="success">
        <AlertTitle variant="h5" gutterBottom={false} paragraph>
          Thank you for joining us!
        </AlertTitle>
        <Typography paragraph>
          Your free trial is active, take some time to explore. You won’t be charged until your trial ends or you make
          your first compensation payment.
        </Typography>
        <Typography>
          This subscription enables us to provide the service to you. You take action, we make sure that your efforts
          make a sustainable difference for the climate, biodiversity, social issues, and human rights. 100% of your
          upcoming compensation payments are used for carbon capture.
        </Typography>
      </StyledAlert>
    );
  }

  if (location.state) {
    return (
      <StyledAlert severity="success">
        <AlertTitle variant="h5" gutterBottom={false}>
          {location.state}
        </AlertTitle>
      </StyledAlert>
    );
  }

  return fallback;
};

LocationContextMessage.propTypes = {
  fallback: PropTypes.node,
};

export default LocationContextMessage;
