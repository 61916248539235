import { chunk, orderBy } from "lodash";

import Emissions from "./formatting/emissions";
import palette from "../theme/palette";

export interface EmissionSource {
  category: string;
  emissions: Emissions;
}

export interface EmissionCategory {
  id: string;
  name: string;
  description: string;
  ghg_scope: number;
}

export interface EmissionCategoryDatum {
  name: string;
  longName: string;
  color: string;
  emissions: number;
  scope: number;
}

export interface EmissionSourceDatum {
  id: string;
  name: string;
  emissions: number;
  description: string;
  color: string;
}

const MAX_CATEGORY = 5;
const DEFAULT_COLOR = "#DAE4E5";
export const SCOPE_COLOR_MAP = {
  1: palette.dataViz.default.carbon,
  2: palette.dataViz.default.primaryTone700,
  3: palette.dataViz.default.goldTone300,
};

const initialScopeData: EmissionCategoryDatum[] = [
  {
    scope: 1,
    name: "Scope 1",
    longName: "Scope 1: Direct emissions",
    emissions: 0,
    color: SCOPE_COLOR_MAP[1],
  },
  {
    scope: 2,
    name: "Scope 2",
    longName: "Scope 2: Indirect emissions from energy",
    emissions: 0,
    color: SCOPE_COLOR_MAP[2],
  },
  {
    scope: 3,
    name: "Scope 3",
    longName: "Scope 3: Other indirect emissions",
    emissions: 0,
    color: SCOPE_COLOR_MAP[3],
  },
];

export function getEmissionsBySourcePieData(
  emissionSources: EmissionSource[] = [],
  emissionCategories: EmissionCategory[] = [],
): EmissionSourceDatum[] {
  const emissionsBySourcePieData = emissionCategories.reduce((acc, category) => {
    const emissionsByCategory = emissionSources.reduce(
      (acc, source) => (source.category === category.id ? acc + source.emissions.amount : acc),
      0,
    );

    return [
      ...acc,
      {
        ...category,
        emissions: emissionsByCategory,
        color: DEFAULT_COLOR,
      },
    ];
  }, [] as EmissionSourceDatum[]);

  return orderBy(emissionsBySourcePieData, "emissions", "desc").map((x, index) => ({
    ...x,
    color: index < MAX_CATEGORY ? Object.values(palette.dataViz.default)[index] : DEFAULT_COLOR,
  }));
}

export function groupOthers(emissionsBySourcePieData: EmissionSourceDatum[]): EmissionSourceDatum[] {
  const [biggestFive, rest] = chunk(emissionsBySourcePieData, MAX_CATEGORY);
  const others = rest?.reduce(
    (acc, data) => {
      acc.emissions += data.emissions;
      return acc;
    },
    {
      id: "others",
      name: "Others",
      description: "",
      emissions: 0,
      color: "#DAE4E5",
    } as EmissionSourceDatum,
  );

  return [...(biggestFive ? biggestFive : []), ...(others ? [others] : [])];
}

export function getEmissionsByGHGScopePieData(
  emissionSources: EmissionSource[] = [],
  emissionCategories: EmissionCategory[] = [],
): EmissionCategoryDatum[] {
  return emissionCategories?.reduce((acc, category) => {
    const emissionsByCategory = emissionSources?.reduce(
      (acc, source) => (source.category === category.id ? acc + source.emissions.amount : acc),
      0,
    );
    return acc.map((scope, i) =>
      i === category.ghg_scope - 1 ? { ...scope, emissions: scope.emissions + emissionsByCategory } : scope,
    );
  }, initialScopeData);
}

export const sampleEmissionSources: EmissionSource[] = [
  { category: "1", emissions: new Emissions(6000) },
  { category: "1", emissions: new Emissions(6000) },
  { category: "2", emissions: new Emissions(5000) },
  { category: "2", emissions: new Emissions(5000) },
  { category: "3", emissions: new Emissions(4000) },
  { category: "3", emissions: new Emissions(4000) },
  { category: "4", emissions: new Emissions(3000) },
  { category: "4", emissions: new Emissions(3000) },
  { category: "5", emissions: new Emissions(2000) },
  { category: "5", emissions: new Emissions(2000) },
  { category: "6", emissions: new Emissions(1000) },
  { category: "6", emissions: new Emissions(1000) },
  { category: "7", emissions: new Emissions(1000) },
  { category: "7", emissions: new Emissions(1000) },
];

export const sampleEmissionCategories: EmissionCategory[] = [
  { id: "1", name: "Category 1", description: "Description example", ghg_scope: 1 },
  { id: "2", name: "Category 2", description: "", ghg_scope: 1 },
  { id: "3", name: "Category 3", description: "", ghg_scope: 2 },
  { id: "4", name: "Category 4", description: "", ghg_scope: 2 },
  { id: "5", name: "Category 5", description: "", ghg_scope: 3 },
  { id: "6", name: "Category 6", description: "", ghg_scope: 3 },
  { id: "7", name: "Category 7", description: "", ghg_scope: 3 },
];
