import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CountryRegionData } from "react-country-region-selector";
import { useFormContext } from "react-hook-form";

import FIELD_PROP_TYPES from "./field-prop-types";
import AutocompleteDropdown from "./AutocompleteDropdown";

const getRegionOptions = (selectedCountryCode) => {
  if (!selectedCountryCode) {
    return [];
  }

  const countryData = CountryRegionData.find(([, code]) => code === selectedCountryCode);

  if (!countryData?.length) {
    return [];
  }

  // CountryRegionData[n][2] Contains a list of regions and region codes separated by pipe e.g. Nottingham~12|Coventry~24|London~44
  return countryData[2].split("|").map((region) => {
    const [regionLabel] = region.split("~");

    return { label: regionLabel, value: regionLabel };
  });
};

const shouldResetRegion = (selectedRegion, nextOptions) =>
  selectedRegion && !nextOptions.find((option) => option.value === selectedRegion?.value);

/**
 * Renders different region options based on the selected country
 */
const RegionDropdown = ({ countryFieldName, name, ...rest }) => {
  const [options, setOptions] = useState([]);
  const { watch, setValue, getValues } = useFormContext();

  const selectedCountry = watch(countryFieldName);

  useEffect(() => {
    // Reset region and options when selectedCountry changes
    const selectedRegion = getValues(name);
    const nextOptions = getRegionOptions(selectedCountry?.value);

    setOptions(nextOptions);

    if (shouldResetRegion(selectedRegion, nextOptions)) {
      setValue(name, null);
    }
  }, [name, setValue, getValues, selectedCountry]);

  return <AutocompleteDropdown name={name} options={options} autocompleteProps={{ autoSelect: false }} {...rest} />;
};

RegionDropdown.propTypes = { ...FIELD_PROP_TYPES, countryFieldName: PropTypes.string };

export default RegionDropdown;
