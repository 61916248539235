import React from "react";
import * as yup from "yup";
import { InputAdornment } from "@material-ui/core";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import FlightLand from "@material-ui/icons/FlightLand";

import { is } from "@utils/comparison";
import { pleaseSelect } from "@components/form/utils/form-messages";
import { NumberField, RadioButtons, AirportAutocomplete, NumberFieldWithAdornment } from "@components/form";
import { TRANSPORTATION_TYPE, AIRPORTS_KNOWN_OPTIONS, AIRPORTS_KNOWN } from "./constants";
import { requiredNumber } from "../../../components/form/utils/schema";
import ToggleHaulDistance from "./components/ToggleHaulDistance";

export const isAirTransportation = is(TRANSPORTATION_TYPE.AIR);

export const AIRPORT_TYPE = yup.object().when(["transportationType", "airportsKnown"], {
  is: (transportationType, airportsKnown) =>
    isAirTransportation(transportationType) && airportsKnown === AIRPORTS_KNOWN.YES,
  then: yup
    .object()
    .shape({
      iata_code: yup.string(),
      name: yup.string(),
    })
    .typeError(pleaseSelect("an airport"))
    .required(pleaseSelect("an airport")),
});

export const AIR_FORM_SCHEMA = {
  airportsKnown: yup.string().when("transportationType", { is: isAirTransportation, then: yup.string().required() }),
  airportFrom: AIRPORT_TYPE,
  airportTo: AIRPORT_TYPE,
  quantity: yup.number().when(["transportationType", "airportsKnown"], {
    is: (transportationType, airportsKnown) =>
      isAirTransportation(transportationType) && airportsKnown === AIRPORTS_KNOWN.NO,
    then: requiredNumber("Quantity"),
  }),
};

export const AIR_FORM_DEFAULTS = {
  airportsKnown: AIRPORTS_KNOWN.YES,
  airportTo: null,
  airportFrom: null,
  quantity: 1,
};

export const AIR_FORM_FIELDS = [
  {
    render: RadioButtons,
    name: "airportsKnown",
    label: "Do you know the departure and destination airports?",
    required: true,
    options: AIRPORTS_KNOWN_OPTIONS,
    dependsOn: {
      name: "transportationType",
      condition: isAirTransportation,
    },
  },

  {
    render: AirportAutocomplete,
    name: "airportFrom",
    label: "Where from?",
    required: true,
    wrapperProps: {
      xs: 12,
      sm: 6,
    },
    fieldProps: {
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <FlightTakeoff />
          </InputAdornment>
        ),
      },
    },
    dependsOn: {
      fields: {
        airportsKnown: is(AIRPORTS_KNOWN.YES),
        transportationType: isAirTransportation,
      },
    },
  },

  {
    render: AirportAutocomplete,
    name: "airportTo",
    label: "Where to?",
    required: true,
    wrapperProps: {
      xs: 12,
      sm: 6,
    },
    fieldProps: {
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <FlightLand />
          </InputAdornment>
        ),
      },
    },
    dependsOn: {
      fields: {
        airportsKnown: is(AIRPORTS_KNOWN.YES),
        transportationType: isAirTransportation,
      },
    },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="km" {...props} />;
    },
    name: "distance",
    label: "Transported distance",
    required: true,
    fieldProps: {
      helperText: <ToggleHaulDistance distanceFieldName="distance" />,
    },
    dependsOn: {
      fields: {
        airportsKnown: is(AIRPORTS_KNOWN.NO),
        transportationType: isAirTransportation,
      },
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="kg" digits={2} {...props} />;
    },
    name: "weight",
    label: "Weight of the goods/cargo",
    required: true,
    fieldProps: {
      helperText: "We automatically add 10% to include secondary packaging",
    },
    dependsOn: {
      name: "transportationType",
      condition: isAirTransportation,
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },

  {
    render: NumberField,
    name: "quantity",
    label: "Quantity",
    required: true,
    fieldProps: {
      helperText: "If you frequently ship goods/cargo along this route, you can add multiple journeys",
    },
    dependsOn: {
      fields: {
        airportsKnown: is(AIRPORTS_KNOWN.NO),
        transportationType: isAirTransportation,
      },
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },
];
