import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Link } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { FormGenerator, FieldGenerator, Accordion, spacing } from "@carbon/shared";

import {
  BASIC_INFORMATION_ERROR_MESSAGE,
  SEPARATE_POWER_PLANT,
  WITH_KNOWN_EMISSION_FACTOR,
  SUMMARY_TABLE_COLUMNS,
} from "./constants";
import Instructions from "./components/Instructions";
import CalculatorPage from "@components/layout/CalculatorPage";
import SummaryTable from "@components/common/SummaryTable";
import { getCompensationParams } from "@utils/compensation";

import realEstateBackground from "@assets/img/real-estate-bg.png";
import { calculationParams } from "./business-logic";
import { calculateEmissions } from "@api/realestate-api";
import FUEL_FORM_CONFIG from "./real-estate-fuel-form-config";
import { FUEL_TYPE_OTHER } from "@constants/fuel-types";
import { getUnitFromFuelType } from "./components/AmountOfFuelUsed";
import useFuelTypesStore from "@store/fuel-type-factors";
import useSnackbarStore from "../../../store/snackbar";
import useCalculatorSummary from "../../../hooks/useCalculatorSummary";
import BASIC_INFO_FORM_CONFIG from "./basic-info-form-config";
import ELECTRICITY_FORM_CONFIG from "./real-estate-electricity-form-config";
import HEATING_FORM_CONFIG from "./real-estate-heating-form-config";
import COOLING_FORM_CONFIG from "./real-estate-cooling-form-config";
import { redirectToCheckout } from "../../../api/payment-api";

const AccordionFormGenerator = styled(FormGenerator)`
  margin-top: ${spacing(1)}px;
  margin-bottom: ${spacing(2)}px;
`;

const BasicInformationFormGrid = styled(Grid)`
  margin-bottom: ${spacing(6)}px;
`;

const RealEstate = ({ headerHeight }) => {
  const formMethods = useForm({
    resolver: BASIC_INFO_FORM_CONFIG.resolver,
    defaultValues: BASIC_INFO_FORM_CONFIG.defaultValues,
  });

  const { handleSubmit: handleSubmitBasicInformation, getValues: getBasicInfoValues } = formMethods;

  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);
  const fuelTypes = useFuelTypesStore((state) => state.fuelTypes);

  const { calculations, addCalculationRow, removeCalculationRow, summary } = useCalculatorSummary(
    SUMMARY_TABLE_COLUMNS,
    "real-estate",
  );

  const addCalculation = async (values, type, name) => {
    const basicInfo = getBasicInfoValues();
    const [params, row] = calculationParams(basicInfo, type, values);
    const { id, result } = await calculateEmissions(params);

    addCalculationRow({
      id,
      nameOfSpace: row.name,
      energyType: name,
      type: row.type,
      usage: row.usage,
      unit: row.unit,
      result,
    });

    createSnackbar(
      <>
        Emissions calculated, <Link href="#summary">view your summary</Link>
      </>,
    );
  };

  const handleBasicInformationError = () => {
    createSnackbar(BASIC_INFORMATION_ERROR_MESSAGE, "error");

    // Caught by the child form to prevent fields from resetting
    throw new Error(BASIC_INFORMATION_ERROR_MESSAGE);
  };

  const handleSubmitClick = async () => {
    const basicInfo = getBasicInfoValues();

    let params = getCompensationParams(summary, "real-estate", "/real-estate");

    params = {
      ...params,
      metadata: {
        ...params.metadata,
        name: basicInfo.space_name,
      },
    };
    try {
      await redirectToCheckout(params);
    } catch (error) {
      createSnackbar(`Failed to submit calculations: ${error.message}`, "error");
    }
  };

  const createSubmitHandler = (type, name) => (form) =>
    handleSubmitBasicInformation(() => addCalculation(form, type, name), handleBasicInformationError)();

  const handleSubmitHeatingForm = (values) =>
    createSubmitHandler(
      "district_heating",
      "District heating",
    )({
      ...values,
      unit: "kWh",
      used_type: values.used_type === SEPARATE_POWER_PLANT ? WITH_KNOWN_EMISSION_FACTOR : values.used_type,
    });

  const handleSubmitFuelForm = (values) =>
    createSubmitHandler(
      "fuel",
      "Fuel",
    )({
      ...values,
      unit: getUnitFromFuelType(values.used_type, fuelTypes),
      used_type: values.used_type === FUEL_TYPE_OTHER ? WITH_KNOWN_EMISSION_FACTOR : values.used_type,
    });

  const formAccordions = [
    {
      title: "+ Electricity",
      key: "electricity",
      content: (
        <AccordionFormGenerator
          onSubmit={createSubmitHandler("electricity", "Electricity")}
          {...ELECTRICITY_FORM_CONFIG}
        />
      ),
    },
    {
      title: "+ District heating",
      key: "district-heating",
      content: <AccordionFormGenerator onSubmit={handleSubmitHeatingForm} {...HEATING_FORM_CONFIG} />,
    },
    {
      title: "+ District cooling",
      key: "district-cooling",
      content: (
        <AccordionFormGenerator
          onSubmit={createSubmitHandler("district_cooling", "District cooling")}
          {...COOLING_FORM_CONFIG}
        />
      ),
    },
    {
      title: "+ Fuel",
      key: "fuel",
      content: <AccordionFormGenerator onSubmit={handleSubmitFuelForm} {...FUEL_FORM_CONFIG} />,
    },
  ];

  return (
    <CalculatorPage
      headerHeight={headerHeight}
      backgroundImage={realEstateBackground}
      title="Energy & Fuel Use"
      subtitle="* = required fields"
      instructions={<Instructions />}
      summary={summary}
      summaryTable={
        <SummaryTable
          columns={SUMMARY_TABLE_COLUMNS}
          summary={summary}
          calculations={calculations}
          onRemoveRow={removeCalculationRow}
          onSubmitCalculations={handleSubmitClick}
        />
      }
    >
      <Typography variant="h4" paragraph>
        Basic information
      </Typography>

      <FormProvider {...formMethods}>
        <BasicInformationFormGrid component="form" container noValidate spacing={3}>
          {BASIC_INFO_FORM_CONFIG.fields.map((field) => (
            <FieldGenerator key={field.name} {...field} />
          ))}
        </BasicInformationFormGrid>
      </FormProvider>

      <Accordion items={formAccordions} />
    </CalculatorPage>
  );
};

RealEstate.propTypes = {
  headerHeight: PropTypes.number,
  locale: PropTypes.string,
};

export default RealEstate;
