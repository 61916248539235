import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Link } from "@material-ui/core";

import { spacing, typography, Section } from "@carbon/shared";

const StyledTextSection = styled(Section)`
  text-align: center;

  > * {
    max-width: ${spacing(160)}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Unbold = styled.span`
  font-weight: ${typography("fontWeightRegular")};
`;

const TextSection = (props) => <StyledTextSection {...props} />;

export const LearnMoreSection = (props) => (
  <TextSection bgcolor="gray.light" {...props}>
    <Typography variant="h3">
      <Unbold>Learn more: </Unbold>
      <Link target="_blank" rel="noopener" href="https://www.compensate.com">
        compensate.com
      </Link>
    </Typography>
  </TextSection>
);

export const AssistanceSection = (props) => (
  <TextSection bgcolor="primary.extraLight" {...props}>
    <Typography variant="h3" gutterBottom>
      Have a question or need assistance?
    </Typography>

    <Typography variant="subtitle1">
      Contact us:{" "}
      <Link variant="h4" href="mailto:support@compensate.com">
        support@compensate.com
      </Link>
    </Typography>
  </TextSection>
);

TextSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextSection;
