import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { spacing, CompensateEmailLink } from "@carbon/shared";
import { ReactComponent as Arrow } from "@assets/icons/arrow-right.svg";
import PlanCard from "../common/PlanCard";
import useLocalStorage from "@hooks/useLocalStorage";
import { LICENSES, LICENSE_LOCAL_STORAGE_KEY } from "@constants/license";
import useAuthStore, { isAnonymous, statusSelector } from "../../store/auth";

const StyledGrid = styled(Grid)`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const GridItem = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    // Full bleed on mobile
    margin-left: -${spacing(8)}px;
    margin-right: -${spacing(8)}px;
    max-width: 100vw;
    flex-basis: 100vw;

    // Increase specificity to override Mui styles
    &.MuiGrid-item {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const EnterpriseCard = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Licenses = ({ children }) => {
  const anonymousUser = isAnonymous(useAuthStore(statusSelector));
  const history = useHistory();
  const [, setSelectedLicense] = useLocalStorage(LICENSE_LOCAL_STORAGE_KEY, null);

  const handleChooseLicense = (id) => {
    const pathname = anonymousUser ? "/login" : "/billinginfo";

    setSelectedLicense(id);

    history.push({ pathname, state: { selectedLicense: id } });
  };

  return (
    <StyledGrid container spacing={1} alignItems="stretch" justifyContent="center">
      {LICENSES.map(({ id, label, employees, pricePerYear }) => (
        <GridItem key={id} item xs={12} sm={6} lg={4} data-testid={`plan-${id}`}>
          <PlanCard
            onClick={() => handleChooseLicense(id)}
            pricePerYear={pricePerYear}
            employeeCount={employees}
            title={label}
          />
        </GridItem>
      ))}

      <GridItem item xs={12} lg={8}>
        <EnterpriseCard px={[6, 4]} py={4} bgcolor="gold" color="text.light">
          <Typography variant="h5" component="p" paragraph>
            Need a bigger solution?
          </Typography>

          <Typography variant="h4" paragraph>
            Enterprise
          </Typography>

          <Typography variant="h5" component="p" paragraph>
            <CompensateEmailLink color="inherit" username="info">
              <Arrow /> Contact our team!
            </CompensateEmailLink>
          </Typography>
        </EnterpriseCard>
      </GridItem>

      {children}
    </StyledGrid>
  );
};

Licenses.propTypes = {
  children: PropTypes.node,
};

export default Licenses;
