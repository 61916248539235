import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import arrow from "@assets/icons/arrow-right.svg";
import { spacing } from "@carbon/shared";
import { Variant } from "@material-ui/core/styles/createTypography";

const StyledList = styled.ul<{ $bulletSpace: number }>`
  list-style: none;
  margin: 0;
  padding-left: ${({ $bulletSpace }) => spacing($bulletSpace)}px;
`;

const StyledItem = styled((props) => <Typography component="li" {...props} />)`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: ${({ $arrowWidth }) => spacing(Math.round($arrowWidth / 4))}px;
    left: -${({ $bulletSpace }) => spacing($bulletSpace)}px;
    height: ${({ $arrowWidth }) => spacing(Math.round($arrowWidth / 2))}px;
    width: ${({ $arrowWidth }) => spacing($arrowWidth)}px;
    background-image: url(${arrow});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const ArrowList = ({ items, variant = "body1", miniArrow = false }: ArrowListProps) => {
  // Width of the arrow icon
  const arrowWidth = miniArrow ? 3 : 4;
  // Space given to the bullet column (must be larger than arrowWidth)
  const bulletSpace = miniArrow ? 4 : 6;

  return (
    <StyledList $bulletSpace={bulletSpace}>
      {items.map((item, i) => (
        <StyledItem key={i} variant={variant} paragraph $arrowWidth={arrowWidth} $bulletSpace={bulletSpace}>
          {item}
        </StyledItem>
      ))}
    </StyledList>
  );
};

interface ArrowListProps {
  items: React.ReactNode[];
  miniArrow?: boolean;
  variant?: Variant;
}

export default ArrowList;
