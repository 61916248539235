import { apiQuery } from "./api";
import { formatCalculationResultV2 } from "./format-calculation-result";

/**
 * Calculate emissions.
 *
 * @params type Type of emission calculation
 * @params parameters Calculation parameters, depends on type of calculation
 * @params token Firebase authentication token
 *
 * @return api request
 */
export const calculateEmissions = (parameters, token) => {
  const request = {
    type: "POST",
    path: "v2/emissions/energy",
    data: parameters,
  };
  return apiQuery(request, token)
    .run()
    .then((response) => formatCalculationResultV2({}, response));
};

export const getFuelTypeFactors = (token) => {
  const apiRequest = {
    type: "GET",
    path: "/v2/products/energy/fuel",
    data: [],
  };
  return apiQuery(apiRequest, token).run();
};

const filterCountryElectricityFactors = (factor) => {
  const factorParts = factor.category.split("/");

  // Valid country energy factors consist of 3 parts, with the third being an alpha-2 country code or "Europe" or "World"
  return factorParts.length === 3 && factorParts[2].match(/^([A-Z]{2}|Europe|World)$/);
};

export const getCountryElectricityFactors = async (token) => {
  const apiRequest = {
    type: "GET",
    path: "/v2/products/energy/electricity",
    data: [],
  };

  const factors = await apiQuery(apiRequest, token).run();

  return factors.filter(filterCountryElectricityFactors);
};
