import React from "react";
import { Link as MuiLink } from "@material-ui/core";
import { Link } from "react-router-dom";
import instructionsImg from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { CO2, CO2E } from "@components/common/Units";
import BillingInstruction from "@components/common/BillingInstruction";

const DigitalInstructions = () => (
  <Instructions
    title="How this works"
    imageOne={instructionsImg}
    listItems={[
      <>
        Use Compensate’s Digital calculator to figure out your estimated digital carbon footprint and how much
        offsetting it costs!
      </>,
      <>
        The carbon footprint is calculated based on the average daily use of a laptop and a mobile device per user. This
        average is then multiplied by the number of employees or users you wish to compensate for, and the number of
        days in your desired reporting period.
      </>,
      <>
        The carbon footprint estimation includes 8 hours of laptop use (WiFi connection, SD streaming) and 4 hours of
        mobile device use (4G connection, SD streaming). The calculation takes into account data transmission, data
        centers, and the electricity the device itself uses. All the estimated greenhouse gas emissions are accounted
        for, and expressed in <CO2E />.
      </>,
      <>
        Because calculating exact digital footprints would require multiple variables, this calculation is based on
        conservative estimates. Compensate is frequently reviewing and, if necessary, updating the emission factors and
        how we calculate digital emissions.
      </>,
      <>
        You can add as many groups of employees or users as you’d like. Simply input one group by clicking “Add”, and
        then add new values to the calculator.
      </>,
      <>
        Have specific data? You might have servers, or know exactly how much energy some of your digital services need.
        You can calculate these emissions with the Energy and Fuel Use calculator{" "}
        <MuiLink to="/real-estate" color="inherit" component={Link}>
          here
        </MuiLink>
        .
      </>,
      <>
        “The Compensation impact” refers to the number of trees required to sequester the amount of <CO2 />
        emissions in a year. This gives you an idea of how many trees are needed to compensate for the emissions created
        by your digital carbon footprint.
      </>,
      <>
        Compensate&apos;s unique overcompensation is already built into the calculator. It ensures actual climate impact
        and tackles the risks involved in all carbon capture projects.
      </>,
      <BillingInstruction key="billing-instruction" />,
    ]}
  />
);

export default DigitalInstructions;
