/**
 * Returns num rounded off to the first significant figure e.g. roundOff(0.004132) // --> 0.004
 *
 * @param {number} num
 * @returns {number} num rounded off to the first significant figure
 */
export const roundOff = (num) => parseFloat(num.toExponential(Math.max(0, 1 + Math.log10(Math.abs(num)))));

export const centToEuro = (cent) => {
  return cent / 100;
};

/**
 * Convert grams to tonnes
 */
export const gramToTon = (gram) => gram / 1e6;

/**
 * Returns distance in km as a float
 *
 * @param {string} mile
 * @returns {number} distance in km as float
 */
export const mileToKm = (mile) => parseFloat(mile) * 1.852;
