import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Link as MuiLink, Box, Tooltip, Typography } from "@material-ui/core";

import leftImage from "@assets/img/on-the-phone.jpeg";
import rightImage from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { Button, IntroSection } from "@carbon/shared";
import { CALCULATOR_NAV_ITEMS } from "@constants/navigation";
import { FAQ_LINK } from "@constants/links";
import { palette, spacing, FeatureGate } from "@carbon/shared";
import LocationContextMessage from "@components/common/LocationContextMessage";
import PackagesSection from "../frontpage/packages-section/PackagesSection";

const imageWrapperStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${palette("primary.light")};
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const StyledLi = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
  width: 100%;
  margin-top: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  ${imageWrapperStyles};
`;

const StyledLink = styled(({ backgroundImage, ...rest }) => <Link {...rest} />)`
  ${imageWrapperStyles};

  text-decoration: none;
  transition: transform 0.6s;

  &:hover {
    transition: transform 2s;
    transform: scale(1.1);
  }
`;

const StyledButton = styled(Button)`
  min-width: 200px;
`;

const getNavItem = (item) => {
  const isDisabled = Boolean(item.comingSoon);

  const imageComponent = isDisabled ? (
    <ImageWrapper backgroundImage={item.background} />
  ) : (
    <StyledLink to={item.path} disabled={isDisabled} backgroundImage={item.background} />
  );

  let listItem = (
    <StyledLi key={item.path}>
      {imageComponent}
      <StyledButton
        component={isDisabled ? undefined : Link}
        to={isDisabled ? undefined : item.path}
        color="light"
        variant="outlined"
        disabled={isDisabled}
      >
        {item.label}
      </StyledButton>
    </StyledLi>
  );

  if (item.comingSoon) {
    listItem = (
      <Tooltip key={item.path} title="Coming soon" placement="top" arrow>
        <div>{listItem}</div>
      </Tooltip>
    );
  }

  if (item.featureGate) {
    return (
      <FeatureGate key={item.path} feature={item.featureGate}>
        {listItem}
      </FeatureGate>
    );
  }

  return listItem;
};

const ChooseCalculatorPage = ({ headerHeight }) => {
  return (
    <>
      <IntroSection headerHeight={headerHeight} bgcolor="primary.extraLight">
        <Box maxWidth={1000} mx="auto">
          <LocationContextMessage
            fallback={
              <Typography variant="h1" align="center" gutterBottom>
                Calculate your business’ carbon footprint
              </Typography>
            }
          />

          <Typography component="h2" variant="h5" align="center" gutterBottom>
            Choose which activities you would like to calculate emissions for
          </Typography>

          <nav>
            <Box component="ul" maxWidth={900} p={0} mx="auto" mt={[5, 10]}>
              {CALCULATOR_NAV_ITEMS.map(getNavItem)}
            </Box>
          </nav>
        </Box>
      </IntroSection>

      <PackagesSection />

      <Instructions
        large
        title="How to use the calculators"
        subtitle="We’ve designed the calculators to be easy to use, so don’t fret – you don’t have to be a sustainability expert to get started!"
        imageOne={rightImage}
        imageTwo={leftImage}
        listTitle="With Compensate Plus you can:"
        listItems={[
          "Calculate the carbon footprint of your energy use, flights, logistics, digital, and more",
          "Start offsetting right away",
          "Track the climate impact of your efforts",
          "Learn about your emissions",
          <>
            For more, see our tool tips and{" "}
            <MuiLink target="_blank" rel="noopener" href={FAQ_LINK}>
              FAQ
            </MuiLink>
          </>,
        ]}
      />
    </>
  );
};

ChooseCalculatorPage.propTypes = {
  headerHeight: PropTypes.number,
};

export default ChooseCalculatorPage;
