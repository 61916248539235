import { ReactNode } from "react";
import create, { State } from "zustand";
import { AlertProps } from "@material-ui/lab/Alert";

interface Snackbar {
  message: ReactNode;
  severity: AlertProps["severity"];
}

interface SnackbarState extends State {
  isOpen: boolean;
  snackbar: Snackbar | null;
  createSnackbar: (message: ReactNode, severity?: AlertProps["severity"]) => void;
  clearSnackbar: () => void;
  closeSnackbar: () => void;
}

const useSnackbarStore = create<SnackbarState>((set) => ({
  isOpen: false,
  snackbar: null,
  createSnackbar: (message, severity = "success") => set({ snackbar: { message, severity }, isOpen: true }),
  clearSnackbar: () => set({ snackbar: null }),
  closeSnackbar: () => set({ isOpen: false }),
}));

export default useSnackbarStore;
