import { useState, useEffect } from "react";

const useImageLoader = (src?: string) => {
  const [imageLoaded, setImageLoaded] = useState<string | undefined>();

  useEffect(() => {
    if (src) {
      const img = new Image();

      img.src = src;

      img.onload = () => setImageLoaded(src);
    }
  }, [src]);

  return imageLoaded;
};

export default useImageLoader;
