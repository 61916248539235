import React from "react";

export const GCO2e = () => (
  <>
    gCO<sub>2</sub>e/kWh
  </>
);

export const GCO2PerUnit = () => (
  <>
    gCO<sub>2</sub>/Unit
  </>
);

export const KWH = () => <>kWh</>;

export const M2 = () => (
  <>
    m<sup>2</sup>
  </>
);

export const M3 = () => (
  <>
    m<sup>3</sup>
  </>
);

export const TCO2E = () => (
  <>
    tCO<sub>2</sub>e
  </>
);

export const CO2 = () => (
  <>
    CO<sub>2</sub>
  </>
);

export const CO2E = () => (
  <>
    CO<sub>2</sub>e
  </>
);
