import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { FormLabel } from "@material-ui/core";

import { TextField } from "@carbon/shared";
import { DatePicker } from "@components/form";
import { COLUMN } from "@components/form/constants/layout";
import { palette, spacing, message } from "@carbon/shared";

const StyledFormLabel = styled(FormLabel)`
  color: ${palette("text.primary")};
  display: block;
  margin-top: ${spacing(3)}px;
  margin-bottom: -${spacing(1)}px;
`;

const schema = yup.object().shape({
  space_name: yup.string().required(message.isRequired("Space name")),
  from_date: yup.date().nullable().typeError(message.invalidDateFormat).required(message.isRequired("A start date")),
  to_date: yup
    .date()
    .nullable()
    .typeError(message.invalidDateFormat)
    .min(yup.ref("from_date"), "This should be later than the from date")
    .required(message.isRequired("An end date")),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  defaultValues: {
    from_date: null,
    to_date: null,
  },

  fields: [
    {
      render: TextField,
      name: "space_name",
      label: "Description",
      required: true,
      fieldProps: {
        helperText: "Additional information shown on your compensation receipt",
      },
    },
    {
      name: "reportingPeriodLabel",
      render() {
        return <StyledFormLabel>Reporting period</StyledFormLabel>;
      },
    },
    {
      render: DatePicker,
      name: "from_date",
      label: "From",
      required: true,
      wrapperProps: COLUMN.THIRD,
    },
    {
      render: DatePicker,
      name: "to_date",
      label: "To",
      required: true,
      wrapperProps: COLUMN.THIRD,
    },
  ],
};

export default FORM_CONFIG;
