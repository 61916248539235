import React, { useRef, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import debounce from "lodash/debounce";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";

import { fetchAirportSuggestions } from "@api/flight-api";
import FIELD_PROP_TYPES from "../constants/field-prop-types";
import { TextField } from "@carbon/shared";

const NO_AIRPORTS_FOUND = "No airports found";
const START_TYPING = "Start typing to search...";
const KEEP_ON_TYPING = "Enter 3 characters to search";

const AirportAutocomplete = ({ register, label, fieldProps, name, defaultValue = null, ...rest }) => {
  const [inputSuggestions, setInputSuggestions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState(START_TYPING);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearch = useRef();

  if (!debouncedSearch.current) {
    debouncedSearch.current = debounce(async (query) => {
      try {
        const response = await fetchAirportSuggestions(query).run();

        setInputSuggestions(response);
      } catch (error) {
        setInputSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    }, 400);
  }

  const handleChangeSearch = (event) => {
    const query = event.target.value;

    if (!query) {
      setNoOptionsText(START_TYPING);
      setInputSuggestions([]);
    } else if (query.length < 3) {
      setNoOptionsText(KEEP_ON_TYPING);
    } else {
      setNoOptionsText(NO_AIRPORTS_FOUND);
    }

    setIsLoading(true);
    debouncedSearch.current(query);
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, name, ref, value }) => {
        const handleSelectAirport = (e, option) => {
          if (!option) {
            setInputSuggestions([]);
            setNoOptionsText(START_TYPING);
            onChange(null);
          } else {
            onChange(option);
          }
        };

        return (
          <Autocomplete
            autoHighlight
            autoSelect
            getOptionSelected={(option, value) => option.iata_code === value.iata_code}
            getOptionLabel={(option) => `${option.iata_code} - ${option.name}`}
            value={value}
            options={inputSuggestions}
            loading={isLoading}
            onChange={handleSelectAirport}
            onBlur={onBlur}
            noOptionsText={noOptionsText}
            filterOptions={(x) => x} // Required for asynchronous results https://mui.com/components/autocomplete/#search-as-you-type
            renderInput={(params) => (
              <TextField
                inputRef={ref}
                name={name}
                label={label}
                onChange={handleChangeSearch}
                {...rest}
                {...fieldProps}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  ...fieldProps.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

AirportAutocomplete.propTypes = FIELD_PROP_TYPES;

AirportAutocomplete.defaultProps = {
  fieldProps: {},
};

export default AirportAutocomplete;
