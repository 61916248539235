import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

import { palette, spacing } from "@carbon/shared";
import leafBannerImage from "@assets/img/leafy-banner.png";
import { formatCO2Emission, formatMoney } from "@utils/formatting";
import { centToEuro, gramToTon } from "@utils/unit-conversion";
import { TCO2E } from "@components/common/Units";

const CO2_TON_TO_PROTECTED_FOREST_RATIO = 0.055;

const CompensationSummaryBanner = styled.div`
  display: flex;
  justify-content: center;
  background: ${palette("primary.main")};
  color: ${palette("text.light")};
  text-align: center;
  padding: ${spacing(6, 2)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;

    > p {
      margin: 0;
    }
  }

  > h6 {
    margin: ${spacing(0, 2)};

    &:first-of-type {
      margin-left: 0;
    }

    &:lat-of-type {
      margin-right: 0;
    }
  }
`;

const TreesProtectedBanner = styled.div`
  color: ${palette("text.light")};
  text-align: center;
  background-color: ${palette("primary.main")};
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${leafBannerImage});
  background-position: center;
  background-size: cover;
  padding: ${spacing(12, 2)};
`;

const ImpactSummary = ({ emissions, price, currencySymbol = "€" }) => {
  const emissionsInTons = gramToTon(emissions);

  return (
    <>
      <CompensationSummaryBanner>
        <Typography variant="subtitle2">
          <b>Total:</b>
        </Typography>

        <Typography variant="subtitle2">
          Compensation{" "}
          <b>
            {formatMoney(undefined, centToEuro(price))} {currencySymbol}
          </b>
        </Typography>

        <Typography variant="subtitle2">
          Emissions{" "}
          <b>
            {formatCO2Emission(undefined, emissionsInTons)} <TCO2E />
          </b>
        </Typography>
      </CompensationSummaryBanner>

      <TreesProtectedBanner>
        <Typography variant="h3">
          This equals to {Math.ceil(emissionsInTons / CO2_TON_TO_PROTECTED_FOREST_RATIO).toLocaleString("fi")} m² of
          rainforest protected
        </Typography>
      </TreesProtectedBanner>
    </>
  );
};

ImpactSummary.propTypes = {
  emissions: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string,
};

export default ImpactSummary;
