import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

import { AssistanceSection } from "@components/common/TextSection";
import { IntroSection } from "@carbon/shared";
import ImpactSummary from "../common/ImpactSummary";

const CalculatorPage = ({
  backgroundImage,
  children,
  headerHeight,
  improvedContrast,
  instructions,
  subtitle,
  summary,
  summaryTable,
  title,
}) => {
  return (
    <>
      <IntroSection
        improvedContrast={improvedContrast}
        headerHeight={headerHeight}
        bgcolor="primary.extraLight"
        mb={[0, 0, 0, 0]} // Override margin bottom at all breakpoints
        backgroundImage={backgroundImage}
      >
        <Box maxWidth={1200} mx="auto">
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            {title}
          </Typography>

          <Typography component="h2" variant="h5" align="center" gutterBottom>
            {subtitle}
          </Typography>

          {children}
        </Box>
      </IntroSection>

      <ImpactSummary emissions={summary.emissions} price={summary.price} />

      {!!summaryTable && (
        <Box m={[4, 4, 12]}>
          <Typography variant="h3" gutterBottom id="summary">
            Summary
          </Typography>

          {summaryTable}
        </Box>
      )}

      {instructions}

      <AssistanceSection />
    </>
  );
};

CalculatorPage.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerHeight: PropTypes.number,
  subtitle: PropTypes.node.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number.isRequired,
    emissions: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
  summaryTable: PropTypes.node,
  title: PropTypes.string.isRequired,
  improvedContrast: PropTypes.bool,
  instructions: PropTypes.node,
};

export default CalculatorPage;
