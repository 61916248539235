import React from "react";

import Dropdown from "./Dropdown";

import FIELD_PROP_TYPES from "../constants/field-prop-types";

const buildingTypeOptions = [
  { value: "by_area/restaurant", label: "Restaurants, cafes" },
  { value: "by_area/hotel", label: "Hotels" },
  { value: "by_area/office", label: "Offices" },
  { value: "by_area/theater_and_concert_hall", label: "Theaters, music venues, auditoriums" },
  { value: "by_area/museum_and_exhibition_hall", label: "Museums, exhibition venues" },
  { value: "by_area/other", label: "Other" },
];

const BuildingTypeDropdown = (props) => (
  <Dropdown defaultValue="by_area/office" options={buildingTypeOptions} {...props} />
);

BuildingTypeDropdown.propTypes = FIELD_PROP_TYPES;

export default BuildingTypeDropdown;
