import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { RadioButtons } from "@components/form";

import {
  COPY_TEXT_FOR_THIS_SPACE,
  COPY_TEXT_WHOLE_BUILDING,
  FOR_REPORTED_SPACE,
  FOR_WHOLE_BUILDING,
  WITH_KNOWN_EMISSION_FACTOR,
} from "./constants";

import {
  AMOUNT_USED_FIELD,
  BUILDING_SIZE_FIELD,
  COMMON_SCHEMA_CONFIG,
  EMISSION_FACTOR_FIELD,
  SPACE_SIZE_FIELD,
} from "./common-form-config";

const showDistrictCoolingType = (amountKnown) => !!amountKnown;

const schema = yup.object().shape({
  ...COMMON_SCHEMA_CONFIG,
  used_type: yup
    .string()
    .when("amount_known", { is: showDistrictCoolingType, then: yup.string().required("Please choose an option") }),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },

  defaultValues: {
    amount_known: "",
    used_type: "",
  },

  fields: [
    {
      label: "Do you know the amount of district cooling used?",
      name: "amount_known",
      render: RadioButtons,
      helperText: "If your space doesn’t use district cooling, please skip this section",
      required: true,
      options: [
        {
          value: FOR_REPORTED_SPACE,
          label: COPY_TEXT_FOR_THIS_SPACE,
        },
        {
          value: FOR_WHOLE_BUILDING,
          label: COPY_TEXT_WHOLE_BUILDING,
        },
      ],
    },

    SPACE_SIZE_FIELD,

    BUILDING_SIZE_FIELD,

    AMOUNT_USED_FIELD,

    {
      render: RadioButtons,
      name: "used_type",
      label: "District cooling is",
      required: true,
      options: [
        {
          value: "FI",
          label: "General district cooling, Finland",
        },
        {
          value: WITH_KNOWN_EMISSION_FACTOR,
          label: "Provider or country specific with known emission factor",
        },
      ],
      dependsOn: {
        name: "amount_known",
        condition: showDistrictCoolingType,
      },
    },

    EMISSION_FACTOR_FIELD,
  ],
};

export default FORM_CONFIG;
