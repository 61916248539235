import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FieldArrayForm } from "../form.types";

/**
 * A hook to reset the form state upon successful submission and set asynchronous form values,
 * for example when editing customer information
 */
function useFormReset<TForm extends object>(resetOnSubmit: boolean, asyncFormValues?: TForm | FieldArrayForm<TForm>) {
  const { formState, setValue, reset } = useFormContext();
  const { isSubmitSuccessful } = formState;

  useEffect(() => {
    if (isSubmitSuccessful && resetOnSubmit) {
      // Reset the form after successful submit
      reset();
    } else if (asyncFormValues instanceof Object && Object.keys(asyncFormValues).length) {
      // Set new form values if asyncFormValues exists and has changed
      Object.entries(asyncFormValues).forEach(([key, value]) => setValue(key, value));
    }
  }, [resetOnSubmit, isSubmitSuccessful, reset, asyncFormValues, setValue]);
}

export default useFormReset;
