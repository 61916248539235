import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FUEL_TYPE_OTHER } from "@constants/fuel-types";
import { NumberFieldWithAdornment } from "@components/form";
import { GCO2PerUnit } from "@components/common/Units";
import { requiredMessage } from "@components/form/utils/form-messages";
import { requiredNumber } from "../../../components/form/utils/schema";

import FuelTypeDropdown from "./components/FuelTypeDropdown";
import AmountOfFuelUsed from "./components/AmountOfFuelUsed";

const isFuelTypeOther = (fuelType) => fuelType === FUEL_TYPE_OTHER;

const schema = yup.object().shape({
  used_type: yup.string().required(requiredMessage("Fuel type")),
  known_emission_factor: yup.number().when("used_type", {
    is: isFuelTypeOther,
    then: requiredNumber("Emission factor"),
  }),
  amount_used: requiredNumber("Amount used"),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },

  defaultValues: {
    used_type: "",
  },

  fields: [
    {
      label: "Type of fuel used",
      name: "used_type",
      render: FuelTypeDropdown,
      required: true,
    },

    {
      label: "Add emission factor",
      name: "known_emission_factor",
      render(props) {
        return <NumberFieldWithAdornment adornment={<GCO2PerUnit />} {...props} />;
      },
      dependsOn: {
        name: "used_type",
        condition: isFuelTypeOther,
      },
      required: true,
      fieldProps: {
        helperText: (
          <>
            Please make sure that the unit in emission factor [<GCO2PerUnit />] is the same as in used fuel
          </>
        ),
      },
    },

    {
      label: "Amount used",
      name: "amount_used",
      render(props) {
        return <AmountOfFuelUsed fuelTypeField="used_type" {...props} />;
      },
      required: true,
    },
  ],
};

export default FORM_CONFIG;
