import React from "react";
import styled from "styled-components";

import { Section } from "@carbon/shared";
import useImageLoader from "../hooks/useImageLoader";
import { SectionProps } from "./Section";

interface Props extends SectionProps {
  /** Small image to be shown while main backgroundImage loads */
  placeholder?: string;
  backgroundImage?: string;
  headerHeight?: number;
  /** Add a semitransparent overlay on complex background images for improved accessibility */
  improvedContrast?: boolean;
  children: React.ReactNode;
}

const getBackgroundImageStyles = (backgroundImage: string, improvedContrast?: boolean) => `
  ${
    improvedContrast
      ? `background: linear-gradient(rgba(229, 241, 242, 0.8), rgba(229, 241, 242, 0.8)), url("${backgroundImage}");`
      : `background-image: url("${backgroundImage}");`
  }
  background-position: center top;
  background-size: cover;
  `;

const StyledIntroSection = styled(({ headerHeight, backgroundImage, improvedContrast, ...rest }: Props) => (
  <Section {...rest} />
))`
  ${({ theme, headerHeight = 0, backgroundImage, improvedContrast }) => `
    min-height: calc(100vh - ${headerHeight}px - ${theme.spacing(18)}px);
    margin-top: -${headerHeight}px;
    padding-top: calc(${headerHeight}px + ${theme.spacing(8)}px);
    
    ${backgroundImage && getBackgroundImageStyles(backgroundImage, improvedContrast)}
  `}
`;

/**
 * Makes a Section flush with the top of the header nav bar,
 * overlaying the header on the section background colour or image
 */
const IntroSection = ({ placeholder, backgroundImage, ...props }: Props) => {
  const loadedImage = useImageLoader(backgroundImage);

  return <StyledIntroSection backgroundImage={loadedImage || placeholder} {...props} />;
};

export default IntroSection;
