import React from "react";
import * as yup from "yup";

import { is } from "@utils/comparison";
import { pleaseSelect } from "@components/form/utils/form-messages";
import { Dropdown, ToggleButtons, NumberFieldWithAdornment } from "@components/form";
import { requiredNumber } from "../../../components/form/utils/schema";

import VehicleDetailsDropdown from "./components/VehicleDetailsDropdown";
import {
  TRANSPORTATION_TYPE,
  LAND_CALCULATION_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
  CALCULATE_BY,
  SPECIFIC_FUEL_TYPE_OPTIONS,
} from "./constants";

export const isLandTransportation = is(TRANSPORTATION_TYPE.LAND);

export const isLandAndCalculateByFuel = (type, by) => isLandTransportation(type) && by === CALCULATE_BY.FUEL;

export const LAND_FORM_SCHEMA = {
  landCalculationBy: yup
    .string()
    .when("transportationType", { is: isLandTransportation, then: yup.string().required(pleaseSelect("an option")) }),

  vehicleType: yup.string().when("transportationType", {
    is: isLandTransportation,
    then: yup.string().required(pleaseSelect("a vehicle type")),
  }),

  vehicleDetails: yup.string().when(["transportationType", "landCalculationBy"], {
    is: (type, by) => isLandTransportation(type) && by !== CALCULATE_BY.FUEL,
    then: yup.string().required(pleaseSelect("an option")),
  }),

  fuelType: yup.string().when(["transportationType", "landCalculationBy"], {
    is: isLandAndCalculateByFuel,
    then: yup.string().required(pleaseSelect("a fuel type")),
  }),

  fuelAmount: yup.number().when(["transportationType", "landCalculationBy"], {
    is: isLandAndCalculateByFuel,
    then: requiredNumber("Fuel used"),
  }),
};

export const LAND_FORM_DEFAULTS = {
  landCalculationBy: "",
  vehicleType: "",
  vehicleDetails: "",
  fuelType: "",
};

export const LAND_FORM_FIELDS = [
  {
    render: Dropdown,
    name: "landCalculationBy",
    label: "Calculate the emissions based on",
    required: true,
    options: LAND_CALCULATION_OPTIONS,
    dependsOn: {
      name: "transportationType",
      condition: isLandTransportation,
    },
    fieldProps: {
      helperText: "If your goods/cargo is part of a larger load, select weight",
    },
  },

  {
    render: ToggleButtons,
    name: "vehicleType",
    label: "The vehicle type is",
    required: true,
    options: VEHICLE_TYPE_OPTIONS,
    dependsOn: {
      name: "transportationType",
      condition: isLandTransportation,
    },
  },

  {
    render(props) {
      return <VehicleDetailsDropdown {...props} />;
    },
    name: "vehicleDetails",
    label: "Vehicle details",
    required: true,
    dependsOn: {
      fields: {
        transportationType: isLandTransportation,
        landCalculationBy: (calculateBy) => calculateBy !== CALCULATE_BY.FUEL,
      },
    },
  },

  {
    render: Dropdown,
    name: "fuelType",
    label: "Type of fuel",
    required: true,
    options: SPECIFIC_FUEL_TYPE_OPTIONS,
    dependsOn: {
      fields: {
        transportationType: isLandTransportation,
        landCalculationBy: (calculateBy) => calculateBy === CALCULATE_BY.FUEL,
      },
    },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="km" {...props} />;
    },
    name: "distance",
    label: "Transported distance",
    required: true,
    dependsOn: {
      fields: {
        transportationType: isLandTransportation,
        landCalculationBy: (calculateBy) =>
          calculateBy === CALCULATE_BY.WEIGHT || calculateBy === CALCULATE_BY.DISTANCE,
      },
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="kg" digits={2} {...props} />;
    },
    name: "weight",
    label: "Weight of the goods/cargo",
    required: true,
    fieldProps: {
      helperText: "We automatically add 10% to include secondary packaging",
    },
    dependsOn: {
      fields: {
        transportationType: isLandTransportation,
        landCalculationBy: (calculateBy) => calculateBy === CALCULATE_BY.WEIGHT,
      },
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="l" {...props} />;
    },
    name: "fuelAmount",
    label: "Amount of fuel used",
    required: true,
    dependsOn: {
      fields: {
        transportationType: isLandTransportation,
        landCalculationBy: (calculateBy) => calculateBy === CALCULATE_BY.FUEL,
      },
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },
];
