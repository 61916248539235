import React from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "../assets/logo/compensate-black.svg";
import { ReactComponent as MiniLogo } from "../assets/logo/compensate-black-mini.svg";

import { spacing, palette } from "../theme";

// BaseWrapper handles the logic of styling light and dark logos
const BaseWrapper = styled.div<{ $light: boolean }>`
  path {
    fill: ${($light) => ($light ? palette("text.light") : palette("text.dark"))};
  }
`;

const Wrapper = styled(BaseWrapper)`
  height: 46px;
  width: auto;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    height: 64px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 90px;
  }
`;

const MiniWrapper = styled(BaseWrapper)`
  height: ${spacing(6)}px;
  width: ${spacing(6)}px;
`;

interface Props {
  mini?: boolean;
  light?: boolean;
}

const CompensateLogo = ({ mini = false, light = false }: Props) =>
  mini ? (
    <MiniWrapper $light={light}>
      <MiniLogo />
    </MiniWrapper>
  ) : (
    <Wrapper $light={light}>
      <Logo />
    </Wrapper>
  );

export default CompensateLogo;
