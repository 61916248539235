export const local = {
  env: "development",
  firebase: {
    apiKey: "AIzaSyATPrzEGq8OQJWgJbL45iTiFWfKCh71w_4",
    authDomain: "compensate-infrastructure.firebaseapp.com",
    databaseURL: "https://compensate-infrastructure.firebaseio.com",
    projectId: "compensate-infrastructure",
    storageBucket: "compensate-infrastructure.appspot.com",
    messagingSenderId: "302013849624",
    appId: "1:302013849624:web:7f2b9e4509e7c2a442e36d",
  },
  stripeLicensingKey:
    "pk_test_51Ht7ITE24VmTbMrFt9mhi2mJM3S0775BASAKoEMFa1g95jxiSqZhkhpI4hT2OaDKS7HUzrQ3Sk3moG3mDJ2h8kGm00lRdrXnJJ",
  stripeCompensationKey:
    "pk_test_51HtA0sHRylZsxYrjCb3VXAzGEoUlOniOi49jNQ2nIPDJ1cOMsYoLc62vL8hha0zn3jCKMPAFQy9Bzrr8wt6d0gOX00wd4RD9Gj",
  features: {
    otherCompensations: true,
    indexCalculator: true,
    analytics: false, // Keep Google Analytics disabled locally
  },
};

export const dev = {
  env: "development",
  firebase: {
    apiKey: "AIzaSyATPrzEGq8OQJWgJbL45iTiFWfKCh71w_4",
    authDomain: "compensate-infrastructure.firebaseapp.com",
    databaseURL: "https://compensate-infrastructure.firebaseio.com",
    projectId: "compensate-infrastructure",
    storageBucket: "compensate-infrastructure.appspot.com",
    messagingSenderId: "302013849624",
    appId: "1:302013849624:web:7f2b9e4509e7c2a442e36d",
  },
  stripeLicensingKey:
    "pk_test_51Ht7ITE24VmTbMrFt9mhi2mJM3S0775BASAKoEMFa1g95jxiSqZhkhpI4hT2OaDKS7HUzrQ3Sk3moG3mDJ2h8kGm00lRdrXnJJ",
  stripeCompensationKey:
    "pk_test_51HtA0sHRylZsxYrjCb3VXAzGEoUlOniOi49jNQ2nIPDJ1cOMsYoLc62vL8hha0zn3jCKMPAFQy9Bzrr8wt6d0gOX00wd4RD9Gj",
  features: {
    otherCompensations: false,
    indexCalculator: true,
    analytics: false,
  },
};

export const prod = {
  env: "production",
  firebase: {
    apiKey: "AIzaSyCCYCKS_HIvkfnN6zvW6oFAGAEPbp0rAJc",
    authDomain: "coco-production.firebaseapp.com",
    databaseURL: "https://coco-production.firebaseio.com",
    projectId: "coco-production",
    storageBucket: "coco-production.appspot.com",
    messagingSenderId: "540035933323",
    appId: "1:540035933323:web:837a0fbef0edbf103aa08e",
  },
  stripeLicensingKey:
    "pk_live_51Ht7ITE24VmTbMrFKHrDjh8dTBkrRPaFTymOD4nuUUL7rMRhNWS06RkT0i4zwC1JIipLipDukHVGhZ3Cv3jVDIkn00OY8sxhOb",
  stripeCompensationKey:
    "pk_live_51HtA0sHRylZsxYrjXTpWwdSSENvkP58kirc25ExlwboCxpgwhC2UuQt6WZ8r3SZ2kdQXEWb2oj8Ws830vKj05aFO0066uo0vsk",
  features: {
    otherCompensations: false,
    indexCalculator: true,
    analytics: true,
  },
};
