import { KILOMETERS, NAUTICAL_MILES } from "@constants/distance";

export const TRANSPORTATION_TYPE = {
  LAND: "land",
  AIR: "air",
  RAIL: "rail",
  SEA: "sea",
};

export const TRANSPORTATION_OPTIONS = [
  { value: TRANSPORTATION_TYPE.LAND, label: "Land" },
  { value: TRANSPORTATION_TYPE.AIR, label: "Air" },
  { value: TRANSPORTATION_TYPE.RAIL, label: "Rail" },
  { value: TRANSPORTATION_TYPE.SEA, label: "Ocean/Sea" },
];

export const CALCULATE_BY = {
  WEIGHT: "delivery_weight",
  DISTANCE: "distance",
  FUEL: "fuel",
};

export const LAND_CALCULATION_OPTIONS = [
  { value: CALCULATE_BY.WEIGHT, label: "Weight of the goods/cargo" },
  { value: CALCULATE_BY.DISTANCE, label: "Distance" },
  { value: CALCULATE_BY.FUEL, label: "Fuel used" },
];

export const VEHICLE_TYPE = {
  HEAVY: "heavy",
  VAN: "van",
  SMALL: "small",
  AVERAGE: "average",
};

export const VEHICLE_TYPE_OPTIONS = [
  { value: VEHICLE_TYPE.HEAVY, label: "Heavy/Large goods vehicle" },
  { value: VEHICLE_TYPE.VAN, label: "Van/Truck" },
  { value: VEHICLE_TYPE.SMALL, label: "Car" },
  { value: VEHICLE_TYPE.AVERAGE, label: "Average" },
];

export const LORRY_TYPE = {
  UNKNOWN: "unknown",
  RIGID: "rigid",
  ARTICULATED: "articulated",
  REFRIGERATED_UNKNOWN: "refrigerated_unknown",
  REFRIGERATED_RIGID: "refrigerated_rigid",
  REFRIGERATED_ARTICULATED: "refrigerated_articulated",
};

export const LORRY_TYPE_OPTIONS = [
  { value: LORRY_TYPE.UNKNOWN, label: "Type unknown" },
  { value: LORRY_TYPE.RIGID, label: "Rigid" },
  { value: LORRY_TYPE.ARTICULATED, label: "Articulated" },
  { value: LORRY_TYPE.REFRIGERATED_UNKNOWN, label: "Refrigerated, type unknown" },
  { value: LORRY_TYPE.REFRIGERATED_RIGID, label: "Refrigerated, rigid" },
  { value: LORRY_TYPE.REFRIGERATED_ARTICULATED, label: "Refrigerated, articulated" },
];

export const GENERIC_FUEL_TYPE = {
  DIESEL: "diesel",
  PETROL: "petrol",
  ELECTRIC: "electric",
  FUEL_UNKNOWN: "fuel_unknown",
};

export const GENERIC_FUEL_TYPE_OPTIONS = [
  { value: GENERIC_FUEL_TYPE.DIESEL, label: "Diesel" },
  { value: GENERIC_FUEL_TYPE.PETROL, label: "Petrol" },
  { value: GENERIC_FUEL_TYPE.ELECTRIC, label: "Electric" },
  { value: GENERIC_FUEL_TYPE.FUEL_UNKNOWN, label: "Fuel unknown" },
];

export const SPECIFIC_FUEL_TYPE = {
  GASOLINE: "gasoline",
  DIESEL: "diesel",
  ETHANOL: "ethanol",
  BIODIESEL: "biodiesel",
  GAS: "gas",
  BIOGAS: "biogas",
};

export const SPECIFIC_FUEL_TYPE_OPTIONS = [
  { value: SPECIFIC_FUEL_TYPE.GASOLINE, label: "Gasoline (average biofuel blend)" },
  { value: SPECIFIC_FUEL_TYPE.DIESEL, label: "Diesel (average biofuel blend)" },
  { value: SPECIFIC_FUEL_TYPE.ETHANOL, label: "Ethanol-blended fuel (flexifuel)" },
  { value: SPECIFIC_FUEL_TYPE.BIODIESEL, label: "Biodiesel" },
  { value: SPECIFIC_FUEL_TYPE.GAS, label: "Natural gas" },
  { value: SPECIFIC_FUEL_TYPE.BIOGAS, label: "Biogas" },
];

export const CAR_FUEL_TYPE = {
  ESTIMATED_AVERAGE: "car_estimated_average",
  GASOLINE: "car_gasoline",
  DIESEL: "car_diesel",
  ETHANOL: "car_ethanol",
  ELECTRIC: "car_electric",
  HYBRID: "car_hybrid",
};

export const CAR_FUEL_TYPE_OPTIONS = [
  { value: CAR_FUEL_TYPE.ESTIMATED_AVERAGE, label: "Estimated average (fuel unknown)" },
  { value: CAR_FUEL_TYPE.GASOLINE, label: "Gasoline (average biofuel blend)" },
  { value: CAR_FUEL_TYPE.DIESEL, label: "Diesel (average biofuel blend)" },
  { value: CAR_FUEL_TYPE.ETHANOL, label: "Ethanol-blended fuel (flexifuel)" },
  { value: CAR_FUEL_TYPE.ELECTRIC, label: "Electric" },
  { value: CAR_FUEL_TYPE.HYBRID, label: "Plug-in hybrid" },
];

export const AVERAGE_OPTIONS = [{ value: "average", label: "Average" }];

export const SEA_CARGO_TYPE = {
  CONTAINER_SHIP: "container_ship",
  REFRIGERATED_CARGO: "refrigerated_cargo",
  FERRY: "ferry",
};

export const SEA_CARGO_TYPE_OPTIONS = [
  { value: SEA_CARGO_TYPE.CONTAINER_SHIP, label: "Container ship" },
  { value: SEA_CARGO_TYPE.REFRIGERATED_CARGO, label: "Refrigerated cargo" },
  { value: SEA_CARGO_TYPE.FERRY, label: "Cruiseferry" },
];

export const DISTANCE_UNIT_OPTIONS = [
  { value: NAUTICAL_MILES, label: NAUTICAL_MILES },
  { value: KILOMETERS, label: KILOMETERS },
];

export const AIRPORTS_KNOWN = {
  YES: "yes",
  NO: "no",
};

export const AIRPORTS_KNOWN_OPTIONS = [
  { value: AIRPORTS_KNOWN.YES, label: "Yes" },
  { value: AIRPORTS_KNOWN.NO, label: "No, let's use an estimated average" },
];
