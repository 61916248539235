import React from "react";
import { Link, LinkProps } from "@material-ui/core";

declare global {
  interface Window {
    // cookiehub is injected by the CookieHub script
    cookiehub: any;
  }
}

interface Props {
  color?: LinkProps["color"];
  display?: LinkProps["display"];
}

/**
 * For services that use third party cookies, we manage consent with CookieHub.
 * This component should be only used if the CookieHub script is installed on a page,
 * either directly or via Google Tag Manager.
 */
const ChangeCookieHubConsent = (props: Props) => {
  const handleRevokeCookieConsent = () => {
    if (window.cookiehub) {
      window.cookiehub.openSettings();
    }
  };

  return (
    <Link variant="body2" component="button" onClick={handleRevokeCookieConsent} {...props}>
      Change cookie consent
    </Link>
  );
};

export default ChangeCookieHubConsent;
