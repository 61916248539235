import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { useFieldArray, useFormContext } from "react-hook-form";
import pick from "lodash/pick";
import Delete from "@material-ui/icons/Delete";

import Button from "../components/Button";

import FieldGenerator from "./FieldGenerator";
import { MultiFormGeneratorProps, FormGeneratorBaseProps } from "./form.types";

const FieldArrayList = styled.ul`
  list-style: none;
  padding: 0;
`;

const RemoveRowButton = styled(Button)`
  display: flex;
  position: unset;
  opacity: 0.8;

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 61px; // Rough height of an input to align the button with inputs rather than input help text
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0 auto;
  }
`;

type Props<TForm extends object> = { formName: string } & Pick<
  MultiFormGeneratorProps<TForm>,
  "duplicateFieldsOnAppend"
> &
  Pick<FormGeneratorBaseProps<TForm>, "fields" | "defaultValues">;

function FieldArrayRows<TForm extends object>({
  formName,
  defaultValues,
  duplicateFieldsOnAppend,
  fields,
}: Props<TForm>) {
  const { getValues } = useFormContext();
  const { fields: fieldArrayFields, append, remove } = useFieldArray({
    name: formName,
  });

  const handleAppend = () => {
    const values = getValues()[formName];
    const lastField = values[values.length - 1];

    if (lastField) {
      const copiedFields = pick(lastField, duplicateFieldsOnAppend || []);
      const newFields = { ...defaultValues, ...copiedFields };

      append(newFields);
    } else {
      append(defaultValues);
    }
  };

  return (
    <Grid item container spacing={1} data-testid={formName}>
      <Grid component={FieldArrayList} item container spacing={4}>
        {fieldArrayFields.map((arrayField, i) => (
          <Grid component="li" key={arrayField.id} item container spacing={1} alignItems="flex-start">
            <Grid item container spacing={1} xs={12} md={10} xl={11}>
              {fields.map(({ name, ...field }, ii) => (
                <FieldGenerator
                  key={`${i}-${ii}-${name}`}
                  name={name ? `${formName}.${i}.${name}` : undefined}
                  defaultValue={name ? arrayField[name] : undefined}
                  {...field}
                />
              ))}
            </Grid>

            <Grid key={arrayField.id} item xs={12} md={2} xl={1}>
              {fieldArrayFields.length > 1 && (
                <RemoveRowButton
                  size="small"
                  endIcon={<Delete />}
                  onClick={() => remove(i)}
                  variant="text"
                  color="secondary"
                >
                  Remove
                </RemoveRowButton>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Button variant="text" onClick={handleAppend} size="small">
          + Add another row
        </Button>
      </Grid>
    </Grid>
  );
}

export default FieldArrayRows;
