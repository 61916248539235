import React from "react";
import styled from "styled-components";
import { Grid, CircularProgress, GridProps } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import Button, { ButtonProps } from "../components/Button";

interface SubmitFormButtonProps extends ButtonProps {
  label?: string;
  wrapperProps?: GridProps;
}

const StyledSubmitButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const SubmitFormButton = ({ label = "Submit", wrapperProps, ...props }: SubmitFormButtonProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Grid item xs={12} {...wrapperProps}>
      <StyledSubmitButton
        type="submit"
        disabled={isSubmitting}
        endIcon={isSubmitting && <CircularProgress />}
        data-testid="submit-calculation"
        {...props}
      >
        {label}
      </StyledSubmitButton>
    </Grid>
  );
};

export default SubmitFormButton;
