import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Grid, Link as MuiLink, Typography } from "@material-ui/core";

import {
  FAQ_LINK,
  COMPENSATE_COM_LINK,
  PRIVACY_STATEMENT_LINK,
  TOU_LINK,
  BRAND_GUIDELINES_LINK,
} from "../constants/links";
import { palette, spacing } from "../theme";
import Section from "./Section";

import logo from "../assets/logo/compensate-white-alt.svg";

const Logo = styled.img`
  width: 120px;
  height: auto;
`;

const Wrapper = styled(Section)`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  background: black;
  color: ${palette("text.light")};

  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
  }
`;

const SubFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid white;
  margin: ${spacing(0, 10, 2)};
  padding-top: ${spacing(2)}px;

  a {
    margin-right: ${spacing(2)}px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: relative;
    flex-direction: column;
    margin: ${spacing(4, 2, 0)};

    a {
      margin-right: 0;
    }
  }
`;

const Tagline = styled(Typography)`
  opacity: 0.5;
`;

const linkStyles = css`
  display: block;
  margin-bottom: ${spacing(1)}px;
`;

const StyledExternalLink = styled((props) => <MuiLink color="inherit" target="_blank" rel="noopener" {...props} />)`
  ${linkStyles};
`;

const StyledInternalLink = styled((props) => <MuiLink component={Link} {...props} />)`
  ${linkStyles};
`;

const CopyrightTypography = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: auto;
  }
`;

interface FooterLink {
  path: string;
  label: string;
}

interface Props {
  accessLinks?: FooterLink[];
  legalInfo?: React.ReactNode;
  productLinks: FooterLink[];
  tagline: string;
  withFAQs?: boolean;
}

const Footer = ({ tagline, accessLinks, productLinks, legalInfo, withFAQs = true }: Props) => (
  <Wrapper component="footer">
    <Grid container spacing={5} alignItems="center">
      <Grid item xs={12} lg={2} xl={1}>
        <Link to="/">
          <Logo alt="Compensate logo" src={logo} />
        </Link>
      </Grid>

      <Grid item xs={12} lg={4} xl={5}>
        <Tagline>{tagline}</Tagline>
      </Grid>

      {!!accessLinks?.length && (
        <Grid item xs={12} lg={2}>
          {accessLinks.map((link) => (
            <StyledInternalLink key={link.path} to={link.path}>
              {link.label}
            </StyledInternalLink>
          ))}
        </Grid>
      )}

      <Grid item xs={12} lg={2}>
        {productLinks.map((link) => (
          <StyledInternalLink key={link.path} to={link.path} color="inherit">
            {link.label}
          </StyledInternalLink>
        ))}
      </Grid>

      <Grid item xs={12} lg={2}>
        <StyledExternalLink href={COMPENSATE_COM_LINK}>Compensate.com</StyledExternalLink>

        {withFAQs && <StyledExternalLink href={FAQ_LINK}>FAQ</StyledExternalLink>}

        <StyledExternalLink href={BRAND_GUIDELINES_LINK}>Brand guidelines</StyledExternalLink>
      </Grid>
    </Grid>

    <SubFooter>
      <MuiLink color="inherit" target="_blank" rel="noopener" href={PRIVACY_STATEMENT_LINK}>
        Privacy Statement
      </MuiLink>

      <MuiLink color="inherit" target="_blank" rel="noopener" href={TOU_LINK}>
        Terms of Use
      </MuiLink>

      {legalInfo}

      <CopyrightTypography variant="body2">
        © Compensate {new Date().getFullYear()}. All rights reserved.
      </CopyrightTypography>
    </SubFooter>
  </Wrapper>
);

export default Footer;
