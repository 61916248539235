import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Link as MuiLink,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper,
  CircularProgress,
  Box,
} from "@material-ui/core";
import parseISO from "date-fns/parseISO";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import { cancelLicense } from "@api/customer-api";
import { getLicenseByStripeLookupKey } from "@utils/license";
import { spacing, ChangeCookieHubConsent, Button, IntroSection, ExternalLinkButton } from "@carbon/shared";
import useAuthStore, {
  cocoUserSelector,
  firebaseUserSelector,
  fetchCocoUserSelector,
  LICENSE_STATUS,
} from "@store/auth";
import { formatDate } from "@utils/formatting";
import PlanCard from "@components/common/PlanCard";
import useSnackbarStore from "../store/snackbar";
import positiveToolkitPreview from "../assets/img/positive-toolkit.png";
import useStripePortal from "../hooks/useStripePortal";

const getLicenseText = (licenseStatus, nextBillingDate) => {
  if (licenseStatus === LICENSE_STATUS.CANCELLED) {
    return (
      <>
        Your free trial has been cancelled, but you can still access Compensate Plus until <b>{nextBillingDate}</b>. If
        you change your mind, make a compensation payment from one of our calculators to start your subscription.
      </>
    );
  }

  if (licenseStatus === LICENSE_STATUS.FREE_TRIAL) {
    return (
      <>
        Your free trial is active until <b>{nextBillingDate}</b>, you can cancel your trial at any time.
      </>
    );
  }

  return (
    <>
      Your next billing date is <b>{nextBillingDate}</b>
    </>
  );
};

const DialogCard = styled(Paper)`
  padding: ${spacing(4)}px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: ${spacing(2)}px;
  }
`;

const ToolkitImg = styled.img`
  max-width: 100%;
`;

const Account = ({ headerHeight }) => {
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const firebaseUser = useAuthStore(firebaseUserSelector);
  const cocoUser = useAuthStore(cocoUserSelector);
  const fetchCocoUser = useAuthStore(fetchCocoUserSelector);
  const history = useHistory();
  const { isLoading, redirectToStripePortal } = useStripePortal();

  const licenseDetails = getLicenseByStripeLookupKey(cocoUser?.license?.type) || {};
  const licenseStatus = cocoUser.license.license_status;
  const isTrialActive = licenseStatus === LICENSE_STATUS.FREE_TRIAL;
  const nextBillingDate = formatDate(parseISO(cocoUser.license?.valid_until), "dd.MM.yyyy");

  const handleClickCancelSubscription = () => setIsCancelModalOpen(true);

  const handleConfirmCancelSubscription = async () => {
    setIsCancelling(true);

    try {
      await cancelLicense();

      fetchCocoUser();
      setIsCancelModalOpen(false);
      createSnackbar(
        "Subscription cancelled. You may test Compensate Plus until the end of your free trial and you won't be charged.",
      );
      history.push({ search: "?flow=cancel" });
    } catch (error) {
      createSnackbar(
        `Failed to cancel license, please contact us at support@compensate.com. ${error.message}`,
        "error",
      );
    } finally {
      setIsCancelling(false);
    }
  };

  const handleCloseModal = () => setIsCancelModalOpen(false);

  const LinkButton = styled((props) => <Button color="primary" variant="text" size="small" {...props} />)`
    background: transparent !important;
    padding: 0;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;

    &:hover {
      text-decoration: underline;
    }
  `;

  return (
    <>
      <IntroSection headerHeight={headerHeight} bgcolor="primary.extraLight">
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Your account
        </Typography>

        <Box maxWidth={1000} mx="auto">
          <Grid container spacing={8} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6} data-testid="your-plan">
              <PlanCard
                condensed
                buttonLabel="Cancel subscription"
                onClick={isTrialActive ? handleClickCancelSubscription : undefined}
                pricePerYear={licenseDetails?.pricePerYear}
                employeeCount={licenseDetails?.employees}
                title={licenseDetails?.label}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h5" paragraph>
                Membership & billing
              </Typography>

              <Typography paragraph>{firebaseUser?.email}</Typography>

              <Typography paragraph>{getLicenseText(licenseStatus, nextBillingDate)}</Typography>

              <Typography paragraph variant="body2">
                Looking for a certificate, marketing tools?{" "}
                <MuiLink href="mailto:support@compensate.com?subject=Compensate Plus: Compensation certificate & marketing tools">
                  Shoot us an email here
                </MuiLink>
              </Typography>

              <MuiLink to="/billinginfo" component={Link}>
                Update your account details
              </MuiLink>

              <LinkButton onClick={redirectToStripePortal} loading={isLoading}>
                Update your payment method
              </LinkButton>

              <ChangeCookieHubConsent display="block" />
            </Grid>

            <Grid item xs={12}>
              <Box mx="auto" p={4} bgcolor="primary.light">
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <ToolkitImg src={positiveToolkitPreview} alt="" />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Typography variant="h4" paragraph>
                      Looking to do more for the climate?
                    </Typography>

                    <Typography paragraph>
                      Get a free copy of the Positive Net-Zero & Beyond Toolkit giving you practical steps to start your
                      Net-Zero Journey.
                    </Typography>

                    <ExternalLinkButton
                      variant="text"
                      color="primary"
                      fullWidth={false}
                      href="https://www.subscribepage.com/net-zero-and-beyond-2030"
                    >
                      Get your copy here
                    </ExternalLinkButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </IntroSection>

      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="cancellation-modal-title"
        open={isCancelModalOpen}
        PaperComponent={DialogCard}
      >
        <DialogTitle id="cancellation-modal-title">
          <Typography variant="h2" component="p" gutterBottom>
            Are you sure?
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography gutterBottom>
            If you’re sure you’d like to cancel, please confirm below. If you proceed, your subscription will be
            cancelled at the end of your free trial on {nextBillingDate} and you won't be charged.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="text" color="secondary" onClick={handleCloseModal}>
            Go back
          </Button>
          <Button
            onClick={handleConfirmCancelSubscription}
            disabled={isCancelling}
            endIcon={isCancelling && <CircularProgress />}
          >
            Cancel subscription
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Account.propTypes = {
  headerHeight: PropTypes.number,
};

export default Account;
