/**
 * Simple curried function to compare two values.
 * Mainly sugar, but useful in validators where creating a new function many times would be verbose, e.g.
 *
 *  Rather than:
 *      validate: (foo) => foo === 'bar'
 *  Do:
 *      validate: is('bar')
 */
export const is = (a) => (b) => a === b;
