export const FUEL_TYPE_OTHER = "other";

export const unitToUnitName = {
  l: "litre",
  t: "tonne",
  m3: "cubic meter",
  kWh: "kilowatt-hour",
};

export const productNameToPrettyName = {
  diesel_bio_blend: "Diesel (average biofuel blend)",
  diesel_bio: "Biodiesel",
  diesel: "Diesel (100% fossil diesel)",
  ethanol: "Ethanol (100 % ethanol)",
  gas_bio: "Biogas",
  gas_natural: "Natural gas",
  gas_natural_per_cubic_meter: "Natural gas",
  gas_natural_per_kilowatt_hour: "Natural gas",
  oil_heating: "Gasoil (heating fuel oil)",
  oil_heating_per_litre: "Gasoil (heating fuel oil)",
  oil_heating_per_kilowatt_hour: "Gasoil (heating fuel oil)",
  oil_heavy: "Heavy fuel oil",
  oil_heavy_per_litre: "Heavy fuel oil",
  oil_heavy_per_kilowatt_hour: "Heavy fuel oil",
  petrol_bio_blend: "Petrol (average biofuel blend)",
  petrol: "Petrol (100% fossil petrol)",
  petrol_ethanol_blend: "Ethanol-blended fuel",
  other: "Other",
  "energy/fuel/diesel_bio_blend": "Diesel (average biofuel blend)",
  "energy/fuel/diesel_bio": "Biodiesel",
  "energy/fuel/diesel": "Diesel (100% fossil diesel)",
  "energy/fuel/ethanol": "Ethanol (100 % ethanol)",
  "energy/fuel/gas_bio": "Biogas",
  "energy/fuel/gas_natural": "Natural gas",
  "energy/fuel/gas_natural_per_cubic_meter": "Natural gas",
  "energy/fuel/gas_natural_per_kilowatt_hour": "Natural gas",
  "energy/fuel/oil_heating": "Gasoil (heating fuel oil)",
  "energy/fuel/oil_heating_per_litre": "Gasoil (heating fuel oil)",
  "energy/fuel/oil_heating_per_kilowatt_hour": "Gasoil (heating fuel oil)",
  "energy/fuel/oil_heavy": "Heavy fuel oil",
  "energy/fuel/oil_heavy_per_litre": "Heavy fuel oil",
  "energy/fuel/oil_heavy_per_kilowatt_hour": "Heavy fuel oil",
  "energy/fuel/petrol_bio_blend": "Petrol (average biofuel blend)",
  "energy/fuel/petrol": "Petrol (100% fossil petrol)",
  "energy/fuel/petrol_ethanol_blend": "Ethanol-blended fuel",
  "energy/fuel/default": "Other",
};
