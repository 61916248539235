import { RETURN } from "../pages/calculators/flights/constants";
import { apiQuery } from "./api";
import { formatCalculationResultV2 } from "./format-calculation-result";

/**
 * fetchAirportSuggestions queries backend given a keyword to obtain
 * a list of airports that match the given keyword. The query parameter
 * must be at least 3 characters long, otherwise returns an empty promise.
 *
 * @params keyword
 * @return Array of airport data
 */
export function fetchAirportSuggestions(keyword, token) {
  if (!keyword || keyword.length < 3) {
    return {
      run: () => Promise.resolve([]),
    };
  }
  const apiRequest = {
    type: "GET",
    path: `/v1/search/airport?q=${keyword}`,
  };
  return apiQuery(apiRequest, token);
}

/**
 * calculateFlightEmissions requests an emission calculation given the parameters
 * and returns the emissions and associated compensation in monetary units
 *
 * @params values Hash with necessary data to perform calculation
 * @return Hash with a calculation from backend service
 */
export async function calculateFlightEmissions(form, token) {
  const apiRequest = {
    type: "POST",
    path: "/v2/emissions/flight",
    data: {
      origin: form.airportFrom.iata_code,
      destination: form.airportTo.iata_code,
      quantity: form.flightType === RETURN ? form.quantity * 2 : form.quantity,
      class: form.seatClass,
    },
  };

  const response = await apiQuery(apiRequest, token).run();

  return formatCalculationResultV2(form, response);
}
