import { UnpackNestedValue, useFormContext } from "react-hook-form";
import { SingleFormGeneratorProps, MultiFormGeneratorProps, FieldArrayForm } from "../form.types";
import { GENERAL_FORM_ERROR_KEY } from "../GeneralFormError";

/**
 * The form values passed to onSubmit, if fieldArray is true this will be an array of form rows
 */
type SubmitData<TForm extends object> = UnpackNestedValue<TForm> & FieldArrayForm<UnpackNestedValue<TForm>>;

/**
 * A hook to attempt submission and set errors to form state on failure
 * Uses React Hook Form context so must be a descendant of <FormProvider>
 */
function useHandleSubmit<TForm extends object>(
  onSubmit: SingleFormGeneratorProps<TForm>["onSubmit"] | MultiFormGeneratorProps<TForm>["onSubmit"],
) {
  const { handleSubmit, setError } = useFormContext();

  const handleSetError = (message: string, field = GENERAL_FORM_ERROR_KEY) => {
    setError(field, {
      type: "server",
      message,
    });
  };

  async function attemptSubmit(data: SubmitData<TForm>) {
    try {
      await onSubmit(data);
    } catch (errorOrErrors: any) {
      if (errorOrErrors instanceof Array) {
        // Multiple errors
        errorOrErrors.forEach((error) => handleSetError(error.message, error?.field));
      } else {
        handleSetError(errorOrErrors.message, errorOrErrors?.field);
      }
    }
  }

  return handleSubmit<TForm>(attemptSubmit);
}

export default useHandleSubmit;
