import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, CircularProgress, FormControlLabel, Switch, Typography, Link } from "@material-ui/core";

import { getLicenseByStripeLookupKey } from "@utils/license";
import { Button, CompensateEmailLink } from "@carbon/shared";
import useAuthStore, { cocoUserSelector, LICENSE_STATUS } from "@store/auth";
import { spacing } from "@carbon/shared";
import { centToEuro } from "@utils/unit-conversion";
import { formatMoney } from "@utils/formatting";
import { VAT_STATUS } from "@constants/vat-status";

const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  // TODO: Safari styles
  margin: ${spacing(2, 0)};
  align-items: flex-start;
  flex-grow: 1;
  width: 0;
`;

const getLabel = (amount, isTrialActive, licensePrice) => {
  const primaryText = amount > 0 ? `Compensate ${formatMoney(undefined, centToEuro(amount))} €` : "Compensate";

  if (isTrialActive) {
    return (
      <Box textAlign="left">
        <span>{primaryText}</span>
        <Typography variant="body2">and start your subscription at {licensePrice} € per year</Typography>
      </Box>
    );
  }

  return primaryText;
};

const VATCheckFailedMessage = () => (
  <Box my={2} maxWidth={400}>
    <BoldTypography color="error" variant="body2">
      Oh no, something went wrong!
    </BoldTypography>
    <Typography color="error" variant="body2">
      It seems we weren't able to verify your VAT number, please{" "}
      <Link href="mailto:support@compensate.com?subject=Compensate Plus: VAT verification failed">contact us here</Link>{" "}
      so we can get you up and running.
    </Typography>
  </Box>
);

const VATCheckPendingMessage = () => (
  <Box my={2} maxWidth={400}>
    <BoldTypography variant="body2">Your VAT number is being verified</BoldTypography>
    <Typography variant="body2">
      Before you can compensate we need to verify your VAT number, it won't take long but you can{" "}
      <CompensateEmailLink>contact us here</CompensateEmailLink> to check the progress.
    </Typography>
  </Box>
);

const SubmitCalculationsButton = ({ amount = 0, onClick }) => {
  const cocoUser = useAuthStore(cocoUserSelector);
  const [shouldStartSubscription, setShouldStartSubscription] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const license = getLicenseByStripeLookupKey(cocoUser.license?.type);
  const isTrialActive =
    cocoUser.license.license_status === LICENSE_STATUS.FREE_TRIAL ||
    cocoUser.license.license_status === LICENSE_STATUS.CANCELLED;
  const hasCalculations = amount > 0;
  const isDisabled =
    isSubmitting ||
    !hasCalculations ||
    (isTrialActive && !shouldStartSubscription) ||
    cocoUser.status !== VAT_STATUS.ACTIVE;

  const handleToggle = (ev) => setShouldStartSubscription(ev.target.checked);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await onClick();
    } finally {
      setIsSubmitting(false);
    }
  };

  const getButtonSubText = () => {
    if (cocoUser.status === VAT_STATUS.FAILED) {
      return <VATCheckFailedMessage />;
    }

    if (cocoUser.status === VAT_STATUS.PENDING) {
      return <VATCheckPendingMessage />;
    }

    if (hasCalculations && isTrialActive) {
      return (
        <Box display="flex">
          <StyledFormControlLabel
            label={
              <Box ml={2}>
                <BoldTypography variant="body2">Start your subscription to compensate</BoldTypography>
                <Typography variant="body2">
                  To make a compensation payment, upgrade your free trial to a subscription. Your trial will end and you
                  will be charged the {license.label} subscription fee of {license.pricePerYear} € per year.
                </Typography>
              </Box>
            }
            control={<Switch checked={shouldStartSubscription} onChange={handleToggle} name="toggle-subscription" />}
          />
        </Box>
      );
    }
  };

  return (
    <Box display="inline-block">
      <Button
        onClick={handleSubmit}
        withArrow
        disabled={isDisabled}
        endIcon={isSubmitting && <CircularProgress />}
        data-testid="submit-compensation"
      >
        {getLabel(amount, isTrialActive, license.pricePerYear)}
      </Button>

      {getButtonSubText()}
    </Box>
  );
};

SubmitCalculationsButton.propTypes = {
  /** Compensation amount in cents */
  amount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default SubmitCalculationsButton;
