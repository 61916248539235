import React from "react";
import { centsToEuros } from "@carbon/shared";

import { TCO2E } from "../../../components/common/Units";
import { formatCO2Emission, formatDate } from "../../../utils/formatting";
import { gramToTon } from "../../../utils/unit-conversion";
import { SummaryTableColumn } from "../../../types";

import { EmployeesForm } from "./employees.types";

const SUMMARY_TABLE_COLUMNS: SummaryTableColumn<EmployeesForm>[] = [
  { label: "Employees' location", getCell: "form.country.label" },

  { label: "Number of employees", getCell: "form.numberOfEmployees" },

  {
    label: "Reporting period",
    getCell: (item) => `${formatDate(item.form.start)} - ${formatDate(item.form.end)}`,
  },

  {
    label: "Total Emissions",
    getCell(item) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(item.result.emissions.usage))} <TCO2E />
        </>
      );
    },
    getTotal(summary) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(summary.emissions))} <TCO2E />
        </>
      );
    },
  },

  {
    label: "Compensation",
    getCell: (item) => centsToEuros(item.result.value.amount),
    getTotal: (summary) => centsToEuros(summary.price),
  },
];

export default SUMMARY_TABLE_COLUMNS;
