import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

/**
 * A simple component that composes Box to easily create consistent section wrappers with a frame.
 * To be used at the top level of a page, as shown in the designs.
 */
const Frame = ({ children, ...props }) => (
  <Box m={[0, 3, 5]} {...props}>
    {children}
  </Box>
);

Frame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Frame;
