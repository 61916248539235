import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import subYears from "date-fns/subYears";

export type DatePresetOption =
  | "today"
  | "last-7-days"
  | "this-month"
  | "last-month"
  | "three-years-ago"
  | "two-years-ago"
  | "last-year"
  | "this-year"
  | "all-time"
  | "custom";

export class DateRange {
  public start: Date | null = null;
  public end: Date | null = null;
}

export interface PresetRange {
  value: DatePresetOption;
  label: string;
  getRange: () => DateRange;
}

const PRESET_TODAY: PresetRange = {
  value: "today",
  label: "Today",
  getRange() {
    return { start: startOfDay(new Date()), end: null };
  },
};

const PRESET_THIS_MONTH: PresetRange = {
  value: "this-month",
  label: "This month",
  getRange() {
    return { start: startOfMonth(new Date()), end: null };
  },
};

const PRESET_LAST_7_DAYS: PresetRange = {
  value: "last-7-days",
  label: "Last 7 days",
  getRange() {
    return { start: subDays(new Date(), 7), end: null };
  },
};

const PRESET_THREE_YEARS_AGO: PresetRange = {
  value: "three-years-ago",
  label: `${new Date().getFullYear() - 3}`,
  getRange() {
    const yearAgo = subYears(Date.now(), 3);

    return { start: startOfYear(yearAgo), end: endOfYear(yearAgo) };
  },
};

const PRESET_TWO_YEARS_AGO: PresetRange = {
  value: "two-years-ago",
  label: `${new Date().getFullYear() - 2}`,
  getRange() {
    const yearAgo = subYears(Date.now(), 2);

    return { start: startOfYear(yearAgo), end: endOfYear(yearAgo) };
  },
};

const PRESET_LAST_YEAR: PresetRange = {
  value: "last-year",
  label: `${new Date().getFullYear() - 1}`,
  getRange() {
    const yearAgo = subYears(Date.now(), 1);

    return { start: startOfYear(yearAgo), end: endOfYear(yearAgo) };
  },
};

const PRESET_THIS_YEAR: PresetRange = {
  value: "this-year",
  label: `${new Date().getFullYear()}`,
  getRange() {
    return { start: startOfYear(new Date()), end: null };
  },
};

const PRESET_LAST_MONTH: PresetRange = {
  value: "last-month",
  label: "Last month",
  getRange() {
    const lastMonth = subMonths(new Date(), 1);

    return { start: startOfMonth(lastMonth), end: endOfMonth(lastMonth) };
  },
};

const PRESET_ALL_TIME: PresetRange = {
  value: "all-time",
  label: "All time",
  getRange() {
    return { start: null, end: null };
  },
};

export const YEAR_PRESET_RANGES: PresetRange[] = [
  PRESET_ALL_TIME,
  PRESET_THIS_YEAR,
  PRESET_LAST_YEAR,
  PRESET_TWO_YEARS_AGO,
  PRESET_THREE_YEARS_AGO,
];

export const DATE_PRESET_RANGES: PresetRange[] = [
  PRESET_TODAY,
  PRESET_LAST_7_DAYS,
  PRESET_THIS_MONTH,
  PRESET_LAST_MONTH,
  PRESET_THIS_YEAR,
  PRESET_ALL_TIME,
];
