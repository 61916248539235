export const LICENSE_LOCAL_STORAGE_KEY = "selected-license";

export const LICENSE_OPTIONS = [
  {
    id: "free",
    stripeLookupKey: "free",
    label: "Free",
    employees: 999,
    pricePerYear: 0,
  },
  {
    id: "freelancer",
    stripeLookupKey: "freelancer",
    label: "Freelancer",
    employees: 10,
    pricePerYear: 120,
  },
  {
    id: "small-business",
    stripeLookupKey: "small business",
    label: "Small",
    employees: 50,
    pricePerYear: 360,
  },
  {
    id: "medium-business",
    stripeLookupKey: "medium business",
    label: "Medium",
    employees: 100,
    pricePerYear: 960,
  },
  {
    id: "large-business",
    stripeLookupKey: "large business",
    label: "Large",
    employees: 200,
    pricePerYear: 1440,
  },
  // New options for simplified license pricing
  {
    id: "small",
    stripeLookupKey: "small",
    label: "Small",
    employees: 50,
    pricePerYear: 120,
  },
  {
    id: "medium",
    stripeLookupKey: "medium",
    label: "Medium",
    employees: 200,
    pricePerYear: 468,
  },
];

export const LICENSES = LICENSE_OPTIONS.slice(-2);
