import * as yup from "yup";
import { message } from "@carbon/shared";

export const requiredNumber = (humanReadableName: string) =>
  yup
    .number()
    .nullable(true)
    .transform((_, value) => {
      if (value && typeof value === "string") {
        // Transform localized fi-FI strings to numbers. TODO: When localization is implemented this should be more generic
        return Number(value.replace(/\s/g, "").replace(/,/g, "."));
      }

      if (value === null) {
        return undefined;
      }

      return value;
    })
    .typeError(message.mustBeNumber(humanReadableName))
    .required(message.isRequired(humanReadableName));

export const requiredOption = (humanReadableName: string) =>
  yup
    /**
     * workaround to fix a yup type error bug https://github.com/jquense/yup/issues/1247
     * when this is resolved `value` and `label` can be replaced by `yup.string().required()`
     */
    .object({ value: new yup.StringSchema<string>().required(), label: new yup.StringSchema<string>().required() })
    .nullable()
    .required(message.isRequired(humanReadableName));

export const requiredDate = (humanReadableName: string) =>
  yup.date().nullable().typeError(message.invalidDateFormat).required(message.isRequired(humanReadableName));

export const requiredEndDate = (humanReadableName: string, startDateName: string) =>
  yup
    .date()
    .nullable()
    .min(yup.ref(startDateName), "This should be later than the start date")
    .typeError(message.invalidDateFormat)
    .required(message.isRequired(humanReadableName));
