import { CalculationResponse, CalculationResponseV2, CalculationResult } from "../types";
import { getCurrencySymbol } from "../utils/formatting";

function formatCalculationResult<TForm>(form: TForm, response: CalculationResponse): CalculationResult<TForm> {
  const usage = Number(response.emissions.usage);
  const upstream = Number(response.emissions.upstream);

  return {
    id: response.id,

    form,

    result: {
      emissions: {
        usage,
        upstream,
        total: usage + upstream,
      },

      value: {
        amount: Number(response.value.amount),
        currency: response.value.currency,
        currencySymbol: getCurrencySymbol(response.value.currency),
      },
    },
  };
}

export function formatCalculationResultV2<TForm>(
  form: TForm,
  response: CalculationResponseV2,
): CalculationResult<TForm> {
  return {
    id: response.id,

    form,

    result: {
      emissions: {
        usage: response.emissions.usage,
        upstream: response.emissions.upstream,
        total: response.emissions.usage + response.emissions.upstream,
      },

      value: {
        amount: Number(response.value),
        currency: "EUR",
        currencySymbol: "€",
      },
    },
  };
}

export default formatCalculationResult;
