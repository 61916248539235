import React from "react";
import { TCO2E } from "@components/common/Units";
import { formatCO2Emission, formatMoney } from "@utils/formatting";
import { centToEuro, gramToTon } from "@utils/unit-conversion";

export const BASIC_INFORMATION_ERROR_MESSAGE = "Please fill in the Basic Information.";
export const COPY_TEXT_FOR_THIS_SPACE = "Yes, for this space";
export const COPY_TEXT_IDK_USE_ESTIMATE = "I don’t know, let’s use an estimate";
export const COPY_TEXT_WHOLE_BUILDING = "Yes, for the whole building (this space is part of a larger building)";
export const COPY_TEXT_USE_ESTIMATE = "No, let’s use an estimate";
export const FOR_REPORTED_SPACE = "FOR_REPORTED_SPACE";
export const FOR_WHOLE_BUILDING = "FOR_WHOLE_BUILDING";
export const USE_GENERAL_ESTIMATE = "USE_GENERAL_ESTIMATE";
export const COUNTRY_AVERAGE = "Country average";
export const RENEWABLE = "FI/renewable";
export const SEPARATE_POWER_PLANT = "SEPARATE_POWER_PLANT";
export const WITH_KNOWN_EMISSION_FACTOR = "WITH_KNOWN_EMISSION_FACTOR";
export const DEFAULT_FACTOR = "default";

export const SUMMARY_TABLE_COLUMNS = [
  { label: "Title", getCell: "nameOfSpace" },

  { label: "Energy type", getCell: "energyType" },

  { label: "Type", getCell: "type" },

  {
    label: "Usage",
    getCell(row) {
      return (
        <>
          {Math.round(row.usage).toLocaleString("fi")} {row.unit || "kWh"}
        </>
      );
    },
  },

  {
    label: "Usage emissions",
    getCell(row) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(row.result.emissions.usage))} <TCO2E />
        </>
      );
    },
    getTotal(summary) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(summary.usageEmissions))} <TCO2E />
        </>
      );
    },
  },

  {
    label: "Upstream emissions",
    getCell(row) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(row.result.emissions.upstream))} <TCO2E />
        </>
      );
    },
    getTotal(summary) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(summary.upstreamEmissions))} <TCO2E />
        </>
      );
    },
  },

  {
    label: "Total emissions",
    getCell(row) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(row.result.emissions.total))} <TCO2E />
        </>
      );
    },
    getTotal(summary) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(summary.emissions))} <TCO2E />
        </>
      );
    },
  },

  {
    label: "Compensation",
    getCell: (row) =>
      `${formatMoney(undefined, centToEuro(row.result.value.amount))} ${row.result.value.currencySymbol}`,
    getTotal: (summary) => `${formatMoney(undefined, centToEuro(summary.price))} ${summary.currencySymbol}`,
  },
];
