import React from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { Box, Typography, Link as MuiLink, Grid } from "@material-ui/core";
import styled from "styled-components";
import {
  CompensateIcon,
  palette,
  Section,
  Button,
  spacing,
  getStaticAsset,
  FAQ_COCO_LINK,
  CompensateEmailLink,
} from "@carbon/shared";

import { ReactComponent as Arrow } from "@assets/icons/arrow-right.svg";
import useAuthStore, { isSignedIn, statusSelector } from "@store/auth";
import AutoplayVideo from "../../components/common/AutoplayVideo";
import DemoSection from "../../components/common/DemoSection";
import PartnersSection from "./PartnersSection";
import PackagesSection from "./packages-section/PackagesSection";

const StyledImg = styled.img`
  width: 100%;
`;

const IntroSection = styled(Section)`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  min-height: calc(100vh - ${(props) => props.$headerHeight}px);
  margin-top: -${(props) => props.$headerHeight}px;
  background-color: ${palette("primary.light")};
  padding-top: ${({ $headerHeight }) => `${$headerHeight}px`};
`;

const IntroGrid = styled(Grid)`
  position: relative;
  margin-top: ${spacing(2)}px;
`;

const DesktopVideoWrapper = styled.div`
  width: 90%;
`;

const MobileVideoWrapper = styled.div`
  position: absolute;

  max-width: 180px;
  width: 30%;
  bottom: -10%;
  right: 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 25%;
    bottom: 0;
  }
`;

const IntroVideosGrid = styled(Grid)`
  position: relative;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: -1;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const Unbold = styled(Typography)`
  font-weight: 400;
`;

const FrontPage = ({ headerHeight }) => {
  const signedIn = isSignedIn(useAuthStore(statusSelector));

  if (signedIn) {
    return <Redirect to="/emission-calculators" />;
  }

  return (
    <>
      <IntroSection $headerHeight={headerHeight}>
        <IntroGrid container justifyContent="center" alignItems="center" spacing={6}>
          <Grid item xs={12} md={5} xl={3}>
            <Typography component="h1" variant="h3" paragraph>
              Calculate and compensate your business' carbon footprint
            </Typography>
            <Unbold variant="h5" paragraph>
              Ready to take climate action? Easily calculate and offset your carbon footprint with our fully
              customizable, self-service tool
            </Unbold>
            <Button component={Link} to="/signin" withArrow>
              Sign in
            </Button>
          </Grid>

          <IntroVideosGrid item xs={12} sm={11} md={7} lg={6} xl={4}>
            <DesktopVideoWrapper>
              <AutoplayVideo
                aspectRatio={1048 / 1360}
                frame="desktop"
                poster={getStaticAsset("/images/demo-poster-realestate.png")}
                sources={[
                  {
                    src: getStaticAsset("/videos/demo-realestate-av1.mp4"),
                    type: "video/mp4",
                  },
                  {
                    src: getStaticAsset("/videos/demo-realestate-h264.mp4"),
                    type: "video/mp4",
                  },
                  {
                    src: getStaticAsset("/videos/demo-realestate.webm"),
                    type: "video/webm",
                  },
                ]}
              />
            </DesktopVideoWrapper>

            <MobileVideoWrapper>
              <AutoplayVideo
                aspectRatio={1334 / 750}
                frame="mobile"
                poster={getStaticAsset("/images/demo-poster-calculators.png")}
                sources={[
                  {
                    src: getStaticAsset("/videos/demo-calculators-av1.mp4"),
                    type: "video/mp4",
                  },
                  {
                    src: getStaticAsset("/videos/demo-calculators-h264.mp4"),
                    type: "video/mp4",
                  },
                  {
                    src: getStaticAsset("/videos/demo-calculators.webm"),
                    type: "video/webm",
                  },
                ]}
              />
            </MobileVideoWrapper>
          </IntroVideosGrid>
        </IntroGrid>
      </IntroSection>

      <PartnersSection />

      <PackagesSection />

      <Section bgcolor="primary.extraLight">
        <DemoSection
          title="Calculate your company's carbon emissions"
          body="With multiple emission calculators to choose from, you are in control of which areas you track and how.
          Easily calculate your emissions on the go daily, weekly, monthly or yearly, without expensive consultants.
          For example, use your electricity bills to calculate your office's carbon footprint, or your travel
          emissions as you process taxi receipts – whatever suits your budget and timeline best."
          preview={
            <AutoplayVideo
              sources={[
                {
                  src: getStaticAsset("/videos/demo-flights-av1.mp4"),
                  type: "video/mp4",
                },
                {
                  src: getStaticAsset("/videos/demo-flights-h264.mp4"),
                  type: "video/mp4",
                },
                {
                  src: getStaticAsset("/videos/demo-flights.webm"),
                  type: "video/webm",
                },
              ]}
            />
          }
        />
      </Section>

      <Section bgcolor="gray.light">
        <DemoSection
          title="Compensate your climate impact"
          body="Offset your carbon footprint through our carefully curated project portfolio. Our industry-leading
          Scientific Advisory Panel and project criteria help us pick the best available projects for true climate
          impact. And with built-in overcompensation, you are always removing more carbon from the atmosphere than
          your activities cause. Our portfolio also supports all 17 UN Sustainable Development Goals."
          reverse
          preview={
            <AutoplayVideo
              sources={[
                {
                  src: getStaticAsset("/videos/demo-compensation-av1.mp4"),
                  type: "video/mp4",
                },
                {
                  src: getStaticAsset("/videos/demo-compensation-h264.mp4"),
                  type: "video/mp4",
                },
                {
                  src: getStaticAsset("/videos/demo-compensation.webm"),
                  type: "video/webm",
                },
              ]}
            />
          }
        />
      </Section>

      <Section bgcolor="primary.extraLight">
        <DemoSection
          title="Communicate your journey to carbon negative"
          body="See the impact your offsetting has in easy-to-understand terms and numbers in our partner dashboard.
          Showcase your compensation certificate and use our ready-made communications templates to tell your
          customers, talent and other stakeholders about your climate efforts."
          preview={<StyledImg src={getStaticAsset("/images/reports-teaser.png")} alt="" />}
        />
      </Section>

      <Section textAlign="center">
        <CompensateIcon icon="message" />
        <Typography variant="h2" gutterBottom>
          Want to talk to our team?
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Please reach out to <CompensateEmailLink username="sales" />
        </Typography>

        <MuiLink href={FAQ_COCO_LINK} target="_blank" rel="noopener" variant="h5">
          <Box component={Arrow} mr={2} mb="-4px" /> Frequently asked questions
        </MuiLink>
      </Section>
    </>
  );
};

FrontPage.propTypes = {
  headerHeight: PropTypes.number,
};

export default FrontPage;
