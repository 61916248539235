import React from "react";

import { TCO2E } from "@components/common/Units";
import { centToEuro, gramToTon } from "@utils/unit-conversion";
import { formatCO2Emission, formatMoney } from "@utils/formatting";

export const getCompensationCell = (row) =>
  `${formatMoney(undefined, centToEuro(row.result.value.amount))} ${row.result.value.currencySymbol}`;

export const getCompensationSummary = (summary) =>
  `${formatMoney(undefined, centToEuro(summary.price))} ${summary.currencySymbol}`;

export const getTotalEmissionsCell = (row) => (
  <>
    {formatCO2Emission(undefined, gramToTon(row.result.emissions.total))} <TCO2E />
  </>
);

export const getTotalEmissionsSummary = (summary) => (
  <>
    {formatCO2Emission(undefined, gramToTon(summary.emissions))} <TCO2E />
  </>
);
