import React from "react";
import instructionsImg from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { CO2 } from "@components/common/Units";
import BillingInstruction from "@components/common/BillingInstruction";

const EmployeesInstructions = () => (
  <Instructions
    title="How this works"
    imageOne={instructionsImg}
    listItems={[
      "Use Compensate’s personal carbon footprint calculator to figure out the average carbon footprint of an individual person and how much offsetting it costs!",
      "Choose the number of people you want to compensate on behalf of and the country they live in to estimate their carbon footprint for the chosen time period.",
      "The calculations use country averages for the carbon footprints. The averages include emissions from living such as consumption of energy, transportation, food and all the other goods and services that require energy or material inputs.",
      <>
        “The Compensation impact” refers to the number of trees required to sequester the amount of <CO2 />
        emissions in a year. This gives you an idea of how many trees are needed to compensate for the emissions created
        by your individual carbon footprint.
      </>,
      "Compensate's unique overcompensation is already built into the calculator. It ensures actual climate impact and tackles the risks involved in all carbon capture projects.",
      <BillingInstruction key="billing-instruction" />,
    ]}
  />
);

export default EmployeesInstructions;
