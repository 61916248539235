const GROUPS = {
  PRODUCTS: "Products",
  TRAVEL: "Travel & transportation",
  SERVICES: "Services & entertainment",
};

const INDEX_CATEGORIES = {
  /** DIGITAL PRODUCTS */
  digital: [
    { value: "", label: "–" },
    { value: "purchases_and_goods/big_purchases/small_electronics/laptop", label: "Laptop" },
    { value: "purchases_and_goods/big_purchases/small_electronics/mobile_phone", label: "Mobile phone" },
    { value: "purchases_and_goods/big_purchases/small_electronics/tablet", label: "Tablet" },
    { value: "purchases_and_goods/big_purchases/small_electronics/monitor", label: "Monitor" },
    { value: "purchases_and_goods/big_purchases/small_electronics/tv", label: "TV" },
    { value: "purchases_and_goods/big_purchases/small_electronics/desktop", label: "Desktop computer" },
  ],

  /** PRODUCTS */
  products: [
    { value: "", label: "–" },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods/foods_and_beverages",
      label: "Food & drinks",
      searchTerms: ["refreshments", "office"],
    },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods/small_purchases/non_daily",
      label: "Office supplies (e.g. stationery)",
      searchTerms: ["pens", "pencils", "post-it notes", "paper"],
    },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods/small_purchases/non_daily/books_magazines_newspapers",
      label: "Books, magazines & newspapers",
    },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods/big_purchases/small_electronics",
      label: "Electronics for office use (e.g. laptops)",
      searchTerms: ["laptops", "printers", "mobiles", "phones", "screens", "monitors", "desktops", "computers"],
    },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods/big_purchases/furniture",
      label: "Furniture",
      searchTerms: ["sofas", "chairs", "tables", "desks"],
    },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods/clothing_and_apparel",
      label: "Textiles",
      searchTerms: ["curtains", "fabrics", "clothing", "clothes"],
    },
    {
      groupBy: GROUPS.PRODUCTS,
      value: "index/purchases_and_goods",
      label: "Other purchases",
    },
  ],

  /** TRAVEL */
  travel: [
    { value: "", label: "–" },
    {
      groupBy: GROUPS.TRAVEL,
      value: "index/transportation/public",
      label: "Public transportation",
      searchTerms: ["bus", "train", "metro", "ferry", "monorail"],
    },
    {
      groupBy: GROUPS.TRAVEL,
      value: "index/transportation/taxi",
      label: "Taxi and Uber",
    },
    {
      groupBy: GROUPS.TRAVEL,
      value: "index/travel/flights",
      label: "Flights, all distances",
      searchTerms: ["plane", "flying"],
    },
    {
      groupBy: GROUPS.TRAVEL,
      value: "index/travel/accommodation",
      label: "Hotels",
      searchTerms: ["airbnb", "accommodation"],
    },
  ],

  /** SERVICES */
  services: [
    { value: "", label: "–" },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/social_and_entertainment/eating_and_drinking",
      label: "Events (meals and activities)",
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/housing/services/cleaning",
      label: "Cleaning services",
      searchTerms: ["cleaner"],
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/housing/services/laundry",
      label: "Laundry services",
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/housing/services/renovation",
      label: "Renovation",
      searchTerms: ["painters", "builders"],
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/housing/utilities/telecom",
      label: "Telecom services (e.g. internet & mobile connection)",
      searchTerms: ["phones", "wifi", "broadband"],
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/services/other/printing",
      label: "Printing services",
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/services/health_and_wellness/healthcare",
      label: "Healthcare services",
      searchTerms: ["medical"],
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/services/other/banking_and_insurance",
      label: "Banking and insurance services",
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/services/other/postal",
      label: "Postal services",
      searchTerms: ["posti", "packages", "delivery"],
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/supporting_services/infrastructure/data_centers",
      label: "Data centres for online services",
      searchTerms: ["hosting"],
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/supporting_services/infrastructure/waste",
      label: "Waste services",
    },
    {
      groupBy: GROUPS.SERVICES,
      value: "index/services/other",
      label: "Other services",
    },
  ],
};

export const FLAT_INDEX_CATEGORIES = [
  ...INDEX_CATEGORIES.digital,
  ...INDEX_CATEGORIES.products,
  ...INDEX_CATEGORIES.services,
  ...INDEX_CATEGORIES.travel,
];

export default INDEX_CATEGORIES;
