import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller } from "react-hook-form";

import FIELD_PROP_TYPES from "../constants/field-prop-types";

/**
 * Note: By default MuiDatePicker pre-selects todays date on first render.
 *       To avoid this, set the default value of the form config to null.
 */
const DatePicker = ({ defaultValue = null, fieldProps, error, required, name, label }) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    render={({ onChange, onBlur, value, name, ref }) => (
      <KeyboardDatePicker
        id={name}
        name={name}
        inputRef={ref}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={required ? `${label} *` : label}
        error={Boolean(error)}
        helperText={error?.message}
        variant="inline"
        autoOk
        disableToolbar
        format="dd.MM.yyyy"
        KeyboardButtonProps={{
          size: "small",
          edge: "end",
          "aria-label": "change date",
        }}
        {...fieldProps}
      />
    )}
  />
);

DatePicker.propTypes = FIELD_PROP_TYPES;

export default DatePicker;
