import logo from "@assets/img/compensate-logo-black.svg";
import logoWhite from "@assets/img/compensate-logo-white.svg";
import { palette, spacing } from "@carbon/shared";
import { AppBar, Box, Button, Fade, useScrollTrigger } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import useAuthStore, { isSignedIn, statusSelector } from "@store/auth";
import useNavigationStore from "@store/navigation";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import SignInOrOut from "./SignInOrOut";

const Logo = styled.img`
  ${({ theme }) => `
    height: 46px;
    width: 46px;
    overflow: hidden;
    object-fit: cover;
    object-position: left;

    ${theme.breakpoints.up("sm")} {
      height: 90px;
      width: 90px
    }

    ${theme.breakpoints.up("md")} {
      width: auto;
    }
  `}
`;

const FlexWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSignInOrOut = styled(SignInOrOut)`
  font-weight: 400;
  margin-left: auto;
  margin-right: 1rem;
`;

const FloatingBurger = styled.div`
  display: flex;
  position: fixed;
  top: ${spacing(3)}px;
  right: ${spacing(3)}px;
  justify-content: center;
  background-color: ${palette("background.default")};
  border-radius: 80px;
  color: ${palette("text.main")};
  align-items: center;
  width: 60px;
  height: 60px;
  z-index: ${({ theme }) => theme.zIndex.appBar};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    top: ${spacing(4)}px;
    right: ${spacing(8)}px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    right: ${spacing(10)}px;
  }
`;

const Header = forwardRef(({ light = false }, ref) => {
  const openNav = useNavigationStore((state) => state.openNav);
  const signedIn = isSignedIn(useAuthStore(statusSelector));
  const isScrollingDown = useScrollTrigger({ threshold: 50 });
  const location = useLocation();

  const [isScrolledPastHeader, setIsScrolledPastHeader] = useState(false);
  const burgerRef = useRef();

  const referer = location?.state?.referer?.pathname ?? "/";
  const showStickyBurgerMenu = !isScrollingDown && isScrolledPastHeader;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolledPastHeader(burgerRef.current?.getBoundingClientRect().bottom < 0);
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const burgerMenu = (
    <Button variant="text" color="inherit" aria-label="open main menu" onClick={openNav}>
      <MenuIcon fontSize="large" />
    </Button>
  );

  return (
    <>
      <AppBar ref={ref} color="transparent" position="relative">
        <FlexWrapper color={light ? "text.light" : "text.primary"} py={[3, 4]} px={[3, 5]}>
          <NavLink to="/">
            <Logo alt="Compensate logo" src={light ? logoWhite : logo} />
          </NavLink>

          <StyledSignInOrOut isSignedIn={signedIn} referer={referer} />

          <div ref={burgerRef}>{burgerMenu}</div>
        </FlexWrapper>
      </AppBar>

      <Fade appear={false} direction="down" in={showStickyBurgerMenu} mountOnEnter unmountOnExit>
        <FloatingBurger>{burgerMenu}</FloatingBurger>
      </Fade>
    </>
  );
});

Header.propTypes = {
  light: PropTypes.bool,
};

export default Header;
