import React from "react";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  GridSize,
  CardProps,
  Fade,
  Box,
  Typography,
  TypographyVariant,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import styled, { css } from "styled-components";

import { palette } from "../theme";
import { skeletonShimmerStyles } from "./SkeletonLoader";

type Size = "small" | "default";

interface Props extends CardProps {
  /** Display card actions (e.g. to toggle between datasets) at the top right corner of the card */
  actions?: React.ReactNode;
  loading?: boolean;
  title?: string;
  width?: CardWidth;
  size?: Size;
  bgcolor?: string;
  glass?: boolean;
}

type CardWidth = "full" | "half" | "third" | "quarter";

const CARD_WITDH_MAP: Record<CardWidth, Partial<Record<Breakpoint, GridSize>>> = {
  full: { xs: 12 },
  half: { xs: 12, md: 6 },
  third: { xs: 12, md: 4 },
  quarter: { xs: 12, md: 6, lg: 3 },
};

const getGridWidthProps = (width?: CardWidth) =>
  width && CARD_WITDH_MAP[width] ? CARD_WITDH_MAP[width] : CARD_WITDH_MAP.full;

const StyledCard = styled((props: { $loading?: boolean; $bgcolor?: string; $glass?: boolean } & CardProps) => (
  <MuiCard {...props} />
))`
  transition: all 0.2s;
  background-color: ${({ $bgcolor }) => palette($bgcolor || "primary.extraLight")};
  width: 100%;
  height: 100%;
  min-height: 100px;
  border-radius: 4px;

  ${({ $glass }) =>
    $glass &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
      color: ${palette("text.light")};
      backdrop-filter: blur(10px);
      box-shadow: 0px 15px 40px -20px rgba(0, 0, 0, 0.1);
    `}

  ${({ $loading }) => $loading && skeletonShimmerStyles};
`;

interface CardStatProps {
  children?: React.ReactNode;
  variant?: TypographyVariant;
}

export const CardStat = ({ children, variant = "h1" }: CardStatProps) => (
  <Typography variant={variant} component="p">
    {children != null && children !== false ? (
      <Fade in>
        <span>{children}</span>
      </Fade>
    ) : (
      // Render empty space to enforce paragraph height to not be zero
      <>&#8203;</>
    )}
  </Typography>
);

const getVariant = (size: Size) => (size === "small" ? "h6" : "h5");

const Card = ({ loading, title, width, children, size = "default", bgcolor, glass, actions, ...props }: Props) => {
  const styledTitle = title && (
    <Typography variant={getVariant(size)} component="h2">
      {title}
    </Typography>
  );

  return (
    <Grid item {...getGridWidthProps(width)}>
      <StyledCard $loading={loading} $bgcolor={bgcolor} $glass={glass} {...props}>
        <Fade in={true}>
          <CardContent>
            {!!styledTitle && !!actions ? (
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Box my={1}>{styledTitle}</Box>
                <Box my={1}>{actions}</Box>
              </Box>
            ) : (
              <>
                {styledTitle}
                {actions}
              </>
            )}
            {children}
          </CardContent>
        </Fade>
      </StyledCard>
    </Grid>
  );
};

export default Card;
