import React from "react";
import PropTypes from "prop-types";
import { Link, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import { spacing, IntroSection, STRIPE_PRICING_LINK, CompensateEmailLink } from "@carbon/shared";
import Licenses from "@components/layout/Licenses";
import ArrowList from "../../components/common/ArrowList";
import PartnersSection from "../frontpage/PartnersSection";

const CenteredTypography = styled(Typography)`
  text-align: center;
`;

const BulletListGridItem = styled(Grid)`
  margin-top: ${spacing(5)}px;
`;

const LicensingPage = ({ headerHeight }) => {
  return (
    <>
      <IntroSection headerHeight={headerHeight} bgcolor="primary.extraLight">
        <CenteredTypography component="h1" variant="h2" gutterBottom>
          Choose the plan that's right for you
        </CenteredTypography>

        <Licenses>
          <BulletListGridItem item xs={12} lg={8}>
            <ArrowList
              items={[
                <>
                  Compensate uses{" "}
                  <Link href={STRIPE_PRICING_LINK} target="_blank" rel="noopener">
                    Stripe
                  </Link>{" "}
                  as a payment service provider. You can cancel your free trial at any time and won't be charged.
                </>,
                "If applicable, VAT will be added to the subscription.",
                "“Employees” means the number of people working full-time for your business at the date of registration.",
                <>
                  Have more than 200 employees? Need an enterprise solution?{" "}
                  <CompensateEmailLink username="info">Contact our team to get started!</CompensateEmailLink>
                </>,
                "Compensate takes no cuts from your compensation payments. The subscription fee enables us to provide this service to you.",
              ]}
            />
          </BulletListGridItem>
        </Licenses>
      </IntroSection>
      <PartnersSection />
    </>
  );
};

LicensingPage.propTypes = {
  headerHeight: PropTypes.number,
};

export default LicensingPage;
