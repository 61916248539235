import React from "react";
import * as yup from "yup";

import { is } from "@utils/comparison";
import { requiredMessage } from "@components/form/utils/form-messages";
import { Dropdown, NumberFieldWithAdornment } from "@components/form";
import { KILOMETERS } from "@constants/distance";

import { TRANSPORTATION_TYPE, SEA_CARGO_TYPE_OPTIONS, DISTANCE_UNIT_OPTIONS } from "./constants";
import NumberFieldWithDynamicUnit from "./components/NumberFieldWithDynamicUnit";

export const isSeaTransportation = is(TRANSPORTATION_TYPE.SEA);

export const SEA_FORM_SCHEMA = {
  cargoType: yup.string().when("transportationType", {
    is: isSeaTransportation,
    then: yup.string().required(requiredMessage("Cargo type")),
  }),

  distanceUnit: yup.string().when("transportationType", {
    is: isSeaTransportation,
    then: yup.string().required(requiredMessage("Unit")),
  }),
};

export const SEA_FORM_DEFAULTS = {
  cargoType: "",
  distanceUnit: KILOMETERS,
};

export const SEA_FORM_FIELDS = [
  {
    render: Dropdown,
    name: "cargoType",
    label: "Cargo type",
    required: true,
    options: SEA_CARGO_TYPE_OPTIONS,
    fieldProps: {
      helperText: "If exact information is unavailable, select 'Container ship'",
    },
    dependsOn: {
      name: "transportationType",
      condition: isSeaTransportation,
    },
  },

  {
    render(props) {
      return <NumberFieldWithDynamicUnit unitFieldName="distanceUnit" {...props} />;
    },
    name: "distance",
    label: "Transported distance",
    required: true,
    dependsOn: {
      name: "transportationType",
      condition: isSeaTransportation,
    },
    fieldProps: {
      helperText: "Select either nautical miles [NM] or kilometres [km]",
    },
    newRow: true,
    wrapperProps: { xs: 8, md: 6 },
  },

  {
    render: Dropdown,
    name: "distanceUnit",
    label: "Unit",
    required: true,
    options: DISTANCE_UNIT_OPTIONS,
    dependsOn: {
      name: "transportationType",
      condition: isSeaTransportation,
    },
    wrapperProps: { xs: 4, md: 2 },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="kg" digits={2} {...props} />;
    },
    name: "weight",
    label: "Weight of the goods/cargo",
    required: true,
    fieldProps: {
      helperText: "We automatically add 10% to include secondary packaging",
    },
    dependsOn: {
      name: "transportationType",
      condition: isSeaTransportation,
    },
    newRow: true,
    wrapperProps: { xs: 12, md: 8 },
  },
];
