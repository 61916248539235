import React from "react";
import { centToEuro, gramToTon } from "@utils/unit-conversion";
import { formatCO2Emission, formatMoney, formatDate } from "@utils/formatting";
import { TCO2E } from "@components/common/Units";

export const ONE_WAY = "one-way";

export const RETURN = "return";

export const FLIGHT_TYPES = [
  { label: "One way", value: ONE_WAY },
  { label: "Return", value: RETURN },
];

export const SEAT_TYPES = [
  { value: "average", label: "Average" },
  { value: "economy", label: "Economy" },
  { value: "business", label: "Business" },
  { value: "first", label: "First" },
];

const seatTypeLabelForValue = (seatTypeValue) => SEAT_TYPES.find((seatType) => seatType.value === seatTypeValue)?.label;

export const SUMMARY_TABLE_COLUMNS = [
  { label: "From - To", getCell: (item) => `${item.form.airportFrom.iata_code} - ${item.form.airportTo.iata_code}` },
  { label: "Quantity", getCell: "form.quantity" },
  { label: "Flight type", getCell: (item) => (item.form.flightType === RETURN ? "Return" : "One way") },
  { label: "Class", getCell: (item) => seatTypeLabelForValue(item.form.seatClass) },
  { label: "Date", getCell: (item) => formatDate(item.form.date) },
  {
    label: "Total Emissions",
    getCell(item) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(item.result.emissions.usage))} <TCO2E />{" "}
        </>
      );
    },
    getTotal(summary) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(summary.emissions))} <TCO2E />
        </>
      );
    },
  },
  {
    label: "Compensation",
    getCell: (item) =>
      `${formatMoney(undefined, centToEuro(item.result.value.amount))} ${item.result.value.currencySymbol}`,
    getTotal: (summary) => `${formatMoney(undefined, centToEuro(summary.price))} ${summary.currencySymbol}`,
  },
];
