/**
 * Format euros as a localized string
 */
export const formatEuros = (euros: number, locale = "fi"): string => {
  if (typeof euros !== "number") {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.warn("formatEuros received an invalid value for `euros`", euros);
    }

    return "- €";
  }

  return `${euros.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`;
};

/**
 * Convert cents to euros and format as a string
 */
export const centsToEuros = (cents: number, locale = "fi"): string => formatEuros(cents / 100);

type CompensateDateFormats = "default" | "wordy";

/**
 * Consistently display dates with Compensate's preferred date formats.
 * Available formatting options are:
 *    "default" e.g. "25/12/2021" (delimeter and day/month order depends on locale)
 *    "wordy"   e.g. "December 25"
 */
export const formatDate = (date: Date, style: CompensateDateFormats = "default"): string => {
  const formatMap: { [key in CompensateDateFormats]: Intl.DateTimeFormatOptions } = {
    default: { dateStyle: "short" },
    wordy: { month: "long", day: "numeric" },
  };

  const options = formatMap[style] || formatMap.default;

  return new Intl.DateTimeFormat("default", options).format(date);
};
