import get from "lodash/get";

/**
 * Reference the Material UI theme via a shorthand syntax within styled components for easier readability
 *
 * For example, rather than:
 *    padding: ${props => props.theme.spacing(6)};
 *
 * Import the spacing util and use:
 *    padding: ${spacing(6)};
 */
const generateThemeGetter = (key: string) => (...args: any[]) => ({ theme }: any) => {
  if (typeof theme[key] === "function") {
    return theme[key](...args);
  }

  if (theme[key] instanceof Object) {
    return get(theme[key], args[0]);
  }

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.warn("Called with an unsupported key. Only theme functions and objects are supported :)");
  }
};

export const spacing = generateThemeGetter("spacing");

export const palette = generateThemeGetter("palette");

export const typography = generateThemeGetter("typography");
