import React, { ReactNode } from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonProps as MuiButtonProps, default as MuiButton } from "@material-ui/core/Button";

import { ReactComponent as Arrow } from "../assets/icons/arrow-right.svg";
import { palette, spacing } from "../theme/utils/theme-getters";

const StyledMuiButton = styled(MuiButton)`
  &.focus-visible {
    opacity: 0.75;
  }
`;

const DarkOutlinedButton = styled(MuiButton)`
  border-color: ${palette("text.primary")};
  color: ${palette("text.primary")};

  &:hover,
  &:active,
  &.focus-visible {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:hover,
  &:active {
    border-color: ${palette("text.primary")};
    color: ${palette("text.primary")};
  }
`;

const LightOutlinedButton = styled(MuiButton)`
  border-color: ${palette("text.light")};
  color: ${palette("text.light")};

  &:hover,
  &:active,
  &.focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover,
  &:active {
    border-color: ${palette("text.light")};
    color: ${palette("text.light")};
  }

  &:disabled {
    opacity: 0.55;
    border-color: ${palette("text.light")};
    color: ${palette("text.light")};
  }
`;

const LightTextButton = styled(MuiButton)`
  color: ${palette("text.light")};

  &:hover,
  &:active,
  &.focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover,
  &:active {
    color: ${palette("text.light")};
  }

  &:disabled {
    opacity: 0.55;
    color: ${palette("text.light")};
  }
`;

const StyledArrow = styled(Arrow)`
  margin-left: ${spacing(3)}px;
`;

export interface ButtonProps {
  "data-testid"?: string;
  color?: "light" | "dark" | MuiButtonProps["color"];
  component?: React.ElementType;
  endIcon?: ReactNode;
  loading?: boolean;
  rel?: string;
  target?: string;
  withArrow?: boolean;
}

const Button = ({ color, loading, variant, withArrow, ...props }: ButtonProps & Omit<MuiButtonProps, "color">) => {
  props = {
    ...props,
    focusVisibleClassName: "focus-visible",
  };

  if (withArrow) {
    props = {
      ...props,
      endIcon: props.endIcon || <StyledArrow />,
    };
  }

  if (loading) {
    props = {
      ...props,
      disabled: true,
      endIcon: <CircularProgress color="inherit" />,
    };
  }

  if (color === "dark") {
    // Dark button only supports outlined variant
    return <DarkOutlinedButton variant="outlined" {...props} />;
  }

  if (color === "light") {
    // Light button only supports text and outlined variants
    return variant === "text" ? (
      <LightTextButton variant={variant} {...props} />
    ) : (
      <LightOutlinedButton variant="outlined" {...props} />
    );
  }

  return <StyledMuiButton color={color} variant={variant} {...props} />;
};

export default Button;
