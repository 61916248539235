import create from "zustand";

import { getFuelTypeFactors } from "@api/realestate-api";
import { productNameToPrettyName, unitToUnitName, FUEL_TYPE_OTHER } from "@constants/fuel-types";

export const formatName = (factor) => {
  const unit = unitToUnitName[factor.unit];
  const name = productNameToPrettyName[factor.id] || factor.id;

  if (!unit) return name;

  return `${name} per ${unit}`;
};

const compareProducts = (product1, product2) => formatName(product1).localeCompare(formatName(product2));

const mapProductToOption = (product) => ({
  id: product.category,
  unit: product.properties.unit,
});

const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
};

const useFuelTypesStore = create((set, get) => ({
  status: STATUS.IDLE,

  isLoading: () => get().status === STATUS.LOADING,

  fuelTypes: [],

  fetchFuelTypes: async () => {
    set({ status: STATUS.LOADING });

    try {
      const products = await getFuelTypeFactors();

      set({
        status: STATUS.IDLE,
        fuelTypes: products
          .map(mapProductToOption)
          .sort(compareProducts)
          .concat([{ id: FUEL_TYPE_OTHER, unit: "Unit" }]),
      });
    } catch (error) {
      set({ status: STATUS.ERROR });
      // TODO: We could retry the request or show an error message here
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
}));

export default useFuelTypesStore;
