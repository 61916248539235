import { linetoBold, linetoBook } from "./font-face";

const createOverrides = (theme: any) => ({
  MuiCssBaseline: {
    "@global": {
      "@font-face": [linetoBold, linetoBook],
    },
  },

  MuiButton: {
    root: {
      textTransform: "none",
      padding: theme.spacing(3, 4),
    },

    outlined: {
      borderWidth: "3px",
      padding: theme.spacing(3, 4),

      "&$disabled": {
        borderWidth: "3px",
      },
    },

    outlinedPrimary: {
      borderWidth: "3px",

      "&:hover": {
        borderWidth: "3px",
      },

      "&:active": {
        borderWidth: "3px",
      },
    },
  },

  MuiTypography: {
    paragraph: {
      marginBottom: 22,
    },

    gutterBottom: {
      marginBottom: 56,
    },
  },

  MuiLink: {
    root: {
      fontWeight: 700,
    },
  },

  MuiCheckbox: {
    root: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(2),
    },
  },

  MuiFilledInput: {
    root: {
      backgroundColor: theme.palette.background.default,
      borderBottomWidth: "2px",
      borderBottomColor: theme.palette.secondary.main,
      borderBottomStyle: "solid",

      "&$focused": {
        backgroundColor: theme.palette.background.default,
      },

      "&:hover, &:focus, &:active": {
        backgroundColor: theme.palette.background.default,
      },
    },

    input: {
      backgroundColor: theme.palette.background.default,
    },
  },

  MuiTableRow: {
    head: {
      verticalAlign: "bottom",
    },
  },

  MuiTableCell: {
    root: {
      borderBottomWidth: "2px",
      borderBottomColor: theme.palette.text.primary,
    },

    head: {
      fontSize: 15,
      lineHeight: 1.3,
      color: theme.palette.text.secondary,
    },

    footer: {
      backgroundColor: theme.palette.primary.light,
      borderBottom: "none",
      fontSize: 15,
    },
  },

  MuiAccordion: {
    root: {
      border: `2px solid ${theme.palette.text.primary}`,
      borderLeft: 0,
      borderRight: 0,
      background: "transparent",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
      "&$disabled": {
        backgroundColor: "transparent",
      },
    },
    expanded: {},
  },

  MuiAccordionSummary: {
    root: {
      "&$disabled": {
        opacity: 1,
      },
    },
  },

  MuiToggleButton: {
    root: {
      border: `2px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary,
      fontWeight: 400,

      "&$selected": {
        border: `2px solid ${theme.palette.text.primary}`,
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.text.light,

        "&:hover": {
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
  },

  MuiSwitch: {
    root: {
      width: theme.spacing(7),
      height: theme.spacing(4),
      padding: 0,
      display: "flex",
    },

    switchBase: {
      padding: theme.spacing(1 / 2),
      color: theme.palette.background.default,

      "&$checked": {
        transform: `translateX(${theme.spacing(3)}px)`,
        color: theme.palette.primary.main,

        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },

    thumb: {
      color: theme.palette.background.default,
      width: theme.spacing(3),
      height: theme.spacing(3),
      boxShadow: "none",
    },

    track: {
      border: 0,
      borderRadius: theme.spacing(2),
      opacity: 1,
      backgroundColor: theme.palette.background.dark,
    },
  },

  MuiDrawer: {
    root: {
      "& .MuiPaper-root": {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
        border: "none",
        padding: 32,
      },
    },
  },

  MuiTooltip: {
    tooltip: {
      backgroundColor: theme.palette.background.dark,
    },
    arrow: {
      color: theme.palette.background.dark,
    },
  },

  MuiDivider: {
    root: {
      height: 4,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.2,
    },
  },

  MuiCard: {
    root: {
      overflow: "visible",
    },
  },

  MuiCardContent: {
    root: {
      padding: theme.spacing(3),
    },
  },

  MuiPopover: {
    paper: {
      boxShadow: "5px 0px 12px -2px rgba(0, 0, 0, 0.12)",
    },
  },
});

export default createOverrides;
