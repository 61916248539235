import React from "react";
import { Controller } from "react-hook-form";

import { TextField } from "@carbon/shared";
import FIELD_PROP_TYPES from "./field-prop-types";

/**
 * Controlled version of TextField
 *
 * This should be used instead of the uncontrolled TextField if the input value is
 * set without user interaction e.g. when preloading form data from the server.
 * This avoids issues with overlapping labels: https://github.com/mui-org/material-ui/issues/17018
 */
const ControlledTextField = ({ register, name, ...rest }) => (
  <Controller
    name={name}
    render={({ ref, onChange, onBlur, value }) => (
      <TextField name={name} inputRef={ref} onChange={onChange} onBlur={onBlur} value={value} {...rest} />
    )}
  />
);

ControlledTextField.propTypes = FIELD_PROP_TYPES;

export default ControlledTextField;
