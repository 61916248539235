import { loadStripe } from "@stripe/stripe-js";

import config from "@config";
import { apiQuery } from "./api";

const stripePromise = loadStripe(config.get("stripeCompensationKey"));

export const redirectToCheckout = async (values, token) => {
  const stripe = await stripePromise;

  const request = {
    type: "POST",
    path: "v1/payment/checkout_session",
    data: {
      value: 1,
      calculations: values.calculations,
      metadata: values.metadata,
      success_path: values.success_path,
      cancel_path: values.cancel_path,
    },
  };
  const session = await apiQuery(request, token).run();
  return await stripe.redirectToCheckout({ sessionId: session.id });
};
