import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import fi from "date-fns/locale/fi";

export const formatCO2Emission = (locale = "fi", num) => {
  const formattedNumber = num.toLocaleString(locale, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 3,
  });

  if (num === 0) {
    return "0";
  }

  // If the number exceeds maximumFractionDigits, provide a rough estimate
  if (num > 0 && formattedNumber === "0,0") {
    return "< 0,001";
  }

  return formattedNumber;
};

export const formatMoney = (locale = "fi", num) => {
  return num.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatDate = (date, formatStr = "d.M.yyyy") => {
  if (typeof date === "string") {
    date = parseISO(date);
  }

  return format(date, formatStr, {
    locale: fi,
  });
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "EUR":
      return "€";

    default:
      return currency;
  }
};

export const formatNumber = (num) => {
  if (typeof num !== "number") {
    return num;
  }

  return num.toLocaleString("fi");
};
