/** COCO */
export const TOU_LINK = "https://www.compensate.com/compensate-plus-terms-of-use";
export const FAQ_COCO_LINK = "https://www.compensate.com/faq-compensate-plus";

/** GENERAL */
export const PRIVACY_STATEMENT_LINK = "https://www.compensate.com/privacy-statement";
export const FAQ_LINK = "https://www.compensate.com/faq";
export const SUSTAINABILITY_LINK = "https://www.compensate.com/sustainability";
export const COMPENSATE_COM_LINK = "https://www.compensate.com";
export const COMPENSATE_COM_BUSINESS_LINK = "https://www.compensate.com/for-business";
export const BRAND_GUIDELINES_LINK = "https://compensate.com/brand/";

/** EXTERNAL */
export const STRIPE_PRICING_LINK = "https://stripe.com/en-fi/payments";
export const MOBY_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.compensate.mobileapp";
export const MOBY_APP_STORE = "https://apps.apple.com/fi/app/compensate/id1517361384";

/** CARBON STORE */
export const API_DOCS_LINK = "https://api.compensate.com/docs/";
export const API_MARKET_LINK = "https://api.compensate.com/docs/#tag/Carbon-Market";
