import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { FormLabel } from "@material-ui/core";

import { NumberField, DatePicker } from "@components/form";
import { COLUMN } from "@components/form/constants/layout";
import { palette, spacing, message, TextField } from "@carbon/shared";

import { requiredNumber } from "../../../components/form/utils/schema";

const StyledFormLabel = styled(FormLabel)`
  color: ${palette("text.primary")};
  display: block;
  margin-top: ${spacing(2)}px;
  margin-bottom: -${spacing(1)}px;
`;

const schema = yup.object().shape({
  numberOfPeople: requiredNumber("Number of people"),
  reportingPeriodFrom: yup
    .date()
    .nullable()
    .typeError(message.invalidDateFormat)
    .required(message.isRequired("A start date")),
  reportingPeriodTo: yup
    .date()
    .nullable()
    .typeError(message.invalidDateFormat)
    .min(yup.ref("reportingPeriodFrom"), "This should be later than the start date")
    .required(message.isRequired("An end date")),
  description: yup.string(),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },

  defaultValues: {
    numberOfPeople: "",
    reportingPeriodFrom: null,
    reportingPeriodTo: null,
  },

  fields: [
    {
      render: NumberField,
      name: "numberOfPeople",
      label: "Number of employees or users",
      required: true,
    },
    {
      name: "reportingPeriodLabel",
      render() {
        return <StyledFormLabel>Reporting period</StyledFormLabel>;
      },
    },
    {
      render: DatePicker,
      name: "reportingPeriodFrom",
      label: "From",
      required: true,
      wrapperProps: COLUMN.HALF,
    },
    {
      render: DatePicker,
      name: "reportingPeriodTo",
      label: "To",
      required: true,
      wrapperProps: COLUMN.HALF,
    },
    {
      render: TextField,
      name: "description",
      label: "Description",
      required: false,
      fieldProps: {
        helperText: "Additional information about the digital calculation.",
      },
    },
  ],
};

export default FORM_CONFIG;
