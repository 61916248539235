import React from "react";
import { palette, spacing } from "@carbon/shared";
import { ButtonBase, ButtonBaseProps, Typography } from "@material-ui/core";
import styled, { css } from "styled-components";

import PACKAGES from "./packages";
import OtherPackageAccordion from "./OtherPackageAccordion";

const OptionsWrapper = styled.div`
  z-index: 4;
  grid-column: 8 / 13;
  grid-row: 1 / 3;
  color: ${palette("text.light")};
`;

const selectedOptionStyles = css`
  margin-left: -${spacing(4)}px;
  width: calc(100% + ${spacing(4)}px);
  transition: margin-left 0.2s, width 0.2s 5ms;

  &,
  &:hover {
    opacity: 1;
  }
`;

const PackageOption = styled(ButtonBase)<{ $selected: boolean } & ButtonBaseProps>`
  width: 100%;
  padding: ${spacing(4)}px;
  margin-bottom: ${spacing(2)}px;
  justify-content: flex-start;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);
  opacity: 0.8;
  box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.9;
  }

  ${({ $selected }) => $selected && selectedOptionStyles};
`;

interface PackageOptionsProps {
  selectedIndex: number;
  onClickPackage: (index: number) => void;
}

const PackageOptions = ({ selectedIndex, onClickPackage }: PackageOptionsProps) => (
  <OptionsWrapper>
    {PACKAGES.map(({ label }, i) => (
      <PackageOption key={i} onClick={() => onClickPackage(i)} $selected={selectedIndex === i}>
        <Typography variant="h5" component="span">
          {label}
        </Typography>
      </PackageOption>
    ))}
    <OtherPackageAccordion />
  </OptionsWrapper>
);

export default PackageOptions;
