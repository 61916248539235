import React from "react";
import PropTypes from "prop-types";
import { Box, Link, Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";
import { Button, SUSTAINABILITY_LINK } from "@carbon/shared";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import tick from "@assets/icons/tick.svg";
import { CO2 } from "./Units";

const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const StyledLi = styled((props) => <Typography component="li" {...props} />)`
  list-style-position: inside;
  list-style-image: url(${tick});
`;

const StyledInfoIcon = styled(InfoIcon)`
  display: inline-block;
  margin-left: 6px;
`;

const PlanCard = ({ buttonLabel = "Sign in", condensed, employeeCount, onClick, title, pricePerYear }) => (
  <CardContainer>
    <Box display={condensed ? "none" : ["none", "block"]} mx={[6, 0]} mt={4} mb={1}>
      <Typography variant="h4">{title}</Typography>
      <Typography>Up to {employeeCount} employees</Typography>
    </Box>

    <CardContainer px={[6, 4]} py={4} bgcolor="primary.main" color="text.light">
      <Box display={condensed ? "block" : ["block", "none"]}>
        <Typography variant="h4">{title}</Typography>
        <Typography paragraph>Up to {employeeCount} employees</Typography>
      </Box>

      <Typography variant="h4" display="inline">
        {pricePerYear / 12} € per month
      </Typography>

      <Typography variant="body2" paragraph>
        Annual billing only, excluding VAT
      </Typography>

      <Box component="ul" mt={0} mb={8} mx={0} p={0}>
        <StyledLi>Access to multiple emission calculators</StyledLi>
        <StyledLi>
          Offset through our{" "}
          <Link href={SUSTAINABILITY_LINK} underline="always" color="inherit" target="_blank" rel="noopener">
            high quality portfolio
          </Link>
          <Tooltip
            interactive
            title={
              <Typography variant="body2">
                Each tonne of <CO2 /> costs 35 € to offset. 100% of your compensation payment goes to carbon capture
              </Typography>
            }
            placement="top"
            arrow
          >
            <StyledInfoIcon fontSize="small" color="inherit" />
          </Tooltip>
        </StyledLi>
        <StyledLi>14 day free trial</StyledLi>
        <StyledLi>Compensation certificate</StyledLi>
        <StyledLi>Marketing tools</StyledLi>
      </Box>

      {typeof onClick === "function" && (
        <Button fullWidth size="large" variant="outlined" color="light" onClick={onClick} withArrow>
          {buttonLabel}
        </Button>
      )}
    </CardContainer>
  </CardContainer>
);

PlanCard.propTypes = {
  buttonLabel: PropTypes.string,
  condensed: PropTypes.bool,
  pricePerYear: PropTypes.number,
  title: PropTypes.string.isRequired,
  employeeCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default PlanCard;
