import React from "react";
import { TCO2E } from "@components/common/Units";
import { formatCO2Emission, formatDate, formatMoney } from "@utils/formatting";
import { centToEuro, gramToTon } from "@utils/unit-conversion";

export const SUMMARY_TABLE_COLUMNS = [
  { label: "Number of employees", getCell: "form.numberOfPeople" },

  {
    label: "Reporting period",
    getCell: (item) => `${formatDate(item.form.reportingPeriodFrom)} - ${formatDate(item.form.reportingPeriodTo)}`,
  },
  { label: "Description", getCell: "form.description" },
  {
    label: "Total Emissions",
    getCell(item) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(item.result.emissions.usage))} <TCO2E />
        </>
      );
    },
    getTotal(summary) {
      return (
        <>
          {formatCO2Emission(undefined, gramToTon(summary.emissions))} <TCO2E />
        </>
      );
    },
  },

  {
    label: "Compensation",
    getCell: (item) => `${formatMoney(undefined, centToEuro(item.result.value.amount))} ${item.result.value.currency}`,
    getTotal: (summary) => `${formatMoney(undefined, centToEuro(summary.price))} ${summary.currencySymbol}`,
  },
];
