import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Typography,
  Box,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import get from "lodash/get";

import { Button } from "@carbon/shared";
import SubmitCalculationsButton from "@components/common/SubmitCalculationsButton";
import { palette, spacing } from "@carbon/shared";

const createCellGetter = (getter) => (column, data, ...rest) => {
  if (typeof column[getter] === "function") {
    return column[getter](data, ...rest);
  }

  if (typeof column[getter] === "string") {
    return get(data, column[getter]);
  }

  return null;
};

const getCellFromRow = createCellGetter("getCell");

const getTotalFromSummary = createCellGetter("getTotal");

const StyledTableContainer = styled(TableContainer)`
  margin-bottom: ${spacing(2)}px;
`;

const ActionTableCell = styled(TableCell)`
  border-bottom: none;
`;

const RemoveButton = styled(Button)`
  color: ${palette("text.secondary")};
  font-weight: 400;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-right: ${spacing(4)}px;
`;

const SummaryTable = ({ summary, columns, calculations, onRemoveRow, onSubmitCalculations }) => (
  <>
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell key={i} component="th">
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {calculations.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box display="flex" justifyItems="center">
                  <StyledInfoIcon color="primary" />
                  <Typography component="span" color="textSecondary">
                    There's nothing here yet, get started by calculating emissions above
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}

          {calculations.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <TableCell key={columnIndex}>{getCellFromRow(column, row)}</TableCell>
              ))}

              <ActionTableCell>
                <RemoveButton variant="text" size="small" onClick={() => onRemoveRow(rowIndex)}>
                  Remove
                </RemoveButton>
              </ActionTableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            {columns.map((column, columnIndex) => (
              <TableCell key={columnIndex}>
                {columnIndex === 0 ? <b>Total</b> : getTotalFromSummary(column, summary, calculations)}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </StyledTableContainer>
    <SubmitCalculationsButton amount={summary.price} onClick={onSubmitCalculations} />
  </>
);

SummaryTable.propTypes = {
  calculations: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      total: PropTypes.node,
    }).isRequired,
  ).isRequired,

  onRemoveRow: PropTypes.func.isRequired,
  onSubmitCalculations: PropTypes.func.isRequired,

  summary: PropTypes.shape({
    price: PropTypes.number.isRequired,
    emissions: PropTypes.number.isRequired,
    currencySymbol: PropTypes.string.isRequired,
  }).isRequired,
};

export default SummaryTable;
