import React from "react";
import { Link, LinkProps } from "@material-ui/core";

const CompensateEmailLink = ({ username = "support", children, ...rest }: Props) => (
  <Link href={`mailto:${username}@compensate.com`} {...rest}>
    {children || `${username}@compensate.com`}
  </Link>
);

interface Props extends LinkProps {
  children?: React.ReactNode;
  username?: string;
}

export default CompensateEmailLink;
