import { EMISSION_EQUIVALENTS } from "../../constants/emission-equivalents";

type Unit = "t" | "kg" | "g";

interface FormatOptions {
  withUnit?: boolean;
  equivalent?: boolean;
  decimals?: number;
}

/**
 * Helper for formatting emissions.
 * Use this with grams by chaining fromGrams, e.g.
 * new Emissions(1000000).fromGrams().toString() => "1 tCO₂e"
 */
class Emissions {
  static formatUnit(unit: Unit = "t", equivalent: boolean = true) {
    return `${unit}CO₂${equivalent ? "e" : ""}`;
  }

  constructor(public amount: number, private unit: Unit = "t", private locale?: string) {}

  /**
   * Returns a new Emissions instance where the amount is converted from grams to the specified unit
   * @param unit Override the default unit for this instance
   */
  fromGrams(unit: Unit = this.unit): Emissions {
    let fraction = 1;

    if (unit === "t") {
      fraction = 1 / 1000000; // Gram to tonne
    }

    if (unit === "kg") {
      fraction = 1 / 1000; // Gram to kilo
    }

    return new Emissions(this.amount * fraction, unit, this.locale);
  }

  /**
   * Returns the emission amount as a localized, consistently formatted string
   */
  toString(options: FormatOptions = {}): string {
    const { withUnit = true, equivalent = true, decimals } = options;

    const minimumAmount = 0.001;
    const showApproximation = this.amount > 0 && this.amount < minimumAmount;
    const amount = showApproximation ? `< ${minimumAmount.toLocaleString(this.locale)}` : this.roundAmount(decimals);

    if (withUnit) {
      return `${amount} ${Emissions.formatUnit(this.unit, equivalent)}`;
    }

    return amount;
  }

  /**
   * Returns the equivalent emissions as a relatable figure, e.g. kilometers driven
   */
  toImpactEquivalent(type: keyof typeof EMISSION_EQUIVALENTS): React.ReactNode {
    return Math.round(this.amount / EMISSION_EQUIVALENTS[type]).toLocaleString(this.locale);
  }

  /**
   * If `decimals` is supplied, round to that number of decimal places OR
   *
   * Rounds the emission amount to:
   *  - A maximum of either 4sf or number of significant figures to the left of the decimal point (whichever is largest)
   *  - A maximum of 3 decimal places
   */
  private roundAmount(decimals?: number): string {
    if (typeof decimals === "number") {
      return this.amount.toLocaleString(this.locale, { maximumFractionDigits: decimals });
    }

    const significantIntegerDigits = (Math.log10(this.amount) + 1) | 0; // Counts significant digits to the left of the decimal place
    const maximumSignificantDigits = Math.max(significantIntegerDigits, 4);

    // Round by maximumFractionDigits if the number consists only of fraction digits
    const options = significantIntegerDigits > 0 ? { maximumSignificantDigits } : { maximumFractionDigits: 3 };

    return this.amount.toLocaleString(this.locale, options);
  }
}

export default Emissions;
