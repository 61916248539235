import { QUERY_FLOW, QUERY_KEY } from "../constants/query-string";
import { apiQuery } from "./api";
import { ApiError } from "@carbon/shared";

const getPayloadFromForm = (form) => ({
  business_id: form.businessId,
  vat_id: form.vatNum,
  contact_name: form.contactName,
  email: form.contactEmailAddress,
  business_domicile: form.businessCountryDomicile.value,
  name: form.companyName,
  country: form.country.value,
  street: form.address,
  state: form.state?.value,
  city: form.city,
  postcode: form.postCode,
});

export const registerCustomer = (form, token) => {
  const apiRequest = {
    type: "POST",
    path: "/v1/customer/register",
    data: getPayloadFromForm(form),
  };
  return apiQuery(apiRequest, token);
};

export const updateCustomer = (form, token) => {
  const apiRequest = {
    type: "POST",
    path: "/v1/customer/update",
    data: getPayloadFromForm(form),
  };
  return apiQuery(apiRequest, token);
};

export const getCustomer = (token) => {
  return apiQuery({ type: "GET", path: "/v1/customer" }, token);
};

export const createStripeCheckout = (license, token) => {
  return apiQuery(
    {
      type: "POST",
      path: "/v1/license/checkout_session",
      data: {
        license_type: license,
        success_path: `/emission-calculators?${QUERY_KEY.FLOW}=${QUERY_FLOW.REGISTER}`,
        cancel_path: "/billinginfo",
      },
    },
    token,
  ).run();
};

export const cancelLicense = async (token) => {
  return await apiQuery(
    {
      type: "POST",
      path: "/v1/license/cancel",
    },
    token,
  ).run();
};

export const getBillingPortalURL = async (returnUrl, stripeAccount) => {
  const request = {
    type: "POST",
    path: "/v1/billing/portal",
    data: {
      return_url: returnUrl,
      stripe_account: stripeAccount,
    },
  };
  const portalSession = await apiQuery(request).run();
  if (!portalSession) {
    throw new ApiError({ message: "Failed to create a billing portal request" });
  }
  if (!portalSession.url) {
    throw new ApiError({ message: "Failed to create the billing portal redirect url" });
  }
  return portalSession.url;
};
