import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, FormHelperText, FormLabel } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Controller } from "react-hook-form";

import { palette, spacing } from "@carbon/shared";
import FIELD_PROP_TYPES from "../constants/field-prop-types";

const StyledFormLabel = styled(FormLabel)`
  color: ${palette("text.primary")};
  margin-bottom: ${spacing(2)}px;
`;

const ToggleButtons = ({ options, helperText, fieldProps, error, required, name, label, defaultValue }) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    render={(props) => (
      <FormControl required={required} error={!!error}>
        <StyledFormLabel>{label}</StyledFormLabel>
        <ToggleButtonGroup
          exclusive
          {...props}
          {...fieldProps}
          onChange={(e, value) => {
            props.onChange(value);
          }}
        >
          {options.map(({ label, value }) => (
            <ToggleButton key={value} value={value} name={name}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        {(error?.message || helperText) && <FormHelperText>{error?.message || helperText}</FormHelperText>}
      </FormControl>
    )}
  />
);

ToggleButtons.propTypes = {
  ...FIELD_PROP_TYPES,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    }),
  ),
};

export default ToggleButtons;
