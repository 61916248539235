// Ignore until FormGenerator is typed
// @ts-nocheck

import React from "react";

import FormGenerator from "../FormGenerator";
import FORM_CONFIG from "../config/login";

interface Props {
  onSubmit: (any) => Promise<void>;
}

const LoginForm = ({ onSubmit }: Props) => {
  let formConfig = FORM_CONFIG;

  return <FormGenerator {...formConfig} resetOnSubmit={false} onSubmit={onSubmit} justifyContent="space-between" />;
};

export default LoginForm;
