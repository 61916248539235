import React, { useEffect, useState } from "react";
import { Box, Collapse, IconButton, Typography, Link } from "@material-ui/core";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

import useAuthStore, { cocoUserSelector } from "@store/auth";
import useCookieConsent, { dismissedVATSelector, setDismissedVATSelector } from "@store/cookie-consent";
import { VAT_STATUS } from "@constants/vat-status";
import { TOU_LINK } from "@carbon/shared";

const NEW_TERMS_EFFECTIVE_DATE = new Date("2021-08-01");

const getNotificationFromUser = (cocoUser, dismissedVATChanges) => {
  if (cocoUser?.status === VAT_STATUS.FAILED) {
    return {
      severity: "error",
      title: "Oops, we weren't able to verify your VAT number",
      description: (
        <>
          Please{" "}
          <Link href="mailto:support@compensate.com?subject=Compensate Plus: VAT verification failed">
            contact us here
          </Link>{" "}
          so we can get you up and running. You can access the service but will need a valid VAT number before
          compensating.
        </>
      ),
    };
  }

  // If a user signed up before new terms went into use
  if (!dismissedVATChanges && cocoUser?.created_at && new Date(cocoUser.created_at) <= NEW_TERMS_EFFECTIVE_DATE) {
    return {
      type: "term-changes",
      severity: "info",
      title: "Our Terms of Use have changed",
      description: (
        <>
          Starting 1 August 2021, we’re charging Finnish value-added tax (24%) on all compensation payments. Please{" "}
          <Link href={TOU_LINK} target="_blank" rel="noopener">
            review the changes
          </Link>{" "}
          before making compensation payments.
        </>
      ),
    };
  }

  return null;
};

/**
 * Display generic information about a user's account, for example an error notification stating their VAT number failed verification
 */
const StatusNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const cocoUser = useAuthStore(cocoUserSelector);
  const dismissedVAT = useCookieConsent(dismissedVATSelector);
  const setDismissedVAT = useCookieConsent(setDismissedVATSelector);

  const handleClose = () => {
    if (notification.type === "term-changes") {
      setDismissedVAT(true);
    }

    setIsOpen(null);
  };

  useEffect(() => {
    const notification = getNotificationFromUser(cocoUser, dismissedVAT);

    if (notification) {
      setIsOpen(true);
      setNotification(notification);
    }
  }, [cocoUser, dismissedVAT]);

  return (
    <Box zIndex="snackbar" position="sticky" top="0">
      <Collapse in={isOpen} mountOnEnter unmountOnExit>
        <Alert
          severity={notification?.severity}
          action={
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          }
        >
          <AlertTitle variant="h5" gutterBottom={false} paragraph>
            {notification?.title}
          </AlertTitle>
          <Typography variant="body2">{notification?.description}</Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default StatusNotification;
