import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FIELD_WIDTH, FormConfig, CountryDropdown } from "@carbon/shared";

import { requiredEndDate, requiredOption, requiredNumber, requiredDate } from "../../../components/form/utils/schema";
import { NumberField, DatePicker } from "../../../components/form";
import { EmployeesForm } from "./employees.types";
import PERSONAL_EMISSION_FACTOR_COUNTRIES from "./available-countries";

const schema = yup.object().shape({
  employees: yup.array().of(
    yup.object().shape({
      country: requiredOption("Employees' location"),
      numberOfEmployees: requiredNumber("Number of people"),
      start: requiredDate("A start date"),
      end: requiredEndDate("An end date", "start"),
    }),
  ),
});

const FORM_CONFIG: FormConfig<EmployeesForm> = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },

  defaultValues: {
    numberOfEmployees: null,
    country: null,
    start: null,
    end: null,
  },

  fields: [
    {
      render: CountryDropdown,
      name: "country",
      label: "Employees' location",
      required: true,
      props: {
        whitelist: PERSONAL_EMISSION_FACTOR_COUNTRIES,
      },
      wrapperProps: FIELD_WIDTH.THIRD,
    },
    {
      render: NumberField,
      name: "numberOfEmployees",
      label: "Number of employees",
      required: true,
      wrapperProps: FIELD_WIDTH.THIRD,
    },
    {
      render: DatePicker,
      name: "start",
      label: "From",
      required: true,
      wrapperProps: FIELD_WIDTH.SIXTH,
    },
    {
      render: DatePicker,
      name: "end",
      label: "To",
      required: true,
      wrapperProps: FIELD_WIDTH.SIXTH,
    },
  ],
};

export default FORM_CONFIG;
