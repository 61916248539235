import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { RadioButtons } from "@components/form";
import { Link, Typography } from "@material-ui/core";

import {
  COPY_TEXT_FOR_THIS_SPACE,
  COPY_TEXT_IDK_USE_ESTIMATE,
  COPY_TEXT_USE_ESTIMATE,
  COPY_TEXT_WHOLE_BUILDING,
  FOR_REPORTED_SPACE,
  FOR_WHOLE_BUILDING,
  RENEWABLE,
  USE_GENERAL_ESTIMATE,
  DEFAULT_FACTOR,
  WITH_KNOWN_EMISSION_FACTOR,
  SEPARATE_POWER_PLANT,
} from "./constants";

import {
  AMOUNT_USED_FIELD,
  BUILDING_SIZE_FIELD,
  BUILDING_TYPE_FIELD,
  COMMON_SCHEMA_CONFIG,
  EMISSION_FACTOR_FIELD,
  SPACE_SIZE_FIELD,
} from "./common-form-config";

const showDistrictHeatingType = (amountKnown) => !!amountKnown;

const schema = yup.object().shape({
  ...COMMON_SCHEMA_CONFIG,
  used_type: yup
    .string()
    .when("amount_known", { is: showDistrictHeatingType, then: yup.string().required("Please choose an option") }),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  submitWrapperProps: {
    xs: 12,
    sm: "auto",
  },

  defaultValues: {
    amount_known: "",
    used_type: "",
  },

  fields: [
    {
      label: "Do you know the amount of district heating used?",
      name: "amount_known",
      render: RadioButtons,
      required: true,
      helperText: "If your space doesn’t use district heating, please skip this section",
      options: [
        {
          value: FOR_REPORTED_SPACE,
          label: COPY_TEXT_FOR_THIS_SPACE,
        },
        {
          value: FOR_WHOLE_BUILDING,
          label: COPY_TEXT_WHOLE_BUILDING,
        },
        {
          value: USE_GENERAL_ESTIMATE,
          label: COPY_TEXT_USE_ESTIMATE,
        },
      ],
    },

    SPACE_SIZE_FIELD,

    BUILDING_SIZE_FIELD,

    AMOUNT_USED_FIELD,

    BUILDING_TYPE_FIELD,

    {
      render: RadioButtons,
      name: "used_type",
      label: "District heating is",
      required: true,
      options: [
        {
          value: "FI",
          label: (
            <>
              <Typography>From a combined heat and power plant, Finland</Typography>
              <Link
                paragraph
                variant="caption"
                target="_blank"
                rel="noopener"
                href="https://storage.googleapis.com/coco-api-static-files/pdfs/District_heating_CHP_2018.pdf"
              >
                Check that your space location is on the list
              </Link>
            </>
          ),
          alignStart: true,
        },
        {
          value: SEPARATE_POWER_PLANT,
          label: (
            <>
              <Typography>From a separate power plant, Finland</Typography>
              <Link
                variant="caption"
                target="_blank"
                rel="noopener"
                href="https://storage.googleapis.com/coco-api-static-files/pdfs/District_heating_2020.pdf"
              >
                Check your emission factor based on your location
              </Link>
            </>
          ),
          alignStart: true,
        },
        {
          value: RENEWABLE,
          label: "100 % renewable",
        },
        {
          value: WITH_KNOWN_EMISSION_FACTOR,
          label: "Provider or country specific with known emission factor",
        },
        {
          value: DEFAULT_FACTOR,
          label: COPY_TEXT_IDK_USE_ESTIMATE,
        },
      ],
      dependsOn: {
        name: "amount_known",
        condition: showDistrictHeatingType,
      },
    },

    EMISSION_FACTOR_FIELD,
  ],
};

export default FORM_CONFIG;
