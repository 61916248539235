import React from "react";
import { TextField as MuiTextField } from "@material-ui/core";

import { FieldProps } from "./types";

const TextField = ({ inputRef, fieldProps, register, error, required, name, label, ...rest }: FieldProps) => (
  <MuiTextField
    id={`text-field-${name}`}
    inputRef={register || inputRef}
    error={!!error}
    required={required}
    name={name}
    label={label}
    {...rest}
    {...fieldProps}
    // Keep error message last to make sure it overrides any other helper texts
    helperText={error?.message || fieldProps?.helperText}
  />
);

export default TextField;
