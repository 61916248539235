import React from "react";
import { Link, ButtonProps } from "@material-ui/core";
import Button from "./Button";

const ExternalLinkButton = (props: ButtonProps) => (
  <Button
    withArrow
    fullWidth
    variant="outlined"
    color="light"
    size="large"
    component={Link}
    target="_blank"
    rel="noopener"
    {...props}
  />
);

export default ExternalLinkButton;
