import React from "react";
import PropTypes from "prop-types";
import { FeatureGate } from "@carbon/shared";
import { Typography, Tooltip, Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Arrow } from "@assets/icons/arrow-right.svg";
import useNavigationStore from "@store/navigation";

const StyledTypography = styled(Typography)`
  position: relative;
  display: flex;
  align-items: center;
  transition: color 0.2s;
  padding-left: 80px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledArrow = styled(Arrow)`
  position: absolute;
  top: calc(50% - 10px);
  left: 0;

  path {
    stroke: currentColor;
  }
`;

const NavigationItem = ({ comingSoon, featureGate, label, path }) => {
  const closeNav = useNavigationStore((state) => state.closeNav);

  const item = (
    <StyledTypography component="li" variant="h2" gutterBottom>
      {comingSoon ? (
        <Tooltip title="Coming soon" arrow>
          <span>{label}</span>
        </Tooltip>
      ) : (
        <Link color="inherit" component={NavLink} onClick={closeNav} to={path}>
          <StyledArrow /> {label}
        </Link>
      )}
    </StyledTypography>
  );

  return featureGate ? <FeatureGate feature={featureGate}>{item}</FeatureGate> : item;
};

NavigationItem.propTypes = {
  comingSoon: PropTypes.bool,
  featureGate: PropTypes.string,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
};

export default NavigationItem;
