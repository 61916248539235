import React from "react";
import PropTypes from "prop-types";
import { MenuItem, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

import FIELD_PROP_TYPES from "../constants/field-prop-types";

const Dropdown = ({ options, fieldProps, error, required, name, label, defaultValue }) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    as={
      <TextField
        id={name}
        label={label}
        select
        error={Boolean(error)}
        required={required}
        {...fieldProps}
        helperText={error?.message || fieldProps?.helperText}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    }
  />
);

Dropdown.propTypes = {
  ...FIELD_PROP_TYPES,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    }),
  ),
};

export default Dropdown;
