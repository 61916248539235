import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

import { spacing } from "@carbon/shared";
import ArrowList from "../common/ArrowList";

const StyledImage = styled.img`
  height: 80%;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 70vw;
    width: calc(100% - ${spacing(10)}px);
    margin: 0 ${spacing(5)}px;
  }
`;

const RightImageWrapper = styled(Grid)`
  display: flex;
  align-items: flex-end;
`;

const MainContentWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const MainContent = styled.div`
  margin: ${spacing(10)}px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin: ${spacing(5)}px;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: ${spacing(5)}px;
  }
`;

const StyledGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: ${spacing(5)}px;
    margin-bottom: ${spacing(5)}px;

    > div.MuiGrid-item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const Instructions = ({ large = false, imageOne, imageTwo, title, subtitle, listTitle, listItems, ...props }) => {
  const doubleImage = imageOne && imageTwo;

  return (
    <StyledGrid container spacing={5} justifyContent="space-between" {...props}>
      {doubleImage && (
        <Grid item xs={12} md={3}>
          <StyledImage src={imageTwo} />
        </Grid>
      )}

      <MainContentWrapper item xs={12} md={doubleImage ? 6 : 8} lg={6}>
        <MainContent>
          <Typography variant={large ? "h2" : "h3"} paragraph>
            {title}
          </Typography>

          {subtitle && <Typography gutterBottom>{subtitle}</Typography>}

          {listTitle && (
            <Typography variant={large ? "h4" : "h5"} paragraph>
              {listTitle}
            </Typography>
          )}

          <ArrowList variant={large ? "subtitle2" : undefined} items={listItems} />
        </MainContent>
      </MainContentWrapper>

      <RightImageWrapper item xs={12} md={doubleImage ? 3 : 4} lg={doubleImage ? 3 : 4}>
        <StyledImage src={imageOne} />
      </RightImageWrapper>
    </StyledGrid>
  );
};

Instructions.propTypes = {
  large: PropTypes.bool,
  imageOne: PropTypes.string.isRequired,
  imageTwo: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  listTitle: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Instructions;
