import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Fade } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import styled from "styled-components";

import useNavigationStore from "@store/navigation";
import useAuthStore, { statusSelector, isRegistered, isAnonymous } from "@store/auth";
import { Frame } from "@carbon/shared";
import {
  CALCULATOR_NAV_ITEMS,
  GENERAL_NAV_ITEMS,
  SUBSCRIBED_GENERAL_NAV_ITEMS,
  ACCESS_LINKS,
} from "@constants/navigation";
import NavigationList from "./NavigationList";

const Modal = styled(Box)`
  position: fixed;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledFrame = styled(Frame)`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
`;

const getGeneralNavItems = (userStatus) => {
  if (isAnonymous(userStatus)) return ACCESS_LINKS;

  if (isRegistered(userStatus)) return SUBSCRIBED_GENERAL_NAV_ITEMS;

  return GENERAL_NAV_ITEMS;
};

const Navigation = () => {
  const userStatus = useAuthStore(statusSelector);
  const isOpen = useNavigationStore((state) => state.isOpen);
  const closeNav = useNavigationStore((state) => state.closeNav);
  const generalNavItems = getGeneralNavItems(userStatus);

  return (
    <Fade in={isOpen}>
      <Modal bgcolor="white" zIndex="modal">
        <StyledFrame bgcolor="beige.main" px={[2, 4, 10]} pt={[10, 20]}>
          <CloseButton variant="text" color="inherit" onClick={closeNav}>
            <Close fontSize="large" />
          </CloseButton>

          <Grid component="nav" container spacing={10} p={[5, 10]} justifyContent="center">
            <NavigationList items={generalNavItems} />
            <NavigationList items={CALCULATOR_NAV_ITEMS} />
          </Grid>
        </StyledFrame>
      </Modal>
    </Fade>
  );
};

Navigation.propTypes = {
  isOpen: PropTypes.bool,
};

export default Navigation;
