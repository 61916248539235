import React from "react";
import instructionsImg from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { CO2, CO2E } from "@components/common/Units";
import BillingInstruction from "@components/common/BillingInstruction";

const FlightsInstructions = () => (
  <Instructions
    title="How this works"
    imageOne={instructionsImg}
    listItems={[
      <>
        Use Compensate’s flight calculator to figure out the carbon footprint of your flights and how much offsetting it
        costs!
      </>,
      <>
        The calculator determines the amount of greenhouse gas emissions the flight creates per passenger(s) for the
        given flight distance. All the emissions are accounted for, and expressed in <CO2E />.
      </>,
      <>
        The calculations are based on average consumption for typical short, medium and long haul flights. They also
        take into account whether you’re traveling economy, business, or first class. If you do not know the class, use
        average!
      </>,
      <>If you had layovers on your trips, please mark the flights as separate flights.</>,
      <>You can add one or multiple flights to your summary.</>,
      <>
        The “Compensation impact” refers to the number of trees required to sequester the amount of <CO2 /> emissions in
        a year. This gives you an idea of how many trees are needed to compensate for the emissions created by the
        flights.
      </>,
      <>
        Compensate’s unique overcompensation is already built into the calculator – making sure we can double your
        climate impact!
      </>,
      <BillingInstruction key="billing-instruction" />,
    ]}
  />
);

export default FlightsInstructions;
