import React from "react";

import { is } from "@utils/comparison";
import { NumberFieldWithAdornment } from "@components/form";
import { TRANSPORTATION_TYPE } from "./constants";

export const isRailTransportation = is(TRANSPORTATION_TYPE.RAIL);

export const RAIL_FORM_FIELDS = [
  {
    render(props) {
      return <NumberFieldWithAdornment adornment="km" {...props} />;
    },
    name: "distance",
    label: "Transported distance",
    required: true,
    dependsOn: {
      name: "transportationType",
      condition: isRailTransportation,
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },

  {
    render(props) {
      return <NumberFieldWithAdornment adornment="kg" digits={2} {...props} />;
    },
    name: "weight",
    label: "Weight of the goods/cargo",
    required: true,
    fieldProps: {
      helperText: "We automatically add 10% to include secondary packaging",
    },
    dependsOn: {
      name: "transportationType",
      condition: isRailTransportation,
    },
    newRow: true,
    wrapperProps: { xs: 12, sm: 10, md: 8, lg: 6 },
  },
];
