import { QUERY_KEY, QUERY_FLOW } from "@constants/query-string";
import useAuthStore, { LICENSE_STATUS } from "@store/auth";

export const getCompensationParams = (summary, type, cancelPath = `/${type}`) => {
  const licenseStatus = useAuthStore.getState().cocoUser.license.license_status;
  const endFreeTrial = licenseStatus === LICENSE_STATUS.FREE_TRIAL || licenseStatus === LICENSE_STATUS.CANCELLED;
  const flow = endFreeTrial ? QUERY_FLOW.END_TRIAL_AND_COMPENSATE : QUERY_FLOW.COMPENSATE;

  return {
    calculations: summary.ids,
    metadata: {
      type,
    },
    success_path: `/emission-calculators?${QUERY_KEY.FLOW}=${flow}&${QUERY_KEY.TYPE}=${type}`,
    cancel_path: cancelPath,
  };
};
