import { ThemeOptions } from "@material-ui/core";

const typography: Partial<ThemeOptions["typography"]> = {
  fontSize: 16,
  fontWeight: 400,
  fontFamily:
    '"Lineto Circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  fontFamilyMonospaced:
    'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", Monaco, "Courier New", Courier, monospace',

  h1: {
    fontWeight: 700,
    fontSize: 78,
    lineHeight: 1.1,

    "@media (max-width: 600px)": {
      fontSize: 42,
    },
  },

  h2: {
    fontWeight: 700,
    fontSize: 52,
    lineHeight: 1.1,
  },

  h3: {
    fontWeight: 700,
    fontSize: 42,
    lineHeight: 1.3,
  },

  h4: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: 1.3,
  },

  h5: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1.3,
  },

  h6: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.6,
  },

  subtitle1: {
    fontWeight: 400,
    fontSize: 32,
    lineHeight: 1.3,
  },

  subtitle2: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.6,
  },

  body2: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: 1.6,
  },

  button: {
    fontWeight: 700,
    textTransform: "none",
    fontSize: 20,
    lineHeight: 1.3,
  },
};

export default typography;
