import React, { ReactNode } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { responsiveFontSizes, CssBaseline } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import theme from "./theme";

interface Props {
  children: ReactNode;
}

const ThemeProviders = ({ children }: Props) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default ThemeProviders;
