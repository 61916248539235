import { ApiError, ServerApiError } from "./errors";

export type RequestType = "POST" | "GET";

export interface Request<T> {
  type: RequestType;
  path: string;
  data?: T;
}

export interface Handlers {
  handleUnauthorized?: () => void;
  handlePaymentRequired?: () => void;
}

const isServerError = (httpStatusCode: number): boolean => httpStatusCode >= 500 && httpStatusCode < 600;

const isClientError = (httpStatusCode: number): boolean => httpStatusCode >= 400 && httpStatusCode < 500;

const isError = (httpStatusCode: number): boolean => isServerError(httpStatusCode) || isClientError(httpStatusCode);

async function executeFetch(path: string, params: RequestInit, handlers: Handlers = {}) {
  const response = await fetch(path, params);

  if (response.status === 204) {
    return response;
  }

  if (response.status === 401) {
    handlers.handleUnauthorized?.();
  }

  if (response.status === 402) {
    handlers.handlePaymentRequired?.();
  }

  if (!isError(response.status)) {
    return response.json();
  }

  let errorObject = response;

  try {
    errorObject = await response.json();
  } catch {}

  throw new ApiError((errorObject as unknown) as ServerApiError, response.status);
}

function getParams<T>(requestType: RequestType, headers: RequestInit["headers"], data: T): RequestInit {
  if (requestType === "GET") {
    return {
      method: "GET",
      headers,
    };
  }

  if (requestType === "POST") {
    return {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    };
  }

  return {};
}

export function executeApiQuery<T>(request: Request<T>, token?: string, handlers?: Handlers) {
  const headers: RequestInit["headers"] = {
    accept: "application/json",
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  const params = getParams(request.type, headers, request.data);

  return executeFetch(request.path, params, handlers);
}
