import React from "react";
import { ApiError } from "@carbon/shared";
import { Link, Typography } from "@material-ui/core";
import { AlertProps } from "@material-ui/lab/Alert";

import { CalculationResult } from "../types";

interface CalculationsAndErrors<TForm> {
  // Formatted results from successful calculations
  calculations: CalculationResult<TForm>[];
  // Errors from failed calculations
  errors: ApiError[];
  // The indices of successful calculations in the original array of forms, useful for filtering the form array to only keep failed calculations
  successIndices: number[];
}

/**
 * Takes an array of all resolved employees calculator requests and groups them by success and error
 * successIndices includes the original index of each successful calculation, used to filter the array to only keep rows with errors
 */
function splitCalculationsAndErrors<TForm>(
  results: (ApiError | CalculationResult<TForm>)[],
): CalculationsAndErrors<TForm> {
  return results.reduce(
    ({ calculations, errors, successIndices }, result, i) => {
      if (result instanceof ApiError) {
        return { calculations, errors: [...errors, result], successIndices };
      }

      return { calculations: [...calculations, result], errors, successIndices: [...successIndices, i] };
    },
    { calculations: [], errors: [], successIndices: [] },
  );
}

/**
 * Returns snackbar message and severity based on how many calculations succeeded and failed
 */
function getBulkSnackbarConfig<TForm>(
  { calculations, errors }: CalculationsAndErrors<TForm>,
  successMessage: React.ReactNode,
): [React.ReactNode, AlertProps["severity"]] {
  const successCount = calculations.length;
  const failCount = errors.length;

  if (failCount) {
    const severity = successCount ? "warning" : "error";
    const title = successCount ? (
      <>
        {failCount}/{failCount + successCount} calculations failed. {successCount} calculations have been added to{" "}
        <Link href="#summary">your summary</Link>
      </>
    ) : (
      "Failed to submit calculations"
    );

    return [
      <>
        <Typography variant="h6" paragraph>
          {title}
        </Typography>
        <ul>
          {errors.map((error, i) => (
            <Typography key={i} component="li" variant="body2">
              {error.message}
            </Typography>
          ))}
        </ul>
      </>,
      severity,
    ];
  }

  return [successMessage, "success"];
}

/**
 * Helper function to submit multiple calculations and format success and error responses consistently
 */
async function submitBulkCalculations<TForm>(
  submitCalculation: (form: TForm) => Promise<CalculationResult<TForm>>,
  calculations: TForm[],
) {
  const promises = calculations.map((formRow) => submitCalculation(formRow).catch((error: ApiError) => error));
  const responses = await Promise.all(promises);
  const results = splitCalculationsAndErrors<TForm>(responses);

  return results;
}

export { getBulkSnackbarConfig, submitBulkCalculations };
