import React from "react";
import { ThemeOptions } from "@material-ui/core";

const props: Partial<ThemeOptions["props"]> = {
  MuiButtonBase: {
    disableRipple: true,
  },

  MuiButton: {
    disableElevation: true,
    variant: "contained",
    color: "primary",
  },

  MuiLink: {
    color: "primary",
  },

  MuiCheckbox: {
    color: "secondary",
    checkedIcon: (
      <svg width="31" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.0608 12.0608l-9.5607 9.5606-5.56065-5.5606 2.12135-2.1213 3.4393 3.4393 7.4394-7.43935 2.1213 2.12135z"
          fill="currentColor"
        />
        <path d="M1.5 1.5h28v28h-28v-28z" stroke="currentColor" strokeWidth="3" />
      </svg>
    ),
    icon: (
      <svg width="31" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 1.5h28v28h-28v-28z" stroke="currentColor" strokeWidth="3" />
      </svg>
    ),
  },

  MuiTextField: {
    fullWidth: true,
    variant: "filled",
    color: "secondary",
  },

  MuiFilledInput: {
    disableUnderline: true,
  },

  MuiIconButton: {
    color: "secondary",
  },

  MuiInputAdornment: {
    variant: "outlined",
  },

  MuiCircularProgress: {
    color: "inherit",
    size: 20,
    thickness: 5,
  },

  MuiSwitch: {
    color: "default",
  },
};

export default props;
