import React from "react";
import PropTypes from "prop-types";
import { InputAdornment } from "@material-ui/core";
import NumberField from "./NumberField";

const NumberFieldWithAdornment = ({ adornment, ...rest }) => (
  <NumberField
    InputProps={{
      endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
    }}
    {...rest}
  />
);

NumberFieldWithAdornment.propTypes = {
  adornment: PropTypes.node.isRequired,
};

export default NumberFieldWithAdornment;
