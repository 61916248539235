import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";

import FIELD_PROP_TYPES from "./field-prop-types";

/**
 * Support filtering options by label, group or a searchTerms array not visible to the user
 * To use search terms, add them to the option e.g. { value: 'houses', label: 'Houses', searchTerms: ['apartments', 'flats'] }
 */
const filterOptions = createFilterOptions({
  stringify(option) {
    let searchString = option.label;

    if (option.searchTerms instanceof Array) {
      searchString = `${searchString} ${option.searchTerms.join(" ")}`;
    }

    if (option.groupBy) {
      searchString = `${searchString} ${option.groupBy}`;
    }

    return searchString;
  },
});

const AutocompleteDropdown = ({
  autocompleteProps,
  defaultValue = null,
  error,
  fieldProps,
  label,
  name,
  options,
  register,
  ...rest
}) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    render={({ onChange, onBlur, name, ref, value }) => (
      <Autocomplete
        autoHighlight
        openOnFocus
        value={value}
        options={options}
        onChange={(e, value) => onChange(value)}
        onBlur={onBlur}
        filterOptions={filterOptions}
        groupBy={(option) => option.groupBy}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        {...autocompleteProps}
        renderInput={(muiProps) => (
          <TextField
            inputRef={ref}
            name={name}
            label={label}
            {...rest}
            {...fieldProps}
            {...muiProps}
            error={Boolean(error)}
            helperText={error?.message || fieldProps?.helperText}
            inputProps={{
              ...fieldProps.inputProps,
              ...rest.inputProps,
              ...muiProps.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
    )}
  />
);

AutocompleteDropdown.propTypes = {
  ...FIELD_PROP_TYPES,
  autocompleteProps: PropTypes.shape(),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.value,
    }),
  ),
};

AutocompleteDropdown.defaultProps = {
  autocompleteProps: {},
  fieldProps: {},
};

export default AutocompleteDropdown;
