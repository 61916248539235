import { centsToEuros, formatEuros } from "@carbon/shared";

import { CalculationResult, SummaryTableColumn } from "../../../types";
import { getTotalEmissionsCell, getTotalEmissionsSummary } from "../../../utils/summary-table";
import { FLAT_INDEX_CATEGORIES } from "./index-categories";

import { PurchaseByAmountForm, PurchaseByQuantityForm, PurchaseForm, Option } from "./index.types";

const reduceTotalAmountSpent = (sum: number, calculation: CalculationResult<PurchaseByAmountForm>) =>
  sum + (calculation.form.amount || 0);

const reduceTotalQuantity = (sum: number, calculation: CalculationResult<PurchaseByQuantityForm>) =>
  sum + (calculation.form.quantity || 0);

const getTypeLabel = (form: PurchaseForm) => {
  if (form.type === "amount") {
    return "Based on amount spent";
  }

  if (form.type === "quantity") {
    return "Based on quantity purchased";
  }

  return "";
};

const getProductLabel = (value: string): Option | undefined =>
  FLAT_INDEX_CATEGORIES.find((product) => product.value === value);

const SUMMARY_TABLE_COLUMNS: SummaryTableColumn<PurchaseForm>[] = [
  { label: "Calculation type", getCell: (item) => getTypeLabel(item.form) },

  { label: "Product category", getCell: (item) => getProductLabel(item.form.productCategory!)?.label ?? "" },

  {
    label: "Amount spent",
    getCell: (item) => (item.form.type === "amount" ? formatEuros(item.form.amount!) : "-"),
    getTotal: (summary, calculations) => formatEuros(calculations.reduce(reduceTotalAmountSpent, 0)),
  },

  {
    label: "Quantity",
    getCell: (item) => (item.form.type === "quantity" ? item.form.quantity : "-"),
    getTotal: (summary, calculations) => calculations.reduce(reduceTotalQuantity, 0),
  },

  { label: "Description", getCell: "form.description" },

  {
    label: "Total Emissions",
    getCell: getTotalEmissionsCell,
    getTotal: getTotalEmissionsSummary,
  },

  {
    label: "Compensation",
    getCell: (item) => centsToEuros(item.result.value.amount),
    getTotal: (summary) => centsToEuros(summary.price),
  },
];

export default SUMMARY_TABLE_COLUMNS;
