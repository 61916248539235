import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, CompensateIcon, palette, spacing } from "@carbon/shared";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

import ArrowList from "../../../components/common/ArrowList";
import { Package } from "./packages.types";

interface PackageDetailsProps {
  details: Package;
}

const MOTION_PROPS = {
  initial: { y: 30, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -200, opacity: 0 },
  transition: { ease: "easeInOut", duration: 0.6 },
};

const MotionMainCard = styled(motion.div)`
  background: ${palette("primary.extraLight")};
  padding: ${spacing(4, 6, 8, 4)};
  grid-column: 1 / 8;
  grid-row: 2 / 4;

  > div {
    ${({ theme }) => theme.breakpoints.down("xs")} {
      flex-direction: column;
    }
  }
`;

const MotionTipCard = styled(motion.div)`
  margin-left: ${spacing(4)}px;
  grid-column: 1 / 6;
  grid-row: 3 / 5;

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-column: 0 / 6;
    margin-left: ${spacing(2)}px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: -${spacing(4)}px;
    margin-right: ${spacing(2)}px;
  }
`;

const SecondaryMotionTipCard = styled(MotionTipCard)`
  grid-column: 6 / 11;
  margin-left: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-column: 6 / 12;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: ${spacing(2)}px;
    margin-left: ${spacing(2)}px;
  }
`;

const Quote = styled.figure`
  position: relative;
  margin: 0;
  padding: ${spacing(1, 1, 1, 6)};
  border-top: 4px solid ${palette("primary.main")};

  &::before {
    content: "“";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 100px;
    line-height: 1;
    color: ${palette("primary.main")};
  }

  blockquote {
    font-weight: bold;
    margin: ${spacing(0, 0, 2, 0)};
  }

  figcaption {
    margin: 0;
    color: ${palette("primary.main")};
  }
`;

const PackageDetails = ({ details }: PackageDetailsProps) => (
  <>
    <MotionMainCard key={`${details.label}-card`} {...MOTION_PROPS}>
      {details.useCases.map((useCase, i) => (
        <Box key={i} display="flex" alignItems="center" my={2}>
          <CompensateIcon icon={useCase.icon} size="md" />
          <Typography variant="body1">{useCase.description}</Typography>
        </Box>
      ))}

      <Button
        withArrow
        fullWidth
        // eslint-disable-next-line react/display-name
        component={forwardRef((props, ref) => (
          <Link to="/login" {...props} ref={ref} />
        ))}
      >
        Log in
      </Button>
    </MotionMainCard>

    <MotionTipCard key={`${details.label}-tip-1`} {...MOTION_PROPS} transition={{ ease: "easeInOut", delay: 0.4 }}>
      <Box bgcolor="primary.light" p={4}>
        <Typography variant="h6" paragraph>
          What you'll need
        </Typography>

        <ArrowList
          variant="body1"
          miniArrow
          items={details.useCases.map(({ data }) => data).filter(Boolean)}
        ></ArrowList>
      </Box>
    </MotionTipCard>

    {!!details.testimonial && (
      <SecondaryMotionTipCard
        key={`${details.label}-tip-2`}
        {...MOTION_PROPS}
        transition={{ ease: "easeInOut", delay: 0.6 }}
      >
        <Box bgcolor="primary.light" p={4}>
          <Typography variant="h6" paragraph>
            {details.testimonial?.title}
          </Typography>

          <Quote>
            <Typography variant="body1" component="blockquote">
              {details.testimonial.quote}
            </Typography>
            <Typography variant="h6" component="figcaption">
              {details.testimonial.author}
            </Typography>
          </Quote>
        </Box>
      </SecondaryMotionTipCard>
    )}
  </>
);
export default PackageDetails;
