import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { NumberFieldWithAdornment } from "@components/form";
import useFuelTypesStore from "@store/fuel-type-factors";
import FIELD_PROP_TYPES from "@components/form/constants/field-prop-types";
import { M3, KWH } from "@components/common/Units";

const maximumFractionDigitsForUnit = {
  l: 1,
  t: 3,
  m3: 1,
  kWh: 0,
};

export const getUnitFromFuelType = (fuelType, fuelTypeFactors) => {
  const fuelTypeFactor = fuelTypeFactors.find((f) => f.id === fuelType);

  return fuelTypeFactor?.unit ?? "unit";
};

const formatUnit = (unit) => {
  if (unit === "m3") return <M3 />;

  if (unit === "kWh") return <KWH />;

  return unit;
};

/**
 * Watches the selected fuel type field to update the unit based on the chosen fuel
 */
const AmountOfFuelUsed = ({ fuelTypeField, ...props }) => {
  const fuelTypes = useFuelTypesStore((state) => state.fuelTypes);
  const { watch } = useFormContext();

  const selectedFuel = watch(fuelTypeField);

  return (
    <NumberFieldWithAdornment
      adornment={formatUnit(getUnitFromFuelType(selectedFuel, fuelTypes))}
      digits={maximumFractionDigitsForUnit[getUnitFromFuelType(selectedFuel, fuelTypes)]}
      {...props}
    />
  );
};

AmountOfFuelUsed.propTypes = {
  ...FIELD_PROP_TYPES,
  /** The name of the field containing the selected fuel type */
  fuelTypeField: PropTypes.string.isRequired,
};

export default AmountOfFuelUsed;
