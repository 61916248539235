import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import { palette, getStaticAsset } from "@carbon/shared";

const desktopFrameStyles = css`
  border-radius: 15px;
  padding-top: 20px;

  video {
    top: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 20px;
    background: ${palette("primary.dark")};
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    border-radius: 8px;
    padding-top: 10px;

    video {
      top: 10px;
    }

    &::before {
      height: 10px;
    }
  }
`;

const mobileFrameStyles = css`
  border-radius: 20px;
  box-shadow: 0 0 0 6px ${palette("primary.dark")};
`;

const videoAspectRatioStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Wrapper = styled.div<Omit<Props, "sources">>`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0); // Support hiding video overflow in safari
  padding-bottom: ${({ aspectRatio }) => (aspectRatio ? `${Math.round(aspectRatio * 100)}%` : 0)};
  background: ${palette("gray.light")} url(${({ poster }) => poster}) no-repeat 0 0;
  background-size: contain;

  video {
    display: block;
    width: 100%;
    height: auto;

    ${({ aspectRatio }) => typeof aspectRatio === "number" && videoAspectRatioStyles}
  }

  ${({ frame }) => frame === "desktop" && desktopFrameStyles};
  ${({ frame }) => frame === "mobile" && mobileFrameStyles};
`;

interface Source {
  src: string;
  type: "video/mp4";
}

interface Props {
  /** Used a fixed aspect ratio. Use this to improve styles while the video is loading */
  aspectRatio?: number;
  poster: string;
  sources: Source[];
  /** Wrap the video in a device frame */
  frame?: "desktop" | "mobile";
}

/**
 * Video component that autoplays when scrolled into view
 */
const AutoplayVideo = ({ aspectRatio, poster, sources, frame }: Props) => {
  const { ref, inView } = useInView();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    try {
      if (inView) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    } catch {
      // no-op
    }
  }, [inView]);

  return (
    <Wrapper poster={poster} frame={frame} aspectRatio={aspectRatio} ref={ref}>
      <video
        ref={videoRef}
        playsInline
        loop
        muted
        controls={false}
        poster={aspectRatio ? getStaticAsset("/images/transparent.png") : poster}
        preload="metadata"
      >
        {sources.map(({ src, type }) => (
          <source key={src} src={src} type={type} />
        ))}
      </video>
    </Wrapper>
  );
};

export default AutoplayVideo;
