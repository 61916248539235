const BRAND = {
  cyanDark: "#00454B",
  cyan: "#288C96",
  cyanLight: "#E5F1F2",
  cyanExtraLight: "#F2F8F8",
  cyanNeon: "#3EB5C1",

  beige: "#F3F0ED",
  beigeLight: "#FCFAF8",

  grayLight: "#F0F2F2",
  grayExtraLight: "#F7F8F8",

  black: "#1B1B1B",
  white: "#FFFFFF",

  gold: "#A08270",
};

export interface MonochromaticPalette {
  20: string;
  40: string;
  60: string;
  80: string; // The main colour for this palette
  100: string;
}

export const DATA_VIZ: { [key: string]: MonochromaticPalette } = {
  blue: {
    "20": "#33B1FF",
    "40": "#1192E8",
    "60": "#0072C3",
    "80": "#00539A",
    "100": "#003A6D",
  },
  purple: {
    "20": "#BE95FF",
    "40": "#A56EFF",
    "60": "#8A3FFC",
    "80": "#6929C4",
    "100": "#491D8B",
  },
  cyan: {
    "20": "#3DD5E3",
    "40": "#36BDC9",
    "60": "#30A5B0",
    "80": BRAND.cyan,
    "100": "#22757D",
  },
  gold: {
    "20": "#DCD1CB",
    "40": "#C8B6AC",
    "60": "#B49A8E",
    "80": BRAND.gold,
    "100": "#846358",
  },
};

const CUSTOM = {
  red: "#f44336",
  green: "#EDF7ED",
};

const palette = {
  primary: {
    dark: BRAND.cyanDark,
    main: BRAND.cyan,
    light: BRAND.cyanLight,
    extraLight: BRAND.cyanExtraLight,
    neon: BRAND.cyanNeon,
  },

  secondary: {
    main: BRAND.black,
  },

  beige: {
    main: BRAND.beige,
    extraLight: BRAND.beigeLight,
    transparent: `${BRAND.gold}18`, // Gold with opacity 18%
  },

  gray: {
    "20": "#333333",
    "40": "#666666",
    "60": "#999999",
    "80": "#CCCCCC",
    light: BRAND.grayLight,
    extraLight: BRAND.grayExtraLight,
  },

  gold: BRAND.gold,
  black: BRAND.black,
  white: BRAND.white,

  background: {
    default: BRAND.white,
    dark: BRAND.black,
  },

  text: {
    primary: BRAND.black,
    secondary: "rgba(0, 0, 0, 0.87)",
    tertiary: "rgba(0, 0, 0, 0.6)",
    light: BRAND.white,
  },

  status: {
    error: CUSTOM.red,
    success: CUSTOM.green,
  },

  dataViz: {
    ...DATA_VIZ,
    default: {
      carbon: "#001F23",
      primaryTone700: "#004F57",
      primaryTone400: "#00A4B0",
      primaryTone300: "#66C8D0",
      primaryTone200: "#AFE3E6",
      primaryTone50: "#DBFDFF",
      goldTone50: "#ECE6E2",
      goldTone100: "#D8CBC3",
      goldTone300: "#C6B4A9",
    },
  },
};

export default palette;
