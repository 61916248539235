/**
 * List of country codes that we support for personal emission calculations
 */
const PERSONAL_EMISSION_FACTOR_COUNTRIES = [
  "AE",
  "AL",
  "AM",
  "AR",
  "AT",
  "AU",
  "AZ",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BJ",
  "BN",
  "BO",
  "BR",
  "BW",
  "BY",
  "CA",
  "CH",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CY",
  "DE",
  "DK",
  "DO",
  "EC",
  "EE",
  "EG",
  "ES",
  "ET",
  "FI",
  "FR",
  "GB",
  "GE",
  "GH",
  "GN",
  "GR",
  "GT",
  "HK",
  "HN",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IR",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KR",
  "KW",
  "KZ",
  "LA",
  "LK",
  "LT",
  "LU",
  "LV",
  "MA",
  "MG",
  "MN",
  "MT",
  "MU",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PH",
  "PK",
  "PL",
  "PT",
  "PY",
  "QA",
  "RO",
  "RW",
  "SA",
  "SE",
  "SG",
  "SI",
  "SK",
  "SN",
  "SV",
  "TG",
  "TH",
  "TN",
  "TR",
  "TT",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "VE",
  "VN",
  "ZA",
  "ZM",
  "ZW",
];

export default PERSONAL_EMISSION_FACTOR_COUNTRIES;
