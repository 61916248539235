import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CountryRegionData } from "react-country-region-selector";

import AutocompleteDropdown from "./AutocompleteDropdown";
import FIELD_PROP_TYPES from "./field-prop-types";

const getCountryOptions = (whitelist) => {
  let countries = CountryRegionData;

  if (whitelist.length) {
    countries = countries.filter(([country, code]) => whitelist.includes(code));
  }

  return countries.map(([country, code]) => ({ label: country, value: code }));
};

const CountryDropdown = ({ whitelist, ...rest }) => {
  const countryOptions = useMemo(() => getCountryOptions(whitelist), [whitelist]);

  return <AutocompleteDropdown options={countryOptions} {...rest} />;
};

CountryDropdown.propTypes = { ...FIELD_PROP_TYPES, whitelist: PropTypes.arrayOf(PropTypes.string) };

CountryDropdown.defaultProps = {
  whitelist: [],
};

export default CountryDropdown;
