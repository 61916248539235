import React from "react";
import { useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const GENERAL_FORM_ERROR_KEY = "formError";

const GeneralFormError = () => {
  const { errors } = useFormContext();

  if (!errors.formError?.message) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Alert severity="error">{errors[GENERAL_FORM_ERROR_KEY].message}</Alert>
    </Grid>
  );
};

export default GeneralFormError;
