import { useState } from "react";

const useToggle = (initialValue = false): [boolean, () => void] => {
  const [isToggled, setIsToggled] = useState(initialValue);

  const handleToggle = () => setIsToggled(!isToggled);

  return [isToggled, handleToggle];
};

export default useToggle;
