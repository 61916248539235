import { string } from "prop-types";
import React, { useEffect, useState } from "react";

import { getCountryElectricityFactors } from "@api/realestate-api";
import Dropdown from "./Dropdown";

const countryNames = {
  AT: "Austria",
  BE: "Belgium",
  BG: "Bulgaria",
  CA: "Canada",
  HR: "Croatia",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  EE: "Estonia",
  FI: "Finland",
  FR: "France",
  DE: "Germany",
  GR: "Greece",
  HU: "Hungary",
  IS: "Iceland",
  IE: "Ireland",
  IT: "Italy",
  LV: "Latvia",
  LT: "Lithuania",
  LU: "Luxembourg",
  MT: "Malta",
  NL: "Netherlands",
  NO: "Norway",
  PL: "Poland",
  PT: "Portugal",
  RO: "Romania",
  RS: "Serbia",
  SK: "Slovakia",
  SI: "Slovenia",
  ES: "Spain",
  SE: "Sweden",
  CH: "Switzerland",
  UK: "United Kingdom",
  US: "United States",
  Europe: "European average",
  World: "Global average",
};

/**
 * Returns the Alpha-2 country code from a factor
 */
export const getCountryFromFactor = (factor) => {
  const parts = factor.split("/");
  const code = parts[parts.length - 1];

  return code;
};

export const formatFactor = (factor) => {
  const code = getCountryFromFactor(factor);

  return countryNames[code] || code;
};

const compareFactors = ({ value: f1 }, { value: f2 }) => {
  const diff = f1.length - f2.length;
  if (diff !== 0) return diff;
  return formatFactor(f1).localeCompare(formatFactor(f2));
};

const CountryElectricityFactorsDropdown = (props) => {
  const [factors, setFactors] = useState([]);

  useEffect(() => {
    getCountryElectricityFactors()
      .then((products) => {
        setFactors(
          products
            .map((product) => ({ value: product.category, label: formatFactor(product.category) }))
            .sort(compareFactors),
        );
      })
      .catch((err) => {
        // TODO: We could retry the request or show an error message here
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  return <Dropdown defaultValue="" options={factors} {...props} />;
};

export default CountryElectricityFactorsDropdown;

CountryElectricityFactorsDropdown.propTypes = {
  name: string.isRequired,
};
