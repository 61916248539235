import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CircularProgress, InputAdornment } from "@material-ui/core";

import useFuelTypesStore, { formatName } from "@store/fuel-type-factors";
import Dropdown from "@components/form/fields/Dropdown";
import FIELD_PROP_TYPES from "@components/form/constants/field-prop-types";

const UsedFuelForm = ({ fieldProps, ...props }) => {
  const { setValue } = useFormContext();

  const fetchFuelTypes = useFuelTypesStore((state) => state.fetchFuelTypes);
  const fuelTypes = useFuelTypesStore((state) => state.fuelTypes);
  const isLoading = useFuelTypesStore((state) => state.isLoading);

  const loading = isLoading();

  useEffect(() => {
    fetchFuelTypes();
  }, [fetchFuelTypes]);

  useEffect(() => {
    if (fuelTypes.length) {
      // Preselect the first fuel type after loading
      setValue(props.name, fuelTypes[0].id);
    }
  }, [fuelTypes, props.name, setValue]);

  if (loading) {
    // Show a field loading indicator
    fieldProps = {
      ...fieldProps,
      InputProps: {
        endAdornment: (
          <InputAdornment position="start">
            <CircularProgress />
          </InputAdornment>
        ),
      },
    };
  }

  return (
    <Dropdown
      defaultValue=""
      loading={loading}
      options={fuelTypes.map((factor) => ({ value: factor.id, label: formatName(factor) }))}
      fieldProps={fieldProps}
      {...props}
    />
  );
};

UsedFuelForm.propTypes = FIELD_PROP_TYPES;

export default UsedFuelForm;
