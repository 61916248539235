import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputAdornment } from "@material-ui/core";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import FlightLand from "@material-ui/icons/FlightLand";

import { message } from "@carbon/shared";
import { AirportAutocomplete, QuantityStepper, DatePicker, ToggleButtons } from "@components/form";
import { FLIGHT_TYPES, ONE_WAY, SEAT_TYPES } from "./constants";

import { requiredNumber } from "../../../components/form/utils/schema";

const AIRPORT_SCHEMA = yup
  .object()
  .shape({
    iata_code: yup.string(),
    name: yup.string(),
  })
  .typeError(message.pleaseSelect("an airport"))
  .required(message.pleaseSelect("an airport"));

const schema = yup.object().shape({
  airportFrom: AIRPORT_SCHEMA,
  airportTo: AIRPORT_SCHEMA,
  date: yup.date().nullable().typeError(message.invalidDateFormat).required(message.isRequired("A date")),
  quantity: requiredNumber("Quantity"),
  seatClass: yup.string().typeError(message.pleaseSelect("a class")).required(message.pleaseSelect("a class")),
  flightType: yup.string().typeError(message.pleaseSelect("a type")).required(message.pleaseSelect("a type")),
});

const FORM_CONFIG = {
  resolver: yupResolver(schema),

  submitLabel: "Calculate emissions",

  defaultValues: {
    airportFrom: null,
    airportTo: null,
    date: new Date(),
    quantity: 1,
    seatClass: SEAT_TYPES[0].value,
    flightType: ONE_WAY,
  },

  fields: [
    {
      render: AirportAutocomplete,
      name: "airportFrom",
      label: "Where from?",
      required: true,
      wrapperProps: {
        xs: 12,
        sm: 6,
        md: 5,
      },
      fieldProps: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <FlightTakeoff />
            </InputAdornment>
          ),
        },
      },
    },
    {
      render: AirportAutocomplete,
      name: "airportTo",
      label: "Where to?",
      required: true,
      wrapperProps: {
        xs: 12,
        sm: 6,
        md: 5,
      },
      fieldProps: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <FlightLand />
            </InputAdornment>
          ),
        },
      },
    },

    {
      render: DatePicker,
      name: "date",
      label: "Date",
      required: true,
      wrapperProps: {
        xs: 12,
        sm: 12,
        md: 2,
      },
    },

    {
      render: QuantityStepper,
      name: "quantity",
      label: "Quantity",
      required: true,
      wrapperProps: {
        xs: 12,
        sm: "auto",
      },
      helperText: "The number of passengers or trips",
    },

    {
      render: ToggleButtons,
      name: "flightType",
      label: "Flight type",
      required: true,
      options: FLIGHT_TYPES,
      wrapperProps: {
        xs: 12,
        sm: "auto",
      },
    },

    {
      render: ToggleButtons,
      name: "seatClass",
      label: "Class",
      required: true,
      options: SEAT_TYPES,
      wrapperProps: {
        xs: 12,
        sm: "auto",
      },
      helperText: "If you don’t know the class, use Average",
    },
  ],
};

export default FORM_CONFIG;
