import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { Section, getStaticAsset, palette, spacing, Accordion } from "@carbon/shared";

import PackageOptions from "./PackageOptions";
import PackageDetails from "./PackageDetails";
import OtherPackageAccordion from "./OtherPackageAccordion";
import PACKAGES from "./packages";

const backgroundImage = getStaticAsset("images/office_bg.jpg");
const accordionPackages = PACKAGES.map((p) => ({
  key: p.label,
  title: p.label,
  content: (
    <div>
      <PackageDetails details={p} />
    </div>
  ),
}));

const BackgroundSection = styled(Section)`
  transition: all 0.4s;
  background-color: ${palette("primary.main")};
  background-image: url("${backgroundImage}");
  background-position: left top;
  background-size: cover;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CenteredTypography = styled(Typography)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: ${palette("text.light")};
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-gap: ${spacing(2)}px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: ${spacing(2)}px min-content ${spacing(6)}px min-content;
  margin: 0 auto;
  max-width: 1600px;
  padding-left: ${spacing(10)}px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const MobilePackages = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

const PackagesAccordion = styled(Accordion)`
  border: none;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
  box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.2);

  &,
  &.Mui-expanded {
    margin-bottom: ${spacing(1)}px;
  }

  .MuiAccordionSummary-content {
    padding-top: ${spacing(2)}px;
    padding-bottom: ${spacing(2)}px;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-expandIcon {
    color: ${palette("text.light")};
  }
`;

const PackagesSection = () => {
  const [packageIndex, setPackageIndex] = useState<number>(0);
  const selectedPackage = PACKAGES[packageIndex];

  return (
    <BackgroundSection>
      <CenteredTypography variant="h2" gutterBottom>
        Become a carbon negative business
      </CenteredTypography>

      <CenteredTypography variant="h6" gutterBottom>
        With Compensate Plus, you get access to a wide selection of calculators to better understand your business’
        carbon footprint – and compensate for it. You can choose which calculators to use based on your unique business
        operations. Here’s how Compensate Plus can work for you.
      </CenteredTypography>

      {/* Packages and options are shown side by side on large screens */}
      <DetailsWrapper data-testid="packages-desktop">
        <PackageOptions selectedIndex={packageIndex} onClickPackage={(index) => setPackageIndex(index)} />
        <AnimatePresence>
          <PackageDetails details={selectedPackage} />
        </AnimatePresence>
      </DetailsWrapper>

      {/* Packages and options are shown in an accordion on smaller screens */}
      <MobilePackages>
        <PackagesAccordion items={accordionPackages} />
        <OtherPackageAccordion />
      </MobilePackages>
    </BackgroundSection>
  );
};

export default PackagesSection;
