import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormGenerator, getStaticAsset } from "@carbon/shared";

import { calculateDigitalServicesFootprint } from "@api/digitalservices-api";
import CalculatorPage from "@components/layout/CalculatorPage";
import SummaryTable from "@components/common/SummaryTable";
import { getCompensationParams } from "@utils/compensation";

import useSnackbarStore from "../../../store/snackbar";
import useCalculatorSummary from "../../../hooks/useCalculatorSummary";
import { SUMMARY_TABLE_COLUMNS } from "./constants";
import Instructions from "./components/Instructions";
import FORM_CONFIG from "./form-config";
import { redirectToCheckout } from "../../../api/payment-api";

const StyledFormGenerator = styled(FormGenerator)`
  max-width: 600px;
  margin: 0 auto;
`;

const DigitalServices = ({ headerHeight }) => {
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);
  const { calculations, addCalculationRow, removeCalculationRow, summary } = useCalculatorSummary(
    SUMMARY_TABLE_COLUMNS,
    "digital",
  );

  const handleSubmitCalculations = async () => {
    try {
      await redirectToCheckout(getCompensationParams(summary, "digital"));
    } catch (error) {
      createSnackbar(`Failed to submit calculations: ${error.message}`, "error");
    }
  };

  const handleAddCalculation = async (form) => {
    const calculation = await calculateDigitalServicesFootprint(form);

    addCalculationRow(calculation);

    createSnackbar("Digital emission added to the cart! Add another?");
  };

  return (
    <CalculatorPage
      backgroundImage={getStaticAsset("/images/digital-bg.jpg")}
      headerHeight={headerHeight}
      title="Digital"
      subtitle={
        <>
          Calculate your digital carbon footprint.
          <br />* = required fields
        </>
      }
      instructions={<Instructions />}
      summary={summary}
      summaryTable={
        <SummaryTable
          columns={SUMMARY_TABLE_COLUMNS}
          summary={summary}
          calculations={calculations}
          onRemoveRow={removeCalculationRow}
          onSubmitCalculations={handleSubmitCalculations}
        />
      }
    >
      <StyledFormGenerator onSubmit={handleAddCalculation} {...FORM_CONFIG} />
    </CalculatorPage>
  );
};

DigitalServices.propTypes = {
  headerHeight: PropTypes.number,
};

export default DigitalServices;
