import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import { ThemeProviders, FeatureProvider } from "@carbon/shared";
import { MuiPickersUtilsProvider } from "@material-ui/pickers"; // Must be imported before ThemeProviders for style injection order
import DateFnsUtils from "@date-io/date-fns";

import config from "./config";
import App from "./App";
import Snackbar from "./components/common/Snackbar";

// Use your config values here.
firebase.initializeApp(config.get("firebase"));

// eslint-disable-next-line no-console
console.log(`Starting application with environment: ${config.get("env")}`);

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <ThemeProviders>
      <FeatureProvider features={config.get("features")}>
        <Snackbar />
        <App />
      </FeatureProvider>
    </ThemeProviders>
  </MuiPickersUtilsProvider>,
  document.getElementById("root"),
);
