import { Frame } from "@carbon/shared";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import FooterContainer from "./FooterContainer";
import Header from "./Header";
import Navigation from "./Navigation";
import StatusNotification from "./StatusNotification";

const Page = ({ lightHeader, title, ...props }) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  const measureHeaderRef = useCallback((node) => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    document.title = title ? `${title} | Compensate Plus` : "Compensate Plus";
  }, [title]);

  return (
    <>
      <Frame>
        <StatusNotification />
        <Header ref={measureHeaderRef} light={lightHeader} />
        <Navigation />

        <main>
          {{
            ...props.page,
            props: { headerHeight, locale: "fi-FI" },
          }}
        </main>
      </Frame>
      <FooterContainer />
    </>
  );
};

Page.propTypes = {
  lightHeader: PropTypes.bool,
  page: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
