import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, FormHelperText, FormLabel, InputBase } from "@material-ui/core";
import { Controller } from "react-hook-form";

import { palette, spacing } from "@carbon/shared";
import FIELD_PROP_TYPES from "../constants/field-prop-types";
import { Button } from "@carbon/shared";

const StyledFormLabel = styled(FormLabel)`
  color: ${palette("text.primary")};
  margin-bottom: ${spacing(2)}px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  padding: 11px; // Match MuiToggleButton padding
  border: 2px solid ${palette("text.primary")};

  &:disabled,
  &:hover {
    border: 2px solid ${palette("text.primary")};
  }
`;

const StyledInput = styled(InputBase)`
  width: 80px;
  border: 2px solid ${palette("text.primary")};
  border-left: 0;
  border-right: 0;

  input {
    text-align: center;
  }
`;

const QuantityStepper = ({ options, helperText, fieldProps, error, required, name, label, defaultValue = 1 }) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    render={({ value, onChange, ...props }) => (
      <FormControl required={required} error={!!error}>
        <StyledFormLabel>{label}</StyledFormLabel>

        <Wrapper>
          <StyledButton
            onClick={() => onChange(value - 1)}
            disabled={value <= 1}
            variant="outlined"
            color="dark"
            aria-label="Decrease quantity"
          >
            -
          </StyledButton>
          <StyledInput
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value, 10) || "")}
            {...props}
            {...fieldProps}
          />
          <StyledButton
            onClick={() => onChange(value + 1)}
            variant="outlined"
            color="dark"
            aria-label="Increase quantity"
          >
            +
          </StyledButton>
        </Wrapper>

        {(error?.message || helperText) && <FormHelperText>{error?.message || helperText}</FormHelperText>}
      </FormControl>
    )}
  />
);

QuantityStepper.propTypes = {
  ...FIELD_PROP_TYPES,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    }),
  ),
};

export default QuantityStepper;
