import { useState } from "react";
import { getBillingPortalURL } from "../api/customer-api";

const useStripePortal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const redirectToStripePortal = async () => {
    try {
      setIsLoading(true);
      const url = await getBillingPortalURL(window.location.href, "licences");
      window.location.href = url;
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, redirectToStripePortal };
};

export default useStripePortal;
