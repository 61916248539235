import PropTypes from "prop-types";

const FIELD_PROP_TYPES = {
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  fieldProps: PropTypes.shape(),
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default FIELD_PROP_TYPES;
