import { GridSize } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export const FRAME_MARGIN = [0, 3, 5];

// FRAME_MARGIN, without a zero margin for mobile devices
export const MOBILE_SAFE_FRAME_MARGIN = [3, ...FRAME_MARGIN.slice(1)];

/**
 * Negate the margin caused by a parent component
 * E.g. to remove a section's gutters when wrapped in a Frame component
 * */
export const NEGATIVE_FRAME_MARGIN = [0, -3, -5];

/**
 * Used by form fields to ensure consistent grid columns
 */
export const FIELD_WIDTH: Record<string, Partial<Record<Breakpoint, GridSize | boolean>>> = {
  HALF: { xs: 12, md: 6 },
  THIRD: { xs: 6, md: 4 },
  QUARTER: { xs: 6, md: 3 },
  SIXTH: { xs: 6, md: 2 },
  AUTO: { xs: true }, // Fill remaining space
};
