import React, { ReactNode, useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled, { css } from "styled-components";

import { palette } from "../theme";

interface AccordionItem {
  title: ReactNode;
  key: string;
  content?: ReactNode;
  disabled?: boolean;
}

type Size = "large" | "medium" | "small";

interface Props extends Omit<AccordionProps, "children"> {
  items: AccordionItem[];
  size?: Size;
}

function getHeadingLevel(size: Size) {
  switch (size) {
    case "large":
      return "h4";

    case "medium":
      return "h5";

    case "small":
    default:
      return "body1";
  }
}

const StyledAccordion = styled(MuiAccordion)<{ $light: boolean }>`
  ${({ $light }) =>
    $light &&
    css`
      border-color: ${palette("gray.80")};
    `}
`;

const Accordion = ({ items, size = "large", ...props }: Props) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const headingLevel = getHeadingLevel(size);

  const createExpandHandler = (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
    setExpandedItem(expanded ? panel : null);
  };

  return (
    <>
      {items.map(({ key, title, content, disabled }) => (
        <StyledAccordion
          key={key}
          $light={size === "small"}
          expanded={expandedItem === key}
          onChange={createExpandHandler(key)}
          disabled={disabled}
          {...props}
        >
          <AccordionSummary
            aria-label="Expand"
            aria-controls={`panel-${key}-content`}
            id={`panel-${key}-header`}
            expandIcon={disabled ? null : <ExpandMoreIcon />}
          >
            <Typography variant={headingLevel}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
};

export default Accordion;
