import React from "react";
import instructionsImg from "@assets/img/compensate-sign.png";
import Instructions from "@components/common/Instructions";
import { CO2E } from "@components/common/Units";
import BillingInstruction from "@components/common/BillingInstruction";

const LogisticsInstructions = () => (
  <Instructions
    title="How this works"
    imageOne={instructionsImg}
    listItems={[
      <>
        Whether you’re delivering goods within a city or hauling international cargo, Compensate’s Logistics calculator
        lets you calculate the carbon footprint – and tell you how much offsetting it costs!
      </>,
      <>
        The calculator determines the amount of greenhouse gas emissions created by your operational logistics. All the
        emissions are accounted for, and expressed in <CO2E />.
      </>,
      <>
        The calculator takes into account how your goods/cargo is transported, the weight, and the transported distance.
      </>,
      <>
        If you wish to calculate emissions per vehicle instead of goods/cargo, you can do so by filling in the distance
        traveled or the amount and type of fuel used.
      </>,
      <>
        The upstream emissions of your logistics or used fuels are also automatically accounted for in the calculations.
      </>,
      <>
        You can add as many logistics calculations as you’d like. Simply fill in the information and values in the
        necessary fields and press “Add”.
      </>,
      <>
        “The Compensation impact” refers to the number of trees required to sequester the amount of CO2 emissions in a
        year. This gives you an idea of how many trees are needed to compensate for the emissions created by your
        logistics.
      </>,
      <>
        Compensate’s unique overcompensation is already built into the calculator. It ensures actual climate impact and
        tackles the risks involved in all carbon capture projects.
      </>,
      <BillingInstruction key="billing-instruction" />,
    ]}
  />
);

export default LogisticsInstructions;
