import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { ScrollToTop, useInjectScript } from "@carbon/shared";

import config from "./config";
import Page from "./components/layout/Page";
import { ProtectedRoute, ProtectedRegisteredRoute } from "./ProtectedRoute";
import FrontPage from "./pages/frontpage/FrontPage";
import ChooseCalculator from "./pages/choose-calculator/ChooseCalculator";

import Flights from "./pages/calculators/flights/Flights";
import RealEstate from "./pages/calculators/real-estate/RealEstate";
import DigitalServices from "./pages/calculators/digital-services/DigitalServices";
import Employees from "./pages/calculators/employees/Employees";
import Logistics from "./pages/calculators/logistics/Logistics";
import OtherCompensations from "./pages/calculators/other-compensations/OtherCompensations";
import Index from "./pages/calculators/index/Index";

import Login from "./pages/login/LoginPage";
import BillingInfo from "./pages/billinginfo/BillingInfoPage";
import Licensing from "./pages/licensing/LicensingPage";
import Account from "./pages/Account";
import useAuthStore, { isLoading, statusSelector } from "./store/auth";
import { history } from "./utils/router";
import { GTM_SCRIPT_PROD, GTM_SCRIPT_DEV } from "./constants/analytics";

const environment = config.get("env");
const isAnalyticsEnabled = config.get("features").analytics;

function App() {
  const watchForAuthChanges = useAuthStore((state) => state.watchForAuthChanges);
  const authLoading = isLoading(useAuthStore(statusSelector));

  useInjectScript(environment === "production" ? GTM_SCRIPT_PROD : GTM_SCRIPT_DEV, isAnalyticsEnabled);

  useEffect(() => {
    watchForAuthChanges();
  }, [watchForAuthChanges]);

  if (authLoading) {
    return <>Loading...</>;
  }

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Page page={<FrontPage />} />
        </Route>
        <Route exact path="/login">
          <Page title="Login" page={<Login />} />
        </Route>
        <ProtectedRoute path="/billinginfo">
          <Page title="Billing information" page={<BillingInfo />} />
        </ProtectedRoute>
        <Route path="/licensing">
          <Page title="Plans" page={<Licensing />} />
        </Route>
        <Route exact path="/emission-calculators">
          <Page title="Emissions calculators" page={<ChooseCalculator />} />
        </Route>
        <ProtectedRegisteredRoute path="/account">
          <Page title="Your account" page={<Account />} />
        </ProtectedRegisteredRoute>
        <ProtectedRegisteredRoute path="/flights">
          <Page title="Flights calculator" page={<Flights />} />
        </ProtectedRegisteredRoute>
        <ProtectedRegisteredRoute path="/real-estate">
          <Page title="Energy and fuel calculator" page={<RealEstate />} />
        </ProtectedRegisteredRoute>
        <ProtectedRegisteredRoute path="/digital">
          <Page title="Digital services calculator" page={<DigitalServices />} />
        </ProtectedRegisteredRoute>
        <ProtectedRegisteredRoute path="/personal-carbon-footprint">
          <Page title="Personal carbon footprint calculator" page={<Employees />} />
        </ProtectedRegisteredRoute>
        <ProtectedRegisteredRoute path="/products-and-services">
          <Page title="Purchased products and services calculator" page={<Index />} />
        </ProtectedRegisteredRoute>

        <ProtectedRegisteredRoute path="/other-compensation">
          <Page title="Other compensations calculator" page={<OtherCompensations />} />
        </ProtectedRegisteredRoute>
        <ProtectedRegisteredRoute exact path="/logistics">
          <Page title="Logistics calculator" page={<Logistics />} />
        </ProtectedRegisteredRoute>
      </Switch>
    </Router>
  );
}

export default App;
