import create from "zustand";
import cookies from "js-cookie";

const VAT_CHANGES_CONSENT_KEY = "vat_changes";

// Selectors to avoid creating a new function on render
export const dismissedVATSelector = (state) => state.dismissedVAT;
export const setDismissedVATSelector = (state) => state.setDismissedVAT;

const useCookieConsent = create((set) => ({
  dismissedVAT: cookies.get(VAT_CHANGES_CONSENT_KEY),

  /**
   * Due to starting to charge VAT on compensation payments, store whether the user has dismissed VAT.
   * Only applicable for users who have signed up before 1.8.2021.
   */
  setDismissedVAT: (dismissedVAT) => {
    set({ dismissedVAT });

    cookies.set(VAT_CHANGES_CONSENT_KEY, dismissedVAT, {
      expires: 365,
    });
  },
}));

export default useCookieConsent;
