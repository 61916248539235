import React from "react";
import PropTypes from "prop-types";

import { calculateFlightEmissions } from "@api/flight-api";
import backgroundImage from "@assets/img/flights-bg.png";
import CalculatorPage from "@components/layout/CalculatorPage";
import { FormGenerator } from "@carbon/shared";
import SummaryTable from "@components/common/SummaryTable";
import { getCompensationParams } from "@utils/compensation";

import useSnackbarStore from "../../../store/snackbar";
import useCalculatorSummary from "../../../hooks/useCalculatorSummary";
import Instructions from "./components/Instructions";
import { SUMMARY_TABLE_COLUMNS } from "./constants";
import FORM_CONFIG from "./form-config";
import { redirectToCheckout } from "../../../api/payment-api";

const Flights = ({ headerHeight }) => {
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);

  const { calculations, addCalculationRow, removeCalculationRow, summary } = useCalculatorSummary(
    SUMMARY_TABLE_COLUMNS,
    "flights",
  );

  const handleAddCalculation = async (form) => {
    const calculation = await calculateFlightEmissions(form);

    addCalculationRow(calculation);

    createSnackbar("Flight added! Add another?");
  };

  const handleSubmitCalculations = async () => {
    try {
      await redirectToCheckout(getCompensationParams(summary, "flights"));
    } catch (error) {
      createSnackbar(`Failed to submit calculations: ${error.message}`, "error");
    }
  };

  return (
    <CalculatorPage
      headerHeight={headerHeight}
      backgroundImage={backgroundImage}
      title="Flights"
      subtitle={
        <>
          You can add one or multiple flights to your summary.
          <br />* = required fields
        </>
      }
      instructions={<Instructions />}
      summary={summary}
      summaryTable={
        <SummaryTable
          columns={SUMMARY_TABLE_COLUMNS}
          summary={summary}
          calculations={calculations}
          onRemoveRow={removeCalculationRow}
          onSubmitCalculations={handleSubmitCalculations}
        />
      }
    >
      <FormGenerator onSubmit={handleAddCalculation} {...FORM_CONFIG} />
    </CalculatorPage>
  );
};

Flights.propTypes = {
  headerHeight: PropTypes.number,
};

export default Flights;
