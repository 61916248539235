import linetoBookWoff from "../assets/fonts/lineto-circular-book.woff";
import linetoBookWoff2 from "../assets/fonts/lineto-circular-book.woff2";
import linetoBoldWoff from "../assets/fonts/lineto-circular-bold.woff2";
import linetoBoldWoff2 from "../assets/fonts/lineto-circular-bold.woff2";

export const linetoBook = {
  fontFamily: "Lineto Circular",
  fontStyle: "normal",
  fontWeight: 400,
  src: `url(${linetoBookWoff}) format('woff'), url(${linetoBookWoff2}) format('woff2')`,
};

export const linetoBold = {
  fontFamily: "Lineto Circular",
  fontStyle: "normal",
  fontWeight: 700,
  src: `url(${linetoBoldWoff}) format('woff'), url(${linetoBoldWoff2}) format('woff2')`,
};
