import React from "react";
import {
  getCompensationCell,
  getCompensationSummary,
  getTotalEmissionsCell,
  getTotalEmissionsSummary,
} from "@utils/summary-table";
import { KILOMETERS } from "@constants/distance";
import { TRANSPORTATION_OPTIONS, CALCULATE_BY } from "./constants";
import { formatNumber } from "@utils/formatting";

const getHumanReadableType = (type) => TRANSPORTATION_OPTIONS.find((option) => option.value === type)?.label ?? type;

const getHumanReadableCalculationType = (form) => {
  if (form.landCalculationBy === CALCULATE_BY.DISTANCE) {
    return "Based on distance";
  }

  if (form.landCalculationBy === CALCULATE_BY.FUEL) {
    return "Based on fuel";
  }

  return "Based on weight";
};

const getHumanReadableWeight = (form) => {
  const formattedWeight = `${formatNumber(form.weight)} kg`;
  const formattedFuel = `${formatNumber(form.fuelAmount)} ${
    form.fuelType === "gas" || form.fuelType === "biogas" ? "kg" : "l"
  }`;

  return (
    <>
      {!!form.weight && formattedWeight}
      {!!form.fuelAmount && formattedFuel}
    </>
  );
};

const getHumanReadableDistance = (form) => (
  <>
    {formatNumber(form.distance)} {form.distance && (form.distanceUnit || KILOMETERS)}
    {form.airportFrom && form.airportFrom.iata_code}
    {form.airportFrom && " - "}
    {form.airportTo && form.airportTo.iata_code}
  </>
);

export const SUMMARY_TABLE_COLUMNS = [
  { label: "Type", getCell: (item) => getHumanReadableType(item.form.transportationType) },
  { label: "Details", getCell: (item) => getHumanReadableCalculationType(item.form) },
  { label: "Weight/Amount", getCell: (item) => getHumanReadableWeight(item.form) },
  { label: "Distance", getCell: (item) => getHumanReadableDistance(item.form) },
  { label: "Quantity", getCell: "form.quantity" },
  {
    label: "Total emissions",
    getCell: getTotalEmissionsCell,
    getTotal: getTotalEmissionsSummary,
  },
  {
    label: "Compensation",
    getCell: getCompensationCell,
    getTotal: getCompensationSummary,
  },
];

export default SUMMARY_TABLE_COLUMNS;
