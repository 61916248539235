import { getStaticAsset } from "@carbon/shared";
import flightsBackground from "@assets/img/wing.png";
import realEstateBackground from "@assets/img/real-estate-mini-bg.png";
import digitalBackground from "@assets/img/digital.png";
import logisticsBackground from "@assets/img/logistics.jpg";

export const GENERAL_NAV_ITEMS = [
  { label: "Billing", path: "billinginfo" },
  { label: "Subscription", path: "licensing" },
];

export const SUBSCRIBED_GENERAL_NAV_ITEMS = [
  { label: "Billing", path: "billinginfo" },
  { label: "Account", path: "account" },
];

export const CALCULATOR_NAV_ITEMS = [
  { label: "Flights", path: "flights", background: flightsBackground },
  {
    label: "Purchased products & services",
    path: "products-and-services",
    featureGate: "indexCalculator",
    background: digitalBackground,
  },
  { label: "Energy and fuel use", path: "real-estate", background: realEstateBackground },
  { label: "Digital services", path: "digital", background: digitalBackground },
  { label: "Shipping and other logistics", path: "logistics", background: logisticsBackground },
  {
    label: "Personal carbon footprints",
    path: "personal-carbon-footprint",
    background: getStaticAsset("images/personal-bg.jpg"),
  },
  {
    label: "Other compensation",
    path: "other-compensation",
    featureGate: "otherCompensations",
    background: realEstateBackground,
  },
];

export const ACCESS_LINKS = [
  { label: "Pricing", path: "licensing" },
  { label: "Sign in", path: "login" },
];
