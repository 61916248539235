import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { Popper } from "@material-ui/core";
import { TextField, FIELD_WIDTH } from "@carbon/shared";

import { requiredNumber } from "../../../components/form/utils/schema";
import { Dropdown, NumberField, NumberFieldWithAdornment } from "../../../components/form";

import { PurchaseByAmountForm, PurchaseByQuantityForm, IndexForm, Option } from "./index.types";
import INDEX_CATEGORIES from "./index-categories";

const amountOrQuantitySchema = (label: string) =>
  yup.mixed().when("productCategory", {
    is: Boolean,
    then: requiredNumber(label),
  });

const purchaseByAmountSchema = yup.object({
  type: yup.string().is(["amount"]),
  productCategory: yup.string(),
  amount: amountOrQuantitySchema("Amount"),
  description: yup.string(),
});

const purchaseByQuantitySchema = yup.object({
  type: yup.string().is(["quantity"]),
  productCategory: yup.string(),
  quantity: amountOrQuantitySchema("Quantity"),
  description: yup.string(),
});

const schema: yup.SchemaOf<IndexForm> = yup.object({
  devices: yup.array().of(purchaseByQuantitySchema),
  indexTravel: yup.array().of(purchaseByAmountSchema),
  indexProducts: yup.array().of(purchaseByAmountSchema),
  indexServices: yup.array().of(purchaseByAmountSchema),
});

const getIndexFormConfig = (categoryOptions: Option[]) => [
  {
    name: "productCategory",
    label: "Product or service",
    render: Dropdown,
    props: {
      options: categoryOptions,
      autocompleteProps: {
        noOptionsText: 'No options found, please select "Other" purchases or services',
        PopperComponent: StyledPopper,
      },
    },
    wrapperProps: FIELD_WIDTH.THIRD,
  },
  {
    name: "amount",
    label: "Amount spent",
    render: NumberFieldWithAdornment,
    props: {
      adornment: "€",
      digits: 2,
    },
    wrapperProps: FIELD_WIDTH.THIRD,
  },
  {
    name: "description",
    label: "Description",
    render: TextField,
    fieldProps: {
      helperText: "Additional information shown on your compensation receipt",
    },
    wrapperProps: FIELD_WIDTH.AUTO,
  },
];

export const resolver = yupResolver(schema);

export const DEFAULT_VALUES: { byQuantity: PurchaseByQuantityForm; byAmount: PurchaseByAmountForm } = {
  byQuantity: {
    type: "quantity" as const,
    productCategory: "",
    quantity: null,
    description: "",
  },

  byAmount: {
    type: "amount" as const,
    productCategory: "",
    amount: null,
    description: "",
  },
};

export const defaultValues = {
  devices: [DEFAULT_VALUES.byQuantity],
  indexTravel: [DEFAULT_VALUES.byAmount],
  indexProducts: [DEFAULT_VALUES.byAmount],
  indexServices: [DEFAULT_VALUES.byAmount],
};

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)`
  min-width: 300px;
`;

export const digitalDevicesFormFields = [
  {
    name: "productCategory",
    label: "Device",
    render: Dropdown,
    props: {
      options: INDEX_CATEGORIES.digital,
    },
    wrapperProps: FIELD_WIDTH.THIRD,
  },
  {
    name: "quantity",
    label: "Quantity",
    render: NumberField,
    wrapperProps: FIELD_WIDTH.THIRD,
  },
  {
    name: "description",
    label: "Description",
    render: TextField,
    fieldProps: {
      helperText: "Additional information shown on your compensation receipt",
    },
    wrapperProps: FIELD_WIDTH.AUTO,
  },
];

export const indexTravelFormFields = getIndexFormConfig(INDEX_CATEGORIES.travel);
export const indexProductsFormFields = getIndexFormConfig(INDEX_CATEGORIES.products);
export const indexServicesFormFields = getIndexFormConfig(INDEX_CATEGORIES.services);
