import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, Typography } from "@material-ui/core";

import message from "../../utils/message";
import ControlledTextField from "../fields/ControlledTextField";
import Checkbox from "../fields/Checkbox";
import CountryDropdown from "../fields/CountryDropdown";
import RegionDropdown from "../fields/RegionDropdown";
import { TOU_LINK } from "../../constants/links";
import { FormConfig } from "../form.types";
import { CustomerForm } from "../../types";
import { FieldGeneratorProps } from "../FieldGenerator";

const { isRequired } = message;

const acceptedCountries = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

const TERMS_TYPE = yup.bool().oneOf([true], "You need to agree to our Terms of Use to start using our services");

const FIELD_TYPES = {
  contactName: yup.string().trim().required(isRequired("Name")),
  contactEmailAddress: yup.string().email("Must be a valid email").required(isRequired("A valid email")),
  businessId: yup.string().trim().required(isRequired("Business ID")),
  vatNum: yup.string().trim().required(isRequired("Vat number")),
  businessCountryDomicile: yup.object().shape({ value: yup.string() }).nullable().required(isRequired("Country")),
  companyName: yup.string().trim().required(isRequired("Company name")),
  country: yup.object().shape({ value: yup.string() }).nullable().required(isRequired("Country")),
  address: yup.string().trim().required(isRequired("Address")),
  city: yup.string().trim().required(isRequired("City")),
  state: yup.object().shape({ value: yup.string() }).nullable(),
  postCode: yup.string().trim().required(isRequired("Postal code")),
};

const TERMS_FIELD = {
  render: Checkbox,
  name: "terms",
  required: true,
  label: (
    <Typography variant="body2">
      I understand that by proceeding I complete the registration process in accordance with the{" "}
      <Link href={TOU_LINK} target="_blank" rel="noopener">
        Terms of Use
      </Link>
    </Typography>
  ),
  fieldProps: {
    "data-testid": "billing-terms",
  },
};

const getFields = (isEditMode: boolean): FieldGeneratorProps[] => [
  {
    name: "sectionTitle",
    render() {
      return (
        <Typography component="h3" variant="h5">
          1. Contact information
        </Typography>
      );
    },
  },
  {
    name: "contactName",
    render: ControlledTextField,
    label: "Your full name",
    required: true,
  },
  {
    name: "contactEmailAddress",
    render: ControlledTextField,
    label: "Email address",
    required: true,
    fieldProps: {
      helperText: "Compensate will use these details to send you necessary customer information",
    },
  },
  {
    name: "businessId",
    render: ControlledTextField,
    label: "Business ID",
    required: true,
    wrapperProps: {
      xs: 12,
      md: 6,
    },
    fieldProps: {
      disabled: isEditMode,
    },
  },
  {
    name: "vatNum",
    render: ControlledTextField,
    label: "VAT number",
    required: true,
    wrapperProps: {
      xs: 12,
      md: 6,
    },
    fieldProps: {
      placeholder: "XX12345678",
      helperText: "Please include the country code in the first two characters, e.g. FI12345678",
      disabled: isEditMode,
    },
  },
  {
    name: "businessCountryDomicile",
    render: CountryDropdown,
    label: "Country of your business domicile",
    required: true,
    props: {
      whitelist: acceptedCountries,
    },
    fieldProps: {
      helperText: "Currently available to countries in the EU",
    },
  },
  {
    name: "sectionTitle2",
    render() {
      return (
        <Typography component="h3" variant="h5">
          2. Billing address
        </Typography>
      );
    },
  },
  {
    name: "companyName",
    render: ControlledTextField,
    label: "Company name",
    required: true,
  },
  {
    name: "country",
    render: CountryDropdown,
    label: "Country",
    required: true,
    props: {
      whitelist: acceptedCountries,
    },
  },
  {
    name: "address",
    render: ControlledTextField,
    label: "Street address",
    required: true,
  },
  {
    name: "city",
    render: ControlledTextField,
    label: "Town / City",
    required: true,
  },
  {
    name: "state",
    render: RegionDropdown,
    label: "State / County / Province",
    props: {
      countryFieldName: "country",
    },
  },
  {
    name: "postCode",
    render: ControlledTextField,
    label: "Postal code / ZIP",
    required: true,
  },
];

const defaultValues = {
  country: null,
  businessCountryDomicile: null,
  state: null,
  terms: false,
  contactName: "",
  contactEmailAddress: "",
  businessId: "",
  vatNum: "",
  companyName: "",
  address: "",
  city: "",
  postCode: "",
};

export const FORM_CONFIG_EDIT_MODE: FormConfig<CustomerForm> = {
  resolver: yupResolver(yup.object().shape(FIELD_TYPES)),
  defaultValues,
  fields: getFields(true),
};

export const FORM_CONFIG_CREATE_MODE: FormConfig<CustomerForm> = {
  resolver: yupResolver(yup.object().shape({ ...FIELD_TYPES, terms: TERMS_TYPE })),
  defaultValues,
  fields: [...getFields(false), TERMS_FIELD],
};
