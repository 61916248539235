import React from "react";
import { FeatureGate } from "@carbon/shared";

const OtherCompensations = () => {
  return (
    <FeatureGate feature="otherCompensations">
      <h1>Other Compensations Placeholder</h1>
    </FeatureGate>
  );
};

export default OtherCompensations;
