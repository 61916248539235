import React, { useState } from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Link, Typography } from "@material-ui/core";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import {
  getStaticAsset,
  FormWrapper,
  GeneralFormError,
  FieldArrayRows,
  SubmitFormButton,
  ApiError,
  spacing,
} from "@carbon/shared";

import useCalculatorSummary from "../../../hooks/useCalculatorSummary";
import CalculatorPage from "../../../components/layout/CalculatorPage";
import SummaryTable from "../../../components/common/SummaryTable";
import { getBulkSnackbarConfig, submitBulkCalculations } from "../../../utils/bulk-calculations";
import useSnackbarStore from "../../../store/snackbar";
import { redirectToCheckout } from "../../../api/payment-api";
import { getCompensationParams } from "../../../utils/compensation";
import { CalculationResult } from "../../../types";

import SUMMARY_TABLE_COLUMNS from "./index-summary-table";
import Instructions from "./components/Instructions";
import { IndexForm, PurchaseForm, PurchaseByAmountForm, PurchaseByQuantityForm } from "./index.types";
import { calculateIndexFootprint, calculateProductFootprint } from "./index-api";
import {
  digitalDevicesFormFields,
  indexProductsFormFields,
  DEFAULT_VALUES,
  resolver,
  defaultValues,
  indexServicesFormFields,
  indexTravelFormFields,
} from "./index-form-config";
import { flattenForm } from "./index-utils";

/**
 * Enforce index type ("amount" or "quantity") is included in form data.
 * This will later be used in the summary, but as an internal property is not included in form state.
 */
function addType<T>(type: PurchaseForm["type"]) {
  return (calculation: T) => ({ ...calculation, type });
}

const isProductCategorySelected = ({ productCategory }: PurchaseForm) => productCategory !== "";

interface Props {
  headerHeight?: number;
}

const GridDivider = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

const GridSection = styled(Grid)`
  margin-top: ${spacing(4)}px;
  margin-bottom: ${spacing(4)}px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  box-shadow: 0px 15px 40px -20px rgba(0, 0, 0, 0.1);
`;

const Index = ({ headerHeight }: Props) => {
  const formMethods = useForm({
    resolver,
    defaultValues,
  });

  const [asyncFormValues, setAsyncFormValues] = useState<IndexForm>();
  const createSnackbar = useSnackbarStore((state) => state.createSnackbar);
  const { calculations, addCalculationRow, removeCalculationRow, summary } = useCalculatorSummary(
    SUMMARY_TABLE_COLUMNS,
    "index",
  );

  const handleSubmitTransaction = async () => {
    try {
      await redirectToCheckout(getCompensationParams(summary, "index", "/products-and-services"));
    } catch (error: any) {
      createSnackbar(`Failed to submit calculations: ${error.message}`, "error");
    }
  };

  const handleAddCalculation = async (form: IndexForm) => {
    let totalCalculations: CalculationResult<PurchaseForm>[] = [];
    let totalErrors: ApiError[] = [];

    if (flattenForm(form).filter(isProductCategorySelected).length === 0) {
      // No sections of the form have been completed
      const message = "Please enter at least one purchased product or service";

      createSnackbar(message, "error");
      throw new Error(message);
    }

    /**
     * Submit a section from the index calculator page.
     * Returns the next state of the index form after submission, this is either failed
     * calculations or a single row with default values (i.e. the form is reset)
     */
    async function submitFormPart<T extends PurchaseForm>(
      calculations: T[],
      calculateFootprint: (form: T) => Promise<CalculationResult<T>>,
      defaultValues: T,
      type: PurchaseForm["type"],
    ): Promise<T[]> {
      calculations = calculations.filter(isProductCategorySelected);

      if (!calculations.length) {
        return [defaultValues];
      }

      const results = await submitBulkCalculations<T>(calculateFootprint, calculations.map(addType<T>(type)));
      const failedCalculations = calculations.filter((_: T, i: number) => !results.successIndices.includes(i));

      totalCalculations = [...totalCalculations, ...results.calculations];
      totalErrors = [...totalErrors, ...results.errors];

      if (failedCalculations.length) {
        return failedCalculations;
      }

      return [defaultValues];
    }

    const nextFormState: IndexForm = {
      devices: await submitFormPart<PurchaseByQuantityForm>(
        form.devices,
        calculateProductFootprint,
        DEFAULT_VALUES.byQuantity,
        "quantity",
      ),
      indexProducts: await submitFormPart<PurchaseByAmountForm>(
        form.indexProducts,
        calculateIndexFootprint,
        DEFAULT_VALUES.byAmount,
        "amount",
      ),
      indexServices: await submitFormPart<PurchaseByAmountForm>(
        form.indexServices,
        calculateIndexFootprint,
        DEFAULT_VALUES.byAmount,
        "amount",
      ),
      indexTravel: await submitFormPart<PurchaseByAmountForm>(
        form.indexTravel,
        calculateIndexFootprint,
        DEFAULT_VALUES.byAmount,
        "amount",
      ),
    };

    createSnackbar(
      ...getBulkSnackbarConfig<PurchaseForm>(
        {
          calculations: totalCalculations,
          errors: totalErrors,
          successIndices: [],
        },
        <>
          Emissions from products and services calculated, <Link href="#summary">view the summary</Link>
        </>,
      ),
    );

    if (totalCalculations.length) {
      addCalculationRow(totalCalculations);
    }

    if (totalErrors.length) {
      // Remove successful calculations from the form rows if submission failed partially
      setAsyncFormValues(nextFormState);

      throw totalErrors[0];
    }
  };

  return (
    <CalculatorPage
      backgroundImage={getStaticAsset("/images/architecture-blue-sky.jpg")}
      headerHeight={headerHeight}
      title="Purchased products & services"
      subtitle={
        <>
          Calculate the estimated carbon footprint for any product or service based on the amount spent or quantity
          purchased
          <br />* = required fields
        </>
      }
      instructions={<Instructions />}
      summary={summary}
      summaryTable={
        <SummaryTable
          columns={SUMMARY_TABLE_COLUMNS}
          summary={summary}
          calculations={calculations}
          onRemoveRow={removeCalculationRow}
          onSubmitCalculations={handleSubmitTransaction}
        />
      }
    >
      <FormProvider {...formMethods}>
        <FormWrapper onSubmit={handleAddCalculation} asyncFormValues={asyncFormValues}>
          <GeneralFormError />

          <GridSection container item spacing={4}>
            <Grid container item spacing={2}>
              <Grid item>
                <Typography variant="h5" paragraph>
                  Products based on quantity
                </Typography>

                <Typography variant="h6">Digital devices</Typography>
              </Grid>

              <FieldArrayRows<PurchaseByQuantityForm>
                formName="devices"
                defaultValues={DEFAULT_VALUES.byQuantity}
                fields={digitalDevicesFormFields}
              />
            </Grid>
          </GridSection>

          <GridSection container item spacing={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" paragraph>
                  Products and services based on amount spent
                </Typography>

                <Typography variant="h6">Products</Typography>
              </Grid>

              <FieldArrayRows<PurchaseByAmountForm>
                formName="indexProducts"
                defaultValues={DEFAULT_VALUES.byAmount}
                fields={indexProductsFormFields}
              />

              <GridDivider />

              <Grid item xs={12}>
                <Typography variant="h6">Travel & transportation</Typography>
              </Grid>

              <FieldArrayRows<PurchaseByAmountForm>
                formName="indexTravel"
                defaultValues={DEFAULT_VALUES.byAmount}
                fields={indexTravelFormFields}
              />

              <GridDivider />

              <Grid item xs={12}>
                <Typography variant="h6">Services & entertainment</Typography>
              </Grid>

              <FieldArrayRows<PurchaseByAmountForm>
                formName="indexServices"
                defaultValues={DEFAULT_VALUES.byAmount}
                fields={indexServicesFormFields}
              />
            </Grid>
          </GridSection>

          <SubmitFormButton
            label="Calculate emissions"
            wrapperProps={{
              xs: 12,
              sm: "auto",
            }}
          />
        </FormWrapper>
      </FormProvider>
    </CalculatorPage>
  );
};

Index.propTypes = {
  headerHeight: PropTypes.number,
};

export default Index;
